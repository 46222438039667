import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ClaimsLoadingService {

  private loading = new Subject<boolean>();

  updateLoading(value: boolean) {
    this.loading.next(value);
  }

  onLoadingChanged() {
    return this.loading.asObservable();
  }
}
