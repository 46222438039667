import { Inject, Injectable, Injector } from '@angular/core';
import createAuth0Client, { Auth0Client, GetIdTokenClaimsOptions, GetUserOptions } from '@auth0/auth0-spa-js';
import { Router } from '@angular/router';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';
import { SessionService } from '../session/session.service';
import { BrowserUtils } from 'src/app/core';
import { EventType } from '../../enums/event-type';

// TODO: Remove this. This is no longer used anywhere else.
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth0Client$ = (from(
    createAuth0Client({
      domain: this.environment.authConfig.auth0Domain,
      client_id: this.environment.authConfig.auth0ClientID,
      redirect_uri: this.environment.authConfig.auth0CallbackURL[window.location.hostname],
      audience: this.environment.authConfig.auth0Audience
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1),
    catchError(err => throwError(err))
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );

  loggedIn: boolean = null;

  private handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  private userProfileSubject$ = new BehaviorSubject<any>(null);

  private idTokenSubject$ = new BehaviorSubject<any>(null);

  constructor(private router: Router, private injector: Injector, @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) {
    this.localAuthSetup();
    this.handleAuthCallback();
  }

  getUser$(options?: GetUserOptions): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => {
        this.userProfileSubject$.next(user);
      })
    );
  }

  getIdToken$(options?: GetIdTokenClaimsOptions): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getIdTokenClaims(options))),
      tap(token => this.idTokenSubject$.next(token))
    );
  }

  login(redirectPath?: string) {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        redirect_uri: this.environment.authConfig.auth0CallbackURL[window.location.hostname],
        logo: 'bsi',
        allow_forgot_password: this.environment.authConfig.allowForgotPassword,
        appState: { target: redirectPath }
      });
    });
  }

  logout() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: this.environment.authConfig.auth0ClientID,
        returnTo: this.environment.authConfig.auth0LogoutURL[window.location.hostname]
      });
    });
  }

  private localAuthSetup() {
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          this.getSessionService().log(EventType.Successful_Login, 'AuthService: Successful login', BrowserUtils.getBrowserInfo(), null, () => {
            return this.getUser$();
          });
        }
        return of(loggedIn);
      })
    );

    checkAuth$.subscribe();
  }

  private handleAuthCallback() {
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string;
      const authComplete$ = this.handleRedirectCallback$.pipe(
        tap(cbRes => {
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          return combineLatest([
            this.getUser$(),
            this.isAuthenticated$
          ]);
        })
      );

      authComplete$.subscribe(() => {
        this.getSessionService().log(EventType.Successful_Login, 'AuthService: Successful login', BrowserUtils.getBrowserInfo(), null, () => {
          if (targetRoute.includes('session/error')) {
            this.router.navigate(['/']);
          } else {
            this.router.navigate([targetRoute]);
          }
        });
      });
    }
  }

  private getSessionService() {
    return this.injector.get(SessionService);
  }
}
