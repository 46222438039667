
import {timer as observableTimer,  Observable } from 'rxjs';

import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';



@Injectable()
export class TimerService {
  private timer: Observable<number>;
  count: number;

  constructor() { }

  countSeconds(duration) {
    this.timer = observableTimer(0, 1000).pipe(take(duration));
    return this.timer;
  }
}
