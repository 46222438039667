import { DcFieldTypeEnum } from '@dc/core';

import {
  SearchQueryField,
  SearchQueryOperator,
  AbstractSearchQuery
} from 'src/component-library';
import { DateFormatEnum } from 'src/component-library/classes/enums/date-format.enum';


export interface HumanizedField {
  name: string;
  datatype: string;
  rawValues: any;
  values: any;
  operator: string;
}

export class SearchQueryUtils {
  static encodeSearchQuery(query: any) {
    return window.btoa(JSON.stringify(query));
  }

  static decodeSearchQuery(query: any) {
    return JSON.parse(window.atob(query));
  }

  static humanizeQuery(query: AbstractSearchQuery): HumanizedField[] {
    const tree = query.fields.slice().map(field => {
      return this.humanizeField(field);
    });

    // Flatten
    return [].concat.apply([], tree);
  }

  static humanizeField(field: SearchQueryField) {
    let operator: string;
    let preposition: string;
    if (field.key === 'CLAIMANT_TAX_ID.keyword' && field.operator === SearchQueryOperator.Includes) {
      operator = ' (exact)';
    } else if (field.key === 'CLAIMANT_TAX_ID.keyword' && field.operator === SearchQueryOperator.Wildcard) {
      operator = ' (last 4)';
    } else if (field.operator === SearchQueryOperator.Equal) {
      operator = ' (exact)';
    } else if (field.operator === SearchQueryOperator.Range && field.type === DcFieldTypeEnum.Money) {
      operator = ' (between)';
      preposition = 'and';
    } else if (field.operator === SearchQueryOperator.Range) {
      operator = ' (range)';
      preposition = 'to';
    } else if (field.operator === SearchQueryOperator.Nested) {
      return field.values
        .filter(value => !!value.name)
        .map(value => this.humanizeField(value));
    } else if (field.operator === SearchQueryOperator.Prefix) {
      operator = '';
    } else if (field.operator === SearchQueryOperator.Excludes) {
      operator = ' (not)';
      preposition = 'or';
    } else if (field.operator === SearchQueryOperator.Includes) {
      operator = '';
      preposition = 'or';
    } else if (field.operator === SearchQueryOperator.NotEqual) {
      operator = ' (not)';
    } else if (field.operator === SearchQueryOperator.Wildcard) {
      operator = ' (contains)';
    } else if (field.operator === SearchQueryOperator.LessThan && field.type === DcFieldTypeEnum.Money) {
      operator = ' (below)';
    } else if (field.operator === SearchQueryOperator.GreaterThan && field.type === DcFieldTypeEnum.Money) {
      operator = ' (above)';
    } else if (field.operator === SearchQueryOperator.LessThan) {
      operator = ' (before)';
    } else if (field.operator === SearchQueryOperator.GreaterThan) {
      operator = ' (after)';
    } else if (field.operator === SearchQueryOperator.LessThanEqual) {
      operator = ' (on or before)';
    } else if (field.operator === SearchQueryOperator.GreaterThanEqual) {
      operator = ' (on or after)';
    } else {
      operator = ' (other)';
      preposition = 'and';
    }

    return {
      name: field.name,
      datatype: field.type,
      operator: operator,
      rawValues: this.getRawFieldValues(field.type, field.values),
      get values() {
        let valuesAsText = '';

        if (Array.isArray(this.rawValues)) {
          (this.rawValues as Array<any>).forEach((value, index) => {
            valuesAsText += value;
            if (field.key === 'CLAIMANT_TAX_ID.keyword') {
              return valuesAsText = this.rawValues[0];
            }
            if (index < this.rawValues.length - 1) {
              valuesAsText += ` ${preposition} `;
            }
          });
          return valuesAsText;
        } else {
          return valuesAsText += this.rawValues;
        }
      }
    };
  }

  static getRawFieldValues(type: DcFieldTypeEnum, values: any) {
    const formatDateValue = (value: any) => {
      /**
       * Firefox date format fix https://support.mozilla.org/en-US/questions/1271450
       * String date with '-' will throw error in Firefox
       * It should be replaced with '/'
       */
      const valueToFormat = `${value}`.replace(/-/g, '/');
      const date = new Date(valueToFormat);
      return date.format(DateFormatEnum.shortDate);
    };
    if (Array.isArray(values)) {
      return values.map(value => {
        return type === DcFieldTypeEnum.Date ? formatDateValue(value) : value;
      });
    }
    return type === DcFieldTypeEnum.Date ? formatDateValue(values) : values;
  }
}
