import {
  Component,
  Optional,
  Self,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html'
})
export class CurrencyInputComponent implements AfterViewInit {
  @Input() align?: 'left' | 'right' | 'center' | 'decimal';
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() inline?: string;

  private history = [''];
  private historyIndex = 0;
  updateForm: Function;

  @ViewChild('input', { static: true }) input: ElementRef;
  el: HTMLInputElement;

  @ViewChild('inputContainer', { static: true}) inputContainer: ElementRef;

  change;
  errorMessages;
  onChange() { }

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    } else {
      throw new Error(`FormControl is missing from Currency Input.`);
    }
  }

  // Have access to the actual html input for restricting and formatting the value on user interactions.
  ngAfterViewInit() {
    this.el = this.input.nativeElement;
  }

  // On blur, update the form using this.updateForm method
  registerOnChange(fn) {
    this.updateForm = fn;
  }

  onTouch = () => { };

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  // When value is written to form, append to history
  writeValue(value: string) {
    this.appendToHistory(value);
  }

  onFocus() {
    // bugfix for CDS upgrade v0.3.5 to v0.6.1
    // CCP-11265 mimic cds-input style
    // Adding -active will add border color and box-shadow
    this.inputContainer.nativeElement.classList.add('-active');

    if (!this.el.value) {
      this.updateForm(this.el.value);
    }
  }

  // Listen for input events so we can handle delete events
  onInput(event: any) {
    switch (event.inputType) {
      // case 'historyUndo':
      //   this.handleUndo();
      //   break;
      // case 'historyRedo':
      //   this.handleRedo();
      //   break;
      case 'deleteContentBackward':
        this.checkForDeleteBackward();
        this.appendToHistory(this.el.value);
        break;
      case 'deleteContentForward':
        this.checkForDeleteForward();
        this.appendToHistory(this.el.value);
        break;
    }
    this.updateForm(this.el.value);
  }

  // Allow for enter key, tab key, and any other special keys to propogate.
  onKeypress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      // Do nothing
    // } else if (event.code === 'KeyZ' && event.shiftKey === false) {
    //   // this.handleUndo();
    // } else if (event.code === 'KeyZ' && event.shiftKey === true) {
    //   // this.handleRedo();
    } else {
      this.handleKey(event);
    }
    this.updateForm(this.el.value);
  }

  onBlur() {
    // bugfix for CDS upgrade v0.3.5 to v0.6.1
    // CCP-11265 mimic cds-input style
    // Removing -active will remove border color and box-shadow
    this.inputContainer.nativeElement.classList.remove('-active');

    // Set empty values to null
    if (!this.el.value || this.el.value === '$' || this.el.value === '-$') {
      this.el.value = null;
    } else {
      this.trimZeros();
      this.padDecimals();
      this.formatCommas(null);
      this.appendToHistory(this.el.value);
    }
    // Hack: set the form's value to empty string temporarily and call onTouch to possibly help range validation
    this.updateForm('');
    this.onTouch();
    this.updateForm(this.el.value);
    this.onTouch();
  }

  onPaste(event: any) {
    event.preventDefault();
    let selectionStart = this.el.selectionStart;
    let selectionEnd = this.el.selectionEnd;
    const originalSelectionStart = this.el.selectionStart;
    const originalSelectionEnd = this.el.selectionEnd;
    let pasteValue = event.clipboardData.getData('text').slice();

    // Paste Value:
    // Do not do anything if paste value is empty
    if (!pasteValue) {
      return;
    }

    // Replace all dash characters with the regular dash character
    pasteValue = pasteValue.replace(/[-−‐﹣－‑⁃–—﹘⸺⸻᠆˗➖]/g, '-');

    // Check for negatives
    let isNegative = false;
    if (pasteValue.includes('-')) {
      isNegative = true;
    }

    // Strip disallowed characters and negatives from paste
    while (pasteValue.match(/[^0-9.]/)) {
      pasteValue = pasteValue.replace(/[^0-9.]/, '');
    }

    // If paste value contains multiple decimals, strip the extra decimals
    if (pasteValue.match(/[.]/g) && pasteValue.match(/[.]/g).length > 1) {
      const decimalIndex = pasteValue.indexOf('.');
      let substringAfterDecimal = pasteValue.substring(decimalIndex + 1);
      substringAfterDecimal = substringAfterDecimal.replace(/[.]/g, '');
      pasteValue = pasteValue.substring(0, decimalIndex + 1) + substringAfterDecimal;
    }

    // Element Value:
    // Check for negatives outside of selected range to be replaced
    if (this.el.value.substring(0, selectionStart).includes('-')) {
      isNegative = true;
    }

    // Strip characters from value and adjust selection range
    while (this.el.value.match(/[^0-9.]/)) {
      const strippedCharacterMatch = this.el.value.match(/[^0-9.]/);
      this.el.value = this.el.value.replace(/[^0-9.]/, '');
      if (strippedCharacterMatch.index < selectionEnd) {
        selectionEnd = selectionEnd - 1;
      }
      if (strippedCharacterMatch.index < selectionStart) {
        selectionStart = selectionStart - 1;
      }
    }

    // If there is a decimal outside the selected range, remove the paste's decimal and any following digits
    if (this.el.value.includes('.') && !this.el.value.substring(selectionStart, selectionEnd).includes('.')) {
      // Do not allow pastes after the decimal where we overwrite existing 2 digits
      const decimalIndex = pasteValue.indexOf('.');
      if (decimalIndex > -1) {
        pasteValue = pasteValue.substring(0, decimalIndex);
      }
    }

    // Replace the selection range with the paste
    this.el.value = this.el.value.substring(0, selectionStart) + pasteValue + this.el.value.substring(selectionEnd);

    // Add dollar sign
    this.el.value = '$' + this.el.value;
    selectionStart = selectionStart + 1;
    selectionEnd = selectionEnd + 1;

    // Add negative
    if (isNegative) {
      this.el.value = '-' + this.el.value;
      selectionStart = selectionStart + 1;
      selectionEnd = selectionEnd + 1;
    }

    // Slice after 2 digits after the decimal
    if (this.el.value.includes('.')) {
      const endIndex = Math.min(this.el.value.indexOf('.') + 3, this.el.value.length);
      this.el.value = this.el.value.substring(0, endIndex);
    }

    // Add a 0 between $ and .
    if (this.el.value.includes('$.')) {
      const dollarSignIndex = this.el.value.indexOf('$');
      this.el.value = this.el.value.substring(0, dollarSignIndex + 1) + '0' + this.el.value.substring(dollarSignIndex + 1);
      selectionStart = selectionStart + 1;
      selectionEnd = selectionEnd + 1;
    }

    // Check whether value exceeds the max-value
    if (this.exceedsMaxValue()) {
      this.el.value = this.history[this.historyIndex];
      this.el.setSelectionRange(originalSelectionStart, originalSelectionEnd);
      return;
    }

    // Add commas
    const cursorPosition = selectionStart + pasteValue.length;
    this.formatCommas(cursorPosition);

    this.appendToHistory(this.el.value);
    this.updateForm(this.el.value);
  }

  handleKey(event: any) {
    const key = event.key;
    const allowedCharactersRegex = /[0-9-−‐﹣－‑⁃–—﹘⸺⸻᠆˗➖$.]+$/;
    const digitsRegex = /\d+$/;
    const dashesRegex = /[-−‐﹣－‑⁃–—﹘⸺⸻᠆˗➖]/g;

    // Stop disallowed characters
    if (!key.match(allowedCharactersRegex)) {
      event.preventDefault();

    // Handle digits
    } else if (key.match(digitsRegex)) {
      this.handleDigit(event);

    // Handle dashes
    } else if (key.match(dashesRegex)) {
      this.handleDash(event);

    // Handle dollar sign
    } else if (key === '$') {
      this.handleDollarSign(event);

    // Handle decimals
    } else if (key === '.') {
      this.handleDecimal(event);
    }

    this.appendToHistory(this.el.value.slice());
  }

  handleDigit(event: any) {
    event.preventDefault();

    // If selection start is 0 and the string that is outside the selected range starts with '-' or '$', ignore
    if (this.el.selectionStart === 0 &&
        (this.el.value.substring(this.el.selectionEnd).startsWith('-') ||
        this.el.value.substring(this.el.selectionEnd).startsWith('$'))) {
      event.preventDefault();

    // If the string starts with '-' and the rest starts with '$', ignore
    } else if (this.el.value.startsWith('-') &&
        this.el.value.substring(this.el.selectionEnd, this.el.value.length).startsWith('$', 0)) {
      event.preventDefault();

    // If the decimal already has two digits after the decimal, ignore
    } else if ((this.el.value.match(/([.]\d\d)+$/)) &&
        (this.el.selectionEnd === this.el.selectionStart) &&
        ((this.el.value.length - this.el.selectionEnd) < 3)) {
      event.preventDefault();

    // If typing over a selection reaching up to '-', add negative dollar sign
    } else if (this.el.value.substring(0, this.el.selectionStart).startsWith('-') &&
        !this.el.value.substring(0, this.el.selectionStart).includes('$')) {
      // Save the selection start and end
      const selectionStart = this.el.selectionStart;
      const selectionEnd = this.el.selectionEnd;

      this.el.value = '-$' + event.key + this.el.value.substring(this.el.selectionEnd, this.el.value.length);

      // Check whether value exceeds the max-value
      if (this.exceedsMaxValue()) {
        this.el.value = this.history[this.historyIndex];
        this.el.setSelectionRange(selectionStart, selectionEnd);
        return;
      }

      this.formatCommas(null);

      // Set cursor position three spots from start
      this.el.setSelectionRange(3, 3);

    // If typing over selection reaching to start, add dollar sign
    } else if (this.el.selectionStart === 0) {
      // Save the selection start and end
      const selectionStart = this.el.selectionStart;
      const selectionEnd = this.el.selectionEnd;

      this.el.value = '$' + event.key + this.el.value.substring(this.el.selectionEnd, this.el.value.length);

      // Check whether value exceeds the max-value
      if (this.exceedsMaxValue()) {
        this.el.value = this.history[this.historyIndex];
        this.el.setSelectionRange(selectionStart, selectionEnd);
        return;
      }

      this.formatCommas(null);
      // Set cursor position
      let cursorPosition;
      if (this.el.value.substring(this.el.selectionStart, this.el.selectionEnd).includes('-$')) {
        cursorPosition = this.el.selectionEnd + 2;
      } else {
        cursorPosition = this.el.selectionEnd + 1;
      }
      this.el.setSelectionRange(cursorPosition, cursorPosition);

    // Otherwise, add the digit
    } else {
      const cursorPosition = this.el.selectionStart + 1;

      // Save the selection start and end
      const selectionStart = this.el.selectionStart;
      const selectionEnd = this.el.selectionEnd;

      this.el.value = this.el.value.substring(0, this.el.selectionStart)
                        + event.key + this.el.value.substring(this.el.selectionEnd, this.el.value.length);

      // Check whether value exceeds the max-value
      if (this.exceedsMaxValue()) {
        this.el.value = this.history[this.historyIndex];
        this.el.setSelectionRange(selectionStart, selectionEnd);
        return;
      }

      this.formatCommas(cursorPosition);
    }
  }

  handleDash(event: any) {
    event.preventDefault();

    // If empty value, add '-$'
    if (!this.el.value) {
      this.el.value = '-$';
      this.el.setSelectionRange(2, 2);

    // If at beginning of string with a dollar sign, add '-'
    } else if ((this.el.selectionStart === 0) && this.el.value.substring(this.el.selectionEnd).startsWith('$', 0)) {
      this.el.value = '-' + this.el.value.substring(this.el.selectionEnd);
      this.formatCommas(null);
      this.el.setSelectionRange(2, 2);

    // If right after a dollar sign starting the string, add '-'
    } else if (this.el.selectionStart === 1 && this.el.value.startsWith('$', 0)) {
      this.el.value = '-$' + this.el.value.substring(this.el.selectionEnd);
      this.formatCommas(null);
      this.el.setSelectionRange(2, 2);

    // If replacing the rest of the string, add '-$'
    } else if (this.el.selectionStart === 0 && !this.el.value.substring(this.el.selectionEnd).includes('-$')) {
      this.el.value = '-$' + this.el.value.substring(this.el.selectionEnd);
      this.formatCommas(null);
      this.el.setSelectionRange(2, 2);
    }
  }

  handleDollarSign(event: any) {
    event.preventDefault();

    // If empty value, add '$'
    if (!this.el.value) {
      this.el.value = '$';
      this.el.setSelectionRange(1, 1);

    // If at start of string and before '-', replace with '-$'
    } else if (this.el.selectionStart === 0 && this.el.value.substring(this.el.selectionEnd).startsWith('-', 0)) {
      // this could be refactored as a nested if statement in the else if below
      this.el.value = '-$' + this.el.value.substring(this.el.selectionEnd + 2);
      this.formatCommas(null);
      this.el.setSelectionRange(2, 2);

    // If right after '-', replace with '-$'
    } else if (this.el.selectionStart === 1 && this.el.value.startsWith('-', 0)) {
      this.el.value = '-$' + this.el.value.substring(this.el.selectionEnd + 1, this.el.value.length);
      this.formatCommas(null);
      this.el.setSelectionRange(2, 2);

    // If before dollar sign, replace with '$'
    } else if (this.el.selectionStart === 0 && this.el.value.substring(this.el.selectionEnd).startsWith('$')) {
      this.el.value = '$' + this.el.value.substring(this.el.selectionEnd + 1, this.el.value.length);
      this.formatCommas(null);
      this.el.setSelectionRange(1, 1);

    // If typing over selection reaching to the beginning, replace the selected range with '$'
    } else if (this.el.selectionStart === 0) {
      this.el.value = '$' + this.el.value.substring(this.el.selectionEnd);
      this.formatCommas(null);
      this.el.setSelectionRange(1, 1);
    }
  }

  handleDecimal(event: any) {
    event.preventDefault();
    // If decimal is not within 3 places of length, ignore
    if ((this.el.value.length - this.el.selectionEnd) >= 3) {
      event.preventDefault();

    // If string has a decimal outside the selected range, ignore
    } else if (this.el.value.includes('.') &&
        !this.el.value.substring(this.el.selectionStart, this.el.selectionEnd).includes('.')) {
      event.preventDefault();

    // If string is empty or replacing whole thing, add $0.
    } else if (!this.el.value || (this.el.selectionStart === 0 && this.el.selectionEnd === this.el.value.length)) {
      this.el.value = '$0.';
      this.el.setSelectionRange(3, 3);

    // If replacing up until the dollar sign, add 0.
    } else if (this.el.value.substring(0, this.el.selectionStart).endsWith('$')) {
      const cursorPosition = this.el.selectionStart + 2;
      this.el.value = this.el.value.substring(0, this.el.selectionStart) + '0.' + this.el.value.substring(this.el.selectionEnd);
      this.el.setSelectionRange(cursorPosition, cursorPosition);

    // If replacing up until the negative, add $0.
    } else if (this.el.value.substring(0, this.el.selectionStart).endsWith('-')) {
      const cursorPosition = this.el.selectionStart + 3;
      this.el.value = this.el.value.substring(0, this.el.selectionStart) + '$0.' + this.el.value.substring(this.el.selectionEnd);
      this.el.setSelectionRange(cursorPosition, cursorPosition);

    // Otherwise, add decimal
    } else {
      let cursorPosition;
      cursorPosition = this.el.selectionStart + 1;

      this.el.value = this.el.value.substring(0, this.el.selectionStart)
                        + '.' + this.el.value.substring(this.el.selectionEnd, this.el.value.length);
      this.formatCommas(cursorPosition);
    }
  }

  trimZeros() {
    // Get dollar sign
    const dollarSignIndex = this.el.value.indexOf('$');
    const dollarSign = this.el.value.substring(0, dollarSignIndex + 1);

    // Strip other characters
    let numberValue = this.el.value.replace(/[^0-9.]/g, '');

    // Remove leading zeros
    while (numberValue.startsWith('0')) {
      numberValue = numberValue.replace('0', '');
    }

    this.el.value = dollarSign + numberValue;

    // Add missing 0 between $ and .
    if (this.el.value.includes('$.') || this.el.value === '$' || this.el.value === '-$') {
      this.el.value = this.el.value.substring(0, dollarSignIndex + 1) + '0' + this.el.value.substring(dollarSignIndex + 1);
    }
  }

  padDecimals() {
    // If missing decimal, add decimal
    if (!this.el.value.includes('.')) {
      this.el.value = this.el.value + '.';
    }

    // If missing either of two digits after decimal, pad with zeros
    const decimalIndex = this.el.value.indexOf('.');
    let decimals = this.el.value.substring(decimalIndex);
    const decimalEnd = Math.min(3, decimals.length);
    decimals = decimals.substring(0, decimalEnd);

    while (decimals.length < 3) {
      decimals = decimals + '0';
    }
    this.el.value = this.el.value.substring(0, decimalIndex) + decimals;
  }

  formatCommas(cursorPosition: number) {
    // Handle decimals
    let decimalIndex = this.el.value.indexOf('.');
    let decimals: string;
    if (decimalIndex > -1) {
      decimals = this.el.value.substring(decimalIndex);
    } else {
      decimalIndex = this.el.value.length;
      decimals = '';
    }

    // Handle dollar sign
    const dollarSignIndex = this.el.value.indexOf('$');
    const dollarSigns = this.el.value.substring(0, dollarSignIndex + 1);

    // Adjust relative cursor position
    let relativeCursorPosition;
    if (cursorPosition) {
      relativeCursorPosition = cursorPosition - dollarSigns.length;
    }

    // Handle body
    let body = this.el.value.substring(dollarSignIndex + 1, decimalIndex);

    // Strip commas and decrement cursor position
    while (body.includes(',')) {
      if (cursorPosition) {
        const currentCommaIndex = body.indexOf(',');
        if (relativeCursorPosition > currentCommaIndex) {
          cursorPosition = cursorPosition - 1;
          relativeCursorPosition = relativeCursorPosition - 1;
        }
      }
      body = body.replace(',', '');
    }

    // Add commas and increment cursor position
    let commaIndex = body.length;
    while (commaIndex > 3) {
      commaIndex = commaIndex - 3;
      body =  body.substring(0, commaIndex) + ',' + body.substring(commaIndex);
      if (cursorPosition) {
        if (relativeCursorPosition > commaIndex) {
          cursorPosition = cursorPosition + 1;
          relativeCursorPosition = relativeCursorPosition + 1;
        }
      }
    }
    this.el.value = dollarSigns + body + decimals;

    // If cursor position, set selected range
    if (cursorPosition) {
      this.el.setSelectionRange(cursorPosition, cursorPosition);
    }
  }

  checkForDeleteBackward() {
    // If we are not deleting dollar sign, do nothing
    if (this.el.value.startsWith('$') || this.el.value.startsWith('-$')) {

    // Handle situations trying to the delete dollar sign in negative values
    } else if (this.el.value.startsWith('-')) {
      this.el.value = '-$' + this.el.value.substring(1);

      // If only deleting the dollar sign (and not a selected range), delete the negative sign instead of the dollar sign
      // There is a "bug" here where highlighting the dollar sign and pressing the "delete" key...
      // is treated as a backspace against the dollar sign.
      if (this.el.value === this.history[this.history.length - 1]) {
        this.el.value = '$' + this.el.value.substring(2);
        this.el.setSelectionRange(1, 1);

      // Otherwise, keep the negative sign
      } else {
        this.el.setSelectionRange(2, 2);
      }

    // Do not allow dollar sign deletion
    } else {
      this.el.value = '$' + this.el.value;
      this.el.setSelectionRange(1, 1);
    }
  }

  checkForDeleteForward() {
    // If we are not deleting dollar sign, do nothing
    if (this.el.value.startsWith('$') || this.el.value.startsWith('-$')) {

    // Handle negative values
    } else if (this.el.value.startsWith('-') && !this.el.value.startsWith('-$')) {
      this.el.value = '-$' + this.el.value.substring(1);

      // If we are only deleting the dollar sign, do not do anything
      if (this.el.value === this.history[this.history.length - 1]) {
        this.el.setSelectionRange(1, 1);

      // If we are deleting more, move the cursor after dollar sign
      } else {
        this.el.setSelectionRange(2, 2);
      }

    // Do not allow dollar sign deletion
    } else {
      this.el.value = '$' + this.el.value;
      this.el.setSelectionRange(0, 0);
    }
  }

  // TODO: Take the max-value (number of body digits) as a parameter
  exceedsMaxValue() {
    // Handle decimals
    let decimalIndex = this.el.value.indexOf('.');
    let decimals: string;
    if (decimalIndex > -1) {
      decimals = this.el.value.substring(decimalIndex);
    } else {
      decimalIndex = this.el.value.length;
      decimals = '';
    }

    // Handle dollar sign
    const dollarSignIndex = this.el.value.indexOf('$');

    // Handle body
    let body = this.el.value.substring(dollarSignIndex + 1, decimalIndex);

    // Strip commas
    body = body.replace(/,/g, '');

    return (body.length > 9);
  }

  appendToHistory(value: string) {
    if (this.history[this.history.length - 1] !== value) {
      this.history.push(value);
      this.historyIndex = this.history.length - 1;
    }
  }

  handleUndo() {
    // event.preventDefault();
    // this.historyIndex = Math.max(0, this.historyIndex - 1);
    // this.el.value = this.history[this.historyIndex];
    // this.el.setSelectionRange(this.el.value.length, this.el.value.length);
  }

  handleRedo() {
    // event.preventDefault();
    // this.historyIndex = Math.min(this.history.length - 1, this.historyIndex + 1);
    // this.el.value = this.history[this.historyIndex];
    // this.el.setSelectionRange(this.el.value.length, this.el.value.length);
  }
}
