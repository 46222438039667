export class BrowserUtils {
  static getBrowserInfo(): any {
    const getBrowserName = (): string =>  {
      const agent: string = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf('edg') > -1:
          return 'edge';
        case agent.indexOf('opr') > -1 && !!(window as any).opr:
          return 'opera';
        case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
          return 'chrome';
        case agent.indexOf('trident') > -1:
          return 'ie';
        case agent.indexOf('firefox') > -1:
          return 'firefox';
        case agent.indexOf('safari') > -1:
          return 'safari';
        default:
          return 'other';
      }
    };

    const getBrowserVersion = (): string => {
      let userAgent: string = navigator.userAgent;
      let tem: RegExpMatchArray | RegExpExecArray | [];
      let matchTest: RegExpMatchArray = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(matchTest[1])) {
        tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return 'IE '+(tem[1] || '');
      }
      if (matchTest[1] === 'Chrome') {
        tem = userAgent.match(/\b(OPR|Edg)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg', 'IE Edge');
      }
      matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join(' ');
    };

    return {
      browserName: getBrowserName(),
      browserVersion: getBrowserVersion()
    };
  }
}
