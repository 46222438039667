import { ObjectValueChecker } from './object-value-checker';

export class LocationHierarchyUtils {
  static transformLocationLevelsToListNodes(data: any) {
    const locationList = LocationHierarchyUtils.getLocationLevelList(data);
    const locationListNodes = LocationHierarchyUtils.createNestedListNodes(locationList, locationList[locationList.length-1], locationList.length);

    return `<div class="location-hierarchy-container">${locationListNodes}</div>`;
  }

  static getLocationLevelList(data: any) {
    const locations: string[] = [
      data['LEVEL_1_NAME'],
      data['LEVEL_2_NAME'],
      data['LEVEL_3_NAME'],
      data['LEVEL_4_NAME'],
      data['LEVEL_5_NAME'],
      data['LEVEL_6_NAME'],
      data['LEVEL_7_NAME'],
      data['LEVEL_8_NAME']
    ];
    const list = [...new Set(locations)].filter((loc) => !ObjectValueChecker.isEmpty(loc) && !loc.toLowerCase().includes('cloned'));

    return list;
  }

  static createNestedListNodes(dataList, currentNode, currentCount) {
    if (currentCount <= 0) {
      return currentNode;
    }

    let node = ``;
    if (dataList.length === currentCount) {
      node = `<ul><li> ${currentNode}</li></ul>`;
    } else {
      node = `<ul><li> ${dataList[currentCount-1]}${currentNode}</li></ul>`;
    }
    return this.createNestedListNodes(dataList, node, currentCount-1);
  };
}
