export enum LineOfBusinessEnum {
  GL = "241",
  AL = "242",
  WC = "243"
}

export enum LineOfBusinessDescriptionEnum {
  GL = "General Liability",
  AL = "Auto Liability",
  WC = "Workers' Compensation",
  // Dmitri LOB mapping
  PR = "General Liability",
  UNK = "Unknown",
  PY = "General Liability",
  AD = "Auto Liability",
}
