import { DcFieldTypeEnum } from '@dc/core';

import { ClaimSearchQuery } from '@app/core/ccp/base-classes/claim-search-query';

import {
  SearchQueryType,
  SearchQueryField,
  SearchQueryOperator
} from 'src/component-library';


export class TokenizedPair {
  key: string;
  operator: SearchQueryOperator;
}

export class TokenizedQueryBuilder {
  query: ClaimSearchQuery;

  static buildDefaultQuery(value: string) {
    return new TokenizedQueryBuilder().addWildcard(value, [
      { key: 'CLAIM_NUMBER.keyword', operator: SearchQueryOperator.Wildcard },
      { key: 'CLAIMANT_LAST_NAME.keyword', operator: SearchQueryOperator.Wildcard },
      { key: 'CLAIMANT_FIRST_NAME.keyword', operator: SearchQueryOperator.Wildcard },
      { key: 'DATE_OF_EVENT.keyword', operator: SearchQueryOperator.Wildcard }
    ]).buildQuery();
  }

  constructor() {
    this.query = new ClaimSearchQuery(SearchQueryType.Tokenized);
  }

  buildQuery() {
    return this.query;
  }

  addWildcard(value: string, pairs: TokenizedPair[]) {
    if (value) {
      if (pairs && pairs.length) {
        this.query.text = value;
        const terms = value.split(/\s/g);

        for (const term of terms) {
          const values = [];
          const extraTerms = this.moreTerms(term);

          for (const pair of pairs) {
            const qField = new SearchQueryField({
              key: pair.key,
              type: DcFieldTypeEnum.Text,
              values: [
                this.applyAnalyzer(term, pair)
              ],
              operator: pair.operator
            });

            values.push(qField);

            for (const extraTerm of extraTerms) {
              const extraQField = new SearchQueryField({
                key: pair.key,
                type: DcFieldTypeEnum.Text,
                values: [
                  this.applyAnalyzer(extraTerm, pair)
                ],
                operator: pair.operator
              });

              values.push(extraQField);
            }
          }

          const field = new SearchQueryField({
            type: DcFieldTypeEnum.Text,
            values: values,
            operator: SearchQueryOperator.ConditionalOr
          });

          this.query.fields.push(field);
        }
      }
    }

    return this;
  }

  private padLeft(value: string, length: number) {
    length = length - value.length;
    if (length < 0) { length = 0; }

    const pad = '0'.repeat(length);
    return `${pad}${value}`;
  }

  private moreTerms(value: string): string[] {

    // Note:
    //  Dates are stored in Elasticsearch in ISO 8601 format,
    //  in order for them to be searchable in a more human friendly
    //  format (eg: 11/13/2019), we need to do some conversions.
    //
    //  We also need to support partial date formats, since
    //  the suggestions need to be provided as the user types.
    //
    //  One thought regarding the question marks below (?), instead
    //  of matching any one character like that, we could pick the
    //  values out of todays year, maybe that gives better suggestions?
    //
    //  Also need to think through that I've covered all cases, we shouldn't
    //  have to worry about covering "too many" cases, since these are just
    //  additional terms that should be searched for, we will always search
    //  for the term as the user entered it.
    //                    - Brian Rowlett, 2019-11-15

    let match;
    if (match = value.match(/^(\d{1,2})[\.\-\/]$/)) {

      // Matches:
      //    m.  mm.
      //    m-  mm-
      //    m/  mm/
      //
      // Replacement:
      //    ????-mm-
      //                    - Brian Rowlett, 2019-11-15

      return [`????-${this.padLeft(match[1], 2)}-`];
    } else if (match = value.match(/^(\d{1,2})[\.\-\/](\d{1,2})[\.\-\/]?$/)) {

      // Matches:
      //    m.d(.)  mm.d(.)  m.dd(.)  mm.dd(.)
      //    m-d(-)  mm-d(-)  m-dd(-)  mm-dd(-)
      //    m/d(/)  mm/d(/)  m/dd(/)  mm/dd(/)
      //
      // Replacement:
      //    ????-mm-dd
      //                    - Brian Rowlett, 2019-11-15

      return [`????-${this.padLeft(match[1], 2)}-${this.padLeft(match[2], 2)}`];
    } else if (match = value.match(/^(\d{1,2})[\.\-\/](\d{1,2})[\.\-\/](\d{1})$/)) {

      // Matches:
      //    m.d.y  mm.d.y  m.dd.y  mm.dd.y
      //    m-d-y  mm-d-y  m-dd-y  mm-dd-y
      //    m/d/y  mm/d/y  m/dd/y  mm/dd/y
      //
      // Replacement:
      //    ???y-mm-dd
      //                    - Brian Rowlett, 2019-11-15

      return [`???${match[3]}-${this.padLeft(match[1], 2)}-${this.padLeft(match[2], 2)}`];
    } else if (match = value.match(/^(\d{1,2})[\.\-\/](\d{1,2})[\.\-\/](\d{2})$/)) {

      // Matches:
      //    m.d.yy  mm.d.yy  m.dd.yy  mm.dd.yy
      //    m-d-yy  mm-d-yy  m-dd-yy  mm-dd-yy
      //    m/d/yy  mm/d/yy  m/dd/yy  mm/dd/yy
      //
      // Replacement:
      //    ??yy-mm-dd
      //                    - Brian Rowlett, 2019-11-15

      return [`??${match[3]}-${this.padLeft(match[1], 2)}-${this.padLeft(match[2], 2)}`];
    } else if (match = value.match(/^(\d{1,2})[\.\-\/](\d{1,2})[\.\-\/](\d{3})$/)) {

      // Matches:
      //    m.d.yyy  mm.d.yyy  m.dd.yyy  mm.dd.yyy
      //    m-d-yyy  mm-d-yyy  m-dd-yyy  mm-dd-yyy
      //    m/d/yyy  mm/d/yyy  m/dd/yyy  mm/dd/yyy
      //
      // Replacement:
      //    yyy?-mm-dd
      //                    - Brian Rowlett, 2019-11-15

      return [`${match[3]}?-${this.padLeft(match[1], 2)}-${this.padLeft(match[2], 2)}`];
    } else if (match = value.match(/^(\d{1,2})[\.\-\/](\d{1,2})[\.\-\/](\d{4})$/)) {

      // Matches:
      //    m.d.yyyy  mm.d.yyyy  m.dd.yyyy  mm.dd.yyyy
      //    m-d-yyyy  mm-d-yyyy  m-dd-yyyy  mm-dd-yyyy
      //    m/d/yyyy  mm/d/yyyy  m/dd/yyyy  mm/dd/yyyy
      //
      // Replacement:
      //    yyyy-mm-dd
      //                    - Brian Rowlett, 2019-11-15

      return [`${match[3]}-${this.padLeft(match[1], 2)}-${this.padLeft(match[2], 2)}`];
    }

    // Note:
    //  Below are a few additional (although probably less common)
    //  date formats. But our users deserve only the best, amirite?
    //                    - Brian Rowlett, 2019-11-15

    if (match = value.match(/^(\d{4})[\.\-\/]$/)) {

      // Matches:
      //    yyyy.
      //    yyyy-
      //    yyyy/
      //
      // Replacement:
      //    yyyy-
      //                    - Brian Rowlett, 2019-11-15

      return [`${match[1]}-`];
    } else if (match = value.match(/^(\d{4})[\.\-\/](\d{1,2})[\.\-\/]?$/)) {

      // Matches:
      //    yyyy.m(.)  yyyy.mm(.)
      //    yyyy-m(-)  yyyy-mm(-)
      //    yyyy/m(/)  yyyy/mm(/)
      //
      // Replacement:
      //    yyyy-mm-
      //                    - Brian Rowlett, 2019-11-15

      return [`${match[1]}-${this.padLeft(match[2], 2)}-`];
    } else if (match = value.match(/^(\d{4})[\.\-\/](\d{1,2})[\.\-\/](\d{1,2})$/)) {

      // Matches:
      //    yyyy.m.d  yyyy.mm.d  yyyy.m.dd  yyyy.mm.dd
      //    yyyy-m-d  yyyy-mm-d  yyyy-m-dd  yyyy-mm-dd
      //    yyyy/m/d  yyyy/mm/d  yyyy/m/dd  yyyy/mm/dd
      //
      // Replacement:
      //    yyyy-mm-dd
      //                    - Brian Rowlett, 2019-11-15

      return [`${match[1]}-${this.padLeft(match[2], 2)}-${this.padLeft(match[3], 2)}`];
    }

    return [];
  }

  private applyAnalyzer(value: string, pair: TokenizedPair) {

    // Note:
    //  When Elasticsearch builds the inverse index, each field is
    //  passed through an `analyzer`, by default, this process applies
    //  both lowercase and asciifolding. However, the analyzer is not
    //  applied automatically when you search.
    //
    //  For a keyword field, this is called a `normalizer`, the difference
    //  being that only one token is emitted, and importantly, it is applied
    //  automatically.
    //
    //  Since analyzers are not applied automatically, we have to apply it.
    //                    - Brian Rowlett, 2019-11-15

    if (!pair.key.endsWith('.keyword')) {

      // Important Note:
      //  I am going to turn asciifolding off for now,
      //  it can lead to confusing results, for example:
      //
      //  Search for: 4-8- (April 8th, about to type a year)
      //  And you get results that include the following claimant
      //  name: Unit 481 Fort Smith.
      //
      //  This is because when ascii folding, (maybe just with
      //  this niave implementation), the hyphens get removed and
      //  we start to match things we didn't mean to...
      //
      //  In the mean time, this will mean that if you search
      //  for a name that includes characters with accents, you
      //  may not find a match...
      //                    - Brian Rowlett, 2019-11-15

      return value.toLowerCase();
      // .normalize('NFKD').replace(/[^\w]/g, '');
    }

    return value;
  }
}
