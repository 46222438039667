import { Injectable } from '@angular/core';
import * as XLSX from '@e965/xlsx';

import { DcColumn, DcDataFormatEnum } from '@dc/core';

import { ExcelConfig } from './excel.const';

@Injectable()
export class ExcelService {
  public exportAsExcelFile(json: any[], excelFileName: string, excelSheetName: string, columns?: any[]): void {
    const worksheet = XLSX.utils.json_to_sheet(json);

    if (columns) {
      this.formatCellData(worksheet, this.mapColumnFields(columns));
    }

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, excelSheetName);
    XLSX.writeFile(workbook, excelFileName);
  }

  // Get only the headers and the format. This is best done here than in the
  // component level.
  private mapColumnFields(columns: any[]): DcColumn[] {
    const formattedColumnObj: DcColumn[] = [];
    columns.forEach(column => formattedColumnObj.push(new DcColumn(column)));
    return formattedColumnObj;
  }

  private formatCellData(worksheet: XLSX.WorkSheet, columns: DcColumn[]) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    const startingRow = range.s.r;
    const startingColumn = range.s.c;
    const endingRow = range.e.r;
    const endingColumn = range.e.c;

    for (let columnIndex = startingColumn; columnIndex <= endingColumn; ++columnIndex) {
      const column = columns[columnIndex];

      // Set format for entire column
      let columnType;

      for (let rowNum = startingRow; rowNum <= endingRow; ++rowNum) {
        // Get the cell object from the column index and row number
        const cellObj = worksheet[XLSX.utils.encode_cell({ c: columnIndex, r: rowNum })];

        // Check if the cell value (cellObj.v) is equal to the columnHeader and set the columnType
        if (cellObj.v === column.header) {
          columnType = DcDataFormatEnum[column.format.toString()];
        } else {
          // Format the cells
          switch (columnType) {
            case DcDataFormatEnum.Date:
              // Do not format null date
              if (cellObj.v !== null) {
                cellObj.v = new Date(cellObj.v).format(ExcelConfig.dateFormat);
              }
              break;
            case DcDataFormatEnum.DateTime:
              // Do not format null datetime
              if (cellObj.v !== null) {
                this.setCellFormat(cellObj, {
                  dataType: ExcelConfig.dataType.date,
                  dataFormat: ExcelConfig.dateTimeFormat
                });
              }
              break;
            case DcDataFormatEnum.Money:
              this.setCellFormat(cellObj, {
                dataFormat: ExcelConfig.currencyFormat
              });
              break;
            case DcDataFormatEnum.NullableNumber:
              this.setCellFormat(cellObj, {
                dataType: ExcelConfig.dataType.number
              });
              break;
            case DcDataFormatEnum.Time:
              // Do not format null time
              if (cellObj.v !== null) {
                this.setCellFormat(cellObj, {
                  dataType: ExcelConfig.dataType.date,
                  dataFormat: ExcelConfig.timeFormat
                });
              }
              break;
            default: break;
          }
        }
      }
    }
  }

  private setCellFormat(cell: any, options?: any) {
    // Remove the original format for the new format to take effect
    cell.w = undefined;

    // Set the data type
    if (options.hasOwnProperty('dataType')) {
      cell.t = options.dataType;
    }

    // Set the new data format
    if (options.hasOwnProperty('dataFormat')) {
      cell.z = options.dataFormat;
    }
  }
}
