
import {switchMap} from 'rxjs/operators';
import {
  Injectable
} from '@angular/core';
import { of, Observable } from 'rxjs';

import {
  SearchQueryOperator
} from 'src/component-library';
import { EventType } from '../../enums/event-type';
import {
  ClaimSearchQueryConverter
} from '../../utils/claim-search-query-converter';
import {
  TokenizedPair,
  TokenizedQueryBuilder
} from '../../utils/tokenized-query-builder';
import { AdvancedQueryBuilder } from '../../utils/advanced-query-builder';
import { WeightByClaimId } from '../../utils/search-query-adapters';
import { HistoryService } from '../history/history.service';
import {
  ClaimSearchPayload,
  ClaimSearchService,
  ClaimSearchSortDirection
} from '../http/claim-search/claim-search.service';
import {
  QuickSearchAction
} from '../http/claim-search/quick-search/quick-search';

export class ClaimSuggestion {
  navigateURL: string;

  constructor(public claimId: number,
              public claimType: string,
              public claimNumber: string,
              public claimantLastName: string,
              public claimantFirstName: string,
              public dateOfLoss: string, /* Moment? */
              public sourceSystem: string,
              public tpaCode: string,) {

    this.navigateURL = '';
    if (sourceSystem === 'DM') {
      let _claimType = claimType || 'UNK';
      this.navigateURL = `/claims/${sourceSystem}/${tpaCode}/${_claimType}/${claimNumber}`;
    } else {
      this.navigateURL = `/claims/${claimType}/${claimNumber}/${claimId}`;
    }
  }
}

export class GroupedSuggestion {
  items: GroupedSuggestionItem[];

  constructor(public name: string) {
    this.items = [];
  }
}

export class GroupedSuggestionItem {
  constructor(public text: string,
              public action: QuickSearchAction) {
  }
}

export class GroupedSuggestionConfig {
  name: string;
  pairs: TokenizedPair[];
  generate: GroupedSuggestionGenerator;

  constructor(other: GroupedSuggestionConfig) {
    this.name = other.name;
    this.pairs = other.pairs;
    this.generate = other.generate;
  }
}

export type GroupedSuggestionGenerator = (result: any) => GroupedSuggestionItem;

export class SuggestionResult {
  suggestedClaims: ClaimSuggestion[];
  groupedSuggestions: GroupedSuggestion[];

  constructor() {
    this.suggestedClaims = [];
    this.groupedSuggestions = [];
  }
}

@Injectable()
export class SuggestionService {
  groupedSuggestionConfigs: GroupedSuggestionConfig[];

  constructor(private historyService: HistoryService,
              private claimSearchService: ClaimSearchService) {
    this.groupedSuggestionConfigs = [
      new GroupedSuggestionConfig({
        name: 'Adjuster',
        pairs: [
          { key: 'ADJUSTER_LAST_NAME', operator: SearchQueryOperator.Prefix },
          { key: 'ADJUSTER_FIRST_NAME', operator: SearchQueryOperator.Prefix }
        ],
        generate: (result: any) => {
          const adjusterLastName = result['ADJUSTER_LAST_NAME'];
          const adjusterFirstName = result['ADJUSTER_FIRST_NAME'];

          const text = `${adjusterFirstName} ${adjusterLastName}`;

          const claimSearchQuery = new AdvancedQueryBuilder()
            .addAdjusterLastName('Adjuster Last Name', adjusterLastName, SearchQueryOperator.Equal)
            .addAdjusterFirstName('Adjuster First Name', adjusterFirstName, SearchQueryOperator.Equal)
            .buildQuery();

          const action = new QuickSearchAction({
            navigateURL: '/search/claims',
            claimSearchQuery: claimSearchQuery
          });

          return new GroupedSuggestionItem(text, action);
        }
      })
    ];
  }

  getMatchingSuggestions(value: string): Observable<SuggestionResult> {
    return this.historyService
      .getCachedHistory('Recent', EventType.Claim_View, 10).pipe(
        switchMap(recentClaims => {
          return this.historyService
            .getCachedHistory('Frequent', EventType.Claim_View, 10).pipe(
              switchMap(frequentClaims => {
                const claimIds = recentClaims.map(recClaim => recClaim.claimData ? recClaim.claimData.CLAIM_ID : null)
                  .concat(frequentClaims.map(freqClaim => freqClaim.claimData ? freqClaim.claimData.CLAIM_ID : null))
                  .filter(val => val !== null);

                const payload = {};
                payload['SuggestedClaims'] = this.getSuggestedClaims(value, claimIds);

                for (const groupedSuggestionConfig of this.groupedSuggestionConfigs) {
                  const key = `ListClaimsBy${groupedSuggestionConfig.name}`;
                  payload[key] = this.getGroupedSuggestions(value, groupedSuggestionConfig);
                }

                return this.claimSearchService
                  .getSearchSuggestions(payload)
                  .pipe(switchMap(claimSearchOutput => {
                    const suggestionResult = new SuggestionResult();
                    for (const claim of claimSearchOutput.SuggestedClaims.results) {
                      const claimSuggestion = new ClaimSuggestion(claim['CLAIM_ID'], claim['LINE_OF_BUSINESS'],
                        claim['CLAIM_NUMBER'], claim['CLAIMANT_LAST_NAME'], claim['CLAIMANT_FIRST_NAME'], claim['EVENT_ACC_DATE'],claim['SOURCE_SYSTEM'], claim['TPA_CODE']);

                      suggestionResult.suggestedClaims.push(claimSuggestion);
                    }

                    for (const groupedSuggestionConfig of this.groupedSuggestionConfigs) {
                      const key = `ListClaimsBy${groupedSuggestionConfig.name}`;
                      const groupedSuggestion = new GroupedSuggestion(groupedSuggestionConfig.name);

                      for (const result of claimSearchOutput[key].results) {
                        groupedSuggestion.items.push(groupedSuggestionConfig.generate(result));
                      }

                      suggestionResult.groupedSuggestions.push(groupedSuggestion);
                    }

                    return of(suggestionResult);
                  }));
              }));
        }));
  }

  private getSuggestedClaims(value: string, claimIds: string[]) {
    const sort = {
      _score: ClaimSearchSortDirection.Descending
    };

    const query = TokenizedQueryBuilder.buildDefaultQuery(value);
    const clauses = ClaimSearchQueryConverter.convertToElasticsearchQuery(query);

    const columns = []; // Note: Defaults to all columns.
    return new ClaimSearchPayload(5, 0, columns, sort, WeightByClaimId(clauses, claimIds));
  }

  private getGroupedSuggestions(value: string, groupedSuggestionConfig: GroupedSuggestionConfig) {
    const sort = {
      _score: ClaimSearchSortDirection.Descending
    };

    const columns = []; // Note: Defaults to all columns.
    return new ClaimSearchPayload(1, 0, columns, sort,
      ClaimSearchQueryConverter.convertToElasticsearchQuery(
        new TokenizedQueryBuilder()
          .addWildcard(value, groupedSuggestionConfig.pairs)
          .buildQuery()
      )
    );
  }
}
