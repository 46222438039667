import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';
import { IAlert } from '@app/core/ccp/components/alert/alert';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertsApiService {

  constructor(protected httpClient: HttpClient, @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) { }

  getAlerts(): Observable<IAlert[]> {
    const url = `${this.environment.appConfig.apiUrl}/alerts`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.get<IAlert[]>(url, options);
  }
}
