import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AUTH_CONFIG, AuthService, SessionService as AuthSessionService, IAppState, IAuthConfig, LoggingService as AuthLoggingService } from '@auth/auth';
import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { User } from '../../models/user/user';
import { EventType } from '../../enums/event-type';
import { DatabaseLoggingResponse, LoggingService } from '../logging/logging.service';
import { HistoryService } from '../history/history.service';

@Injectable()
export class SessionService extends AuthSessionService<IAppState> {

  private clientConnectUser$ = new ReplaySubject<User>();

  constructor(private localLoggingService: LoggingService,
    private historyService: HistoryService,
    private router: Router,
    @Inject(AUTH_CONFIG) authConfig: IAuthConfig,
    authLoggingService: AuthLoggingService,
    authService: AuthService<IAppState>) {
    super(authConfig, authLoggingService, authService);
  }

  terminateSession(): void {
    this.logout();
  }

  getIdToken(): Observable<any> {
    return this.idTokenClaims$;
  }

  getUserProfile(): Observable<any> {
    return this.user$;
  }

  getUser(): Observable<User> {
    return this.clientConnectUser$.asObservable();
  }

  log(eventType: EventType, message: string, eventData?: any, error?: Error, callback?: Function, params?: Params): void {
    this.user$.pipe(switchMap((profile: any) => {
      let userId = 'None';
      if (profile && profile['https://conan.crawco.com/userid']) {
        userId = profile['https://conan.crawco.com/userid'];
      }
      return this.localLoggingService.log(userId, eventType, message, eventData, error, params);
    })).subscribe((response: DatabaseLoggingResponse) => {
      if (eventType === EventType.Successful_Login) {
        const user = new User(response.userAttributes);
        this.clientConnectUser$.next(user);
        this.historyService.setCachedHistory(response.userHistory);
      } else if (eventType === EventType.Claim_View || eventType === EventType.Claim_Search) {
        this.historyService.setCachedHistory(response.userHistory);
      }
      if (callback) {
        callback();
      }
    }, err => {
      if (eventType === EventType.Successful_Login && err instanceof HttpErrorResponse) {
        if (err.status === 403) {
          this.router.navigate(['/session/general-error/403']);
        } else {
          if (this.router.url && this.router.url.includes('session/callback')) {
            this.router.navigate(['/session/error'], { queryParams: { action: 'logout' } });
          } else {
            this.router.navigate(['/session/error']);
          }
        }
      }
    });
  }
}
