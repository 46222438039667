import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  ClaimSearchInput,
  ClaimSearchOutput,
  ClaimSearchService
} from './claim-search.service';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

@Injectable()
export class ClaimSearchApiService implements ClaimSearchService {

  constructor(protected httpClient: HttpClient, @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) { }
  ///
  /// Usage:
  ///
  ///   <size>    - The number of records to get.
  ///   <offset>  - The number of records to skip.
  ///   <sort>    - The `key` must be an Elasticsearch field and the `value` must be either
  ///               ClaimSearchSortDirection.Ascending or ClaimSearchSortDirection.Descending.
  ///   <clauses> - The result from calling ClaimSearchQueryConvert.convert with a query.
  ///
  ///       const claimSearchInput = {
  ///         Search1: new ClaimSearchPayload(<size>, <offset>,
  ///           ClaimSearchQueryConverter.convertToElasticsearchSort(sort1),
  ///           ClaimSearchQueryConverter.convertToElasticsearchQuery(claimSearchQuery1)
  ///         ),
  ///         Search2: new ClaimSearchPayload(<size>, <offset>,
  ///           ClaimSearchQueryConverter.convertToElasticsearchSort(sort2),
  ///           ClaimSearchQueryConverter.convertToElasticsearchQuery(claimSearchQuery2)
  ///         )
  ///       };
  ///
  ///   this.claimSearchService
  ///     .getSearchResults(claimSearchInput)
  ///     .subscribe(results => console.log(results));
  ///
  ///   In the ClaimSearchInput, we can specify a `name` for each query,
  ///   the results that come back will be keyed off that same `name` and
  ///   will be of type ClaimSearchResponse.
  ///
  getSearchResults(input: ClaimSearchInput): Observable<ClaimSearchOutput> {
    const url = `${this.environment.appConfig.apiUrl}/searchClaims`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.post<ClaimSearchOutput>(url, input, options);
  }

  getSearchSuggestions(input: ClaimSearchInput): Observable<ClaimSearchOutput> {
    const url = `${this.environment.appConfig.apiUrl}/suggestClaims`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.post<ClaimSearchOutput>(url, input, options);
  }
}
