export class EndpointSpecification {
  path: string;
  parameters: [{
    key: string,
    dataWeave: string,
    required: boolean
  }];
}

export class ValueExtractSpecification {
  ExpressionKey: string;
  Expression: string;
}

export class NewEndpointSpecification {
  Path: string;
  Api: string;
  Weight: number;
  ValueExtract: ValueExtractSpecification;
  Parameters: [{
    Key: string,
    Alias: string,
    ValueExtract: ValueExtractSpecification,
    Required: boolean,
    DataType: string
  }];
}

export class KeySpecification {
  key: string;
  path: string;
  dataWeave: string;
  description: string;
}

export class NewKeySpecification {
  Key: string;
  Description: string;
  Endpoints: NewEndpointSpecification[];
}
