import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AtomsModule } from '../../component-library';
import { SearchCriteriaComponent } from './search-criteria/search-criteria.component';

@NgModule({
  declarations: [
    SearchCriteriaComponent
  ],
  imports: [
    CommonModule,
    AtomsModule
  ],
  exports: [
    SearchCriteriaComponent
  ]
})
export class SharedModule { }
