import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CdsNavigationItem } from '@cds/angular';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

import {
  Menu,
  MenuService } from './menu.service';
import { QuickClaimInfoComponent } from '../ccp';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent {
  @Input() collapsed?: boolean;

  currentLang = 'en';

  menuItems: CdsNavigationItem[];

  menuWidget: any;
  dynamicComponents = {
    'None': null,
    'QuickClaimInfo': QuickClaimInfoComponent
  };
  inputs = { };

  constructor(public menuService: MenuService,
              public translate: TranslateService,
              public activatedRoute: ActivatedRoute,
              @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) {
    menuService.menuItems.subscribe(menuItems => {
      const params = this.activatedRoute.snapshot.children[0].params;
      if (this.environment.appConfig.demo) {
        menuItems = menuItems?.filter((menuItem: any) => {
          return menuItem.path !== 'diaries';
        });
      }
      if (params['source'] === 'DM' && params['tpa'] !== null) {
        menuItems = menuItems?.filter((menuItem: any) => {
          return menuItem.name !== 'Medical Bills' && menuItem.name !== 'Attachments';
        });
      }
      this.menuItems = menuItems ? menuItems.map(item => this.convertToNavigationItem(item)) : [];
    });
    menuService.menuWidgetConfig.subscribe(menuWidgetConfig => {
      this.menuWidget = this.dynamicComponents[menuWidgetConfig.widget];
      this.inputs['parameters'] = menuWidgetConfig.parameters;
    });
  }

  convertToNavigationItem(menu: Menu) {
    return {
      url: menu.routerLink,
      title: menu.name,
      iconName: menu.icon,
      children: menu.children ? menu.children.map(item => this.convertToNavigationItem(item)) : null
    };
  }
}
