import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

fetch('/configs/environment.json')
  .then(response => response.json())
  .then(environment => start(environment))
  .catch(reason => console.error(reason));

function start(environment: AppEnvironment) {
  if (environment?.appConfig?.production ?? environment?.appConfig?.qa) {
    enableProdMode();
  }

  const extraProviders = [
    {
      provide: APP_ENVIRONMENT,
      useValue: environment,
    }
  ];


  platformBrowserDynamic(extraProviders)
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}
