import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { v4 as Uuidv4 } from 'uuid';

import {
  DcFieldTypeEnum,
  DcSectionTypeEnum,
  DcStatusIndicatorEnum,
  DcMathUtils
} from '@dc/core';

import { ObjectValueChecker } from '@app/core/ccp/utils/object-value-checker';

import { DataRequestUtils } from '../../http/universal-claim-retriever/data-request';
import { DataResponseUtils } from '../../http/universal-claim-retriever/data-response';
import { PdfCustomDataConfig } from '../pdf-definitions';
import { PdfConverterUtils } from '../utils/pdf-converter.utils';

const htmlToPdfMake = require('html-to-pdfmake');
const FRACTION_DIGITS = 2;

@Injectable()
export class DynamicPanelToPdfConfigService {
  private appendixCounter = 0;
  private appendixKeysMap = new Map<number, string>();
  private appendixNamesMap = new Map<string, string>();

  constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private router: Router) {
    this.createAppendixKeysMap();
  }

  /**
   * Main function that converts the master data into a PDF config object
   */
  convert(pageTitle: string, data: any, keysToRemoveCodes: string[] = []) {
    const downloadDate = PdfConverterUtils.getESTDateTime();
    const claimantName = data.values['CLAIMANT_NAME'];
    const claimNumber = data.values['CLAIM_NUMBER'];
    const url = window.location.origin + this.router.url;
    const sections = this.getFormattedSections(data, keysToRemoveCodes);
    const contents = this.convertContentToPdfConfig(sections);
    return {
      pageTitle,
      downloadDate,
      claimantName,
      claimNumber,
      url,
      contents
    };
  }

  /**
   * This provided data into a pdf config section object e.g. for fields that display the data via modal
   */
  convertSection(data: any, config: PdfCustomDataConfig, keysToRemoveCodes: string[] = []) {
    let sectionNameOverride: string;

    if (config.sectionName) {
      sectionNameOverride = config.sectionName;
    } else if (config.appendixNameKey) {
      sectionNameOverride = this.appendixNamesMap.get(config.appendixNameKey);
    }

    const sections = this.getFormattedSections(data, keysToRemoveCodes, config.displayOnNextPage, sectionNameOverride);
    return this.convertContentToPdfConfig(sections);
  }

  /**
   * Maps the letters of the alphabet to their associated number keys.
   * This is mainly used for naming the appendix section.
   */
  private createAppendixKeysMap() {
    const alphabetLength = 26;
    const asciiCode = 65; // Represents letter A

    for (const key of Array(alphabetLength).keys()) {
      this.appendixKeysMap.set(key + 1, String.fromCharCode(key + asciiCode));
    }
  }

  private getFormattedSections(data: any, keysToRemoveCodes: string[] = [], displayOnNextPage: boolean = false,
    sectionNameOverride?: string) {
    const { layout: { sections } } = data;

    const sectionsToFormat = sections.filter((sec) => this.toDisplay(data.values, sec.displayWhen)).map(section => {
      const columnContents = section.columns.map((column: any) =>
        column.fields.filter((field: any) => field.type !== DcFieldTypeEnum.Hidden).map((field: any) => this.getFieldDetails(data, field, keysToRemoveCodes)));

      return {
        name: sectionNameOverride ? sectionNameOverride : section.name,
        type: section.type,
        columnContents,
        displayOnNextPage,
        flags: section.flags
      };
    });

    return sectionsToFormat.map((section: any) => this.parseSectionsAndContents(section));
  }

  private toDisplay(dataStore: any, selector?: string) {
    if (dataStore && selector !== undefined) {
      const jumps = selector.split('.');

      while (dataStore && jumps.length) {
        dataStore = dataStore[jumps.shift()];
      }

      return Boolean(dataStore);
    }
    return true;
  }

  private getFieldDetails(data: any, field: any, keysToRemoveCodes: string[]) {
    // Remove the codes from data first
    if (field.dataSource in keysToRemoveCodes) {
      DataResponseUtils.removeCodeFromData(data, [field.dataSource]);
    }

    const _field = {
      label: field.name,
      type: field.type
    };

    if (field.hasHTMLElement) {
      _field['hasHTMLElement'] = field.hasHTMLElement;
    }

    if (field.type === DcFieldTypeEnum.Table) {
      const { tableDefinition } = field;
      const additionalTableDetails = tableDefinition.columns.map((column: any) => {
        return {
          header: column.header,
          dataSource: column.dataSource,
          format: column.format,
          alignment: column.alignment,
        };
      });

      if (tableDefinition.flags && tableDefinition.flags.includeTotals) {
        additionalTableDetails['includeTotals'] = tableDefinition.flags.includeTotals;
      }
      _field['value'] = this.extractFieldValue(data, field, additionalTableDetails);
    } else {
      _field['value'] = this.extractFieldValue(data, field);
    }

    return _field;
  }

  private parseSectionsAndContents(section: { name: string, type: string, columnContents: any[], displayOnNextPage: boolean, flags: any }) {
    const contents = section.columnContents.map(content => {
      switch (section.type) {
        case DcSectionTypeEnum.Note:
          const beautifiedContent = PdfConverterUtils.beautifyHtml(content[0].value);
          const noteDocumentDefinition = PdfConverterUtils.convertToDocumentDefinition(beautifiedContent);
          let stackNoteDocumentDefinition = noteDocumentDefinition;
          if (Array.isArray(noteDocumentDefinition)) {
            const noteDocumentDefinitionsWithID = noteDocumentDefinition.map(note => {
              note['id'] = `note-${Uuidv4()}`; // note: Uuidv4 is rquired to make id unique for pdfmake
              return note;
            });
            stackNoteDocumentDefinition = noteDocumentDefinitionsWithID;
          }
          return { stack: [stackNoteDocumentDefinition], style: 'content', noWrap: false };
        case DcSectionTypeEnum.Status:
          return this.parseStatusIndicatorContent(content);
        case DcSectionTypeEnum.Table:
          return this.parseTableContent(content);
        default:
          return content.map(subContent => {
            const label = PdfConverterUtils.generateLabel(subContent.label, 'right', `field-label-${Uuidv4()}`); // note: Uuidv4 is rquired to make id unique for pdfmake
            let value = null;

            if (subContent.hasHTMLElement) {
              const beautifiedContent = PdfConverterUtils.beautifyHtml(subContent.value);
              const documentDefinition = PdfConverterUtils.convertToDocumentDefinition(beautifiedContent);
              value = { stack: [documentDefinition], style: 'content', noWrap: false };
            } else {
              if (subContent.type === 'Paragraph') {
                subContent.value = PdfConverterUtils.beautifyHtml(subContent.value);
              }
              value = PdfConverterUtils.generateText(htmlToPdfMake(subContent.value), 'left', `field-value-${Uuidv4()}`);
            }

            return [label, value];
          });
      }
    });
    return {
      name: section.name,
      type: section.type,
      contents,
      displayOnNextPage: section.displayOnNextPage,
      flags: section.flags
    };
  }

  private parseStatusIndicatorContent(content: any) {
    const statusIndicatorObj = content.find(c => c.type === DcFieldTypeEnum.StatusIndicator);
    const currentValue = statusIndicatorObj.value.currentValue;
    const maxValue = statusIndicatorObj.value.maxValue;
    const displayOrientation = statusIndicatorObj.value.displayOrientation;
    const statusIndicatorLabel = PdfConverterUtils.generateLabel(statusIndicatorObj.label, 'right');
    let statusIndicatorProgress: any = PdfConverterUtils.generateText('No Data');
    let statusIndicatorSubLabel: any = null;

    if (!ObjectValueChecker.isEmpty(currentValue) && !ObjectValueChecker.isEmpty(maxValue) && maxValue != 0) {
      statusIndicatorProgress = PdfConverterUtils.generateStatusIndicator(maxValue, currentValue, displayOrientation, statusIndicatorObj.reversed);
      statusIndicatorSubLabel = PdfConverterUtils.generateSubfield(statusIndicatorObj.value.subField);
    }

    return { statusIndicatorLabel, statusIndicatorProgress, statusIndicatorSubLabel, displayOrientation };
  }

  private parseTableContent(content: any) {
    const headers = content[0].value['headers'];
    const rows = content[0].value['rows'];
    const totals = content[0].value['totals'] || [];
    const tableContent = {
      headers: headers.map(header => PdfConverterUtils.generateLabel(header.value, header.alignment)),
      rows: [...rows.map(row => {
        return row.map(r => {
          return r.format === DcFieldTypeEnum.Money ?
            PdfConverterUtils.generateText(this.currencyPipe.transform(r.value), r.alignment) :
            PdfConverterUtils.generateText(r.value, r.alignment);
        });
      })
      ]
    };

    if (totals.length > 0) {
      tableContent.rows.push(
        totals.map(total => {
          const text = total.format === DcFieldTypeEnum.Money ?
            PdfConverterUtils.generateLabel(this.currencyPipe.transform(total.value), total.alignment) :
            PdfConverterUtils.generateLabel(total.value, total.alignment);
          const borderAndText = {
            border: [true, true, false, false],
            text
          };
          return total.value === 'Totals' ? borderAndText : text;
        }));
    }

    return tableContent;
  }

  private extractFieldValue(data: any, field: any, additionalTableDetails?: any[]) {
    const dataFromSource = data.values[field.dataSource];
    const emptyValue = ObjectValueChecker.isEmpty(dataFromSource);

    if ((field.type !== DcFieldTypeEnum.Table && field.type !== DcFieldTypeEnum.Combined && field.type !== DcFieldTypeEnum.StatusIndicator) && emptyValue) {
      return 'No data';
    }

    switch (field.type) {
      case DcFieldTypeEnum.Combined:
        const combinedFields = DataRequestUtils.getCombinedFields(field);
        const separator = field.separator || ' ';
        const combinedValue = combinedFields.map(subField => data.values[subField.dataSource]).join(separator)?.trim();
        return !combinedValue ? 'No Data' : combinedValue;
      case DcFieldTypeEnum.Date:
        return this.datePipe.transform(dataFromSource, 'MM/dd/yyyy');
      case DcFieldTypeEnum.DateTime:
        return this.datePipe.transform(dataFromSource, 'MM/dd/yyyy h:mm aa');
      case DcFieldTypeEnum.Link:
        const appendixName = `Appendix ${this.appendixKeysMap.get(++this.appendixCounter)}`;
        this.appendixNamesMap.set(field.name, `${appendixName}: ${field.name}`); // Map field name to an appendix name
        return `See ${appendixName}`;
      case DcFieldTypeEnum.Money:
        return this.currencyPipe.transform(dataFromSource);
      case DcFieldTypeEnum.StatusIndicator:
        return this.getStatusIndicatorValues(data, field);
      case DcFieldTypeEnum.Table:
        return this.getTableValues(data, field, additionalTableDetails);
      case DcFieldTypeEnum.Flag:
        return !!dataFromSource ? 'Yes' : 'No';
      default:
        return dataFromSource;
    }
  }

  /**
   * This extracts the values of the status indicator from the provided data and field.
   * Caveat: This only gets the constant values or the data value from the field datasource.
   */
  private getStatusIndicatorValues(data: any, field: any) {
    const type = field.indicatorType;
    const currentValue = field.currentValue ?? data.values[field.currentValueDataSource] ?? data.values[field.currentValueCallback];
    const maxValue = field.maxValue || data.values[field.maxValueDataSource];
    const showDifference = field.showDifference;
    const displayDecimalNumbers = field.displayDecimalNumbers;

    let value;

    if (ObjectValueChecker.isEmpty(currentValue) || ObjectValueChecker.isEmpty(maxValue)) {
      return {
        displayOrientation: field.displayOrientation,
        currentValue: currentValue,
        maxValue: maxValue,
        subField: null
      };
    }

    switch (type) {
      case DcStatusIndicatorEnum.Percentage:
        const quotient = currentValue / maxValue;
        const percentage = !isFinite(quotient) ? 0 : DcMathUtils.round((quotient) * 100);

        if (displayDecimalNumbers) {
          value = `${percentage.toFixed(FRACTION_DIGITS)}%`;
        } else {
          value = `${Math.sign(percentage) * Math.round(Math.abs(percentage))}%`;
        }

        break;
      case DcStatusIndicatorEnum.Money:
        const moneyValue = showDifference ? maxValue - currentValue : currentValue;
        value = this.currencyPipe.transform(moneyValue);
        break;
      default:
        const numberValue = showDifference ? maxValue - currentValue : currentValue;
        const numberValueWithDecimal = numberValue.toFixed(FRACTION_DIGITS);
        value = displayDecimalNumbers ? numberValueWithDecimal : Math.floor(numberValue);
        break;
    }

    const indicatorLabel = this.getFormattedStatusIndicatorLabel(field.indicatorLabel, data.values, field);
    return {
      displayOrientation: field.displayOrientation,
      currentValue: currentValue,
      maxValue: maxValue,
      subField: `${value} ${indicatorLabel}`
    };
  }

  private transformValue(valueType: string, value: any) {
    switch (valueType) {
      case DcStatusIndicatorEnum.Money:
        return this.currencyPipe.transform(value);
      case DcStatusIndicatorEnum.Percentage:
        return `${value}%`;
      default:
        return value;
    }
  }

  private getFormattedStatusIndicatorLabel(labelToFormat: string, data: any, field: any) {
    const labelPlaceholderRegExp = /\{([^}]+)\}/g;
    const valuePlaceholderRegExp = /([^{|}\s*]+)/g;

    let newLabel = labelToFormat;
    labelToFormat.match(labelPlaceholderRegExp).forEach((labelToReplace: string) => {
      const keyToTransform = labelToReplace.match(valuePlaceholderRegExp)[0];
      const valueType = labelToReplace.match(valuePlaceholderRegExp)[1];

      let extractedFieldValue: any;
      let _valueType = valueType;

      if ([...Object.keys(DcStatusIndicatorEnum)].includes(valueType)) {
        extractedFieldValue = data[keyToTransform];
      } else if (valueType === 'Callback') {
        extractedFieldValue = data[field.currentValueCallback];
        _valueType = labelToReplace.match(valuePlaceholderRegExp)[2];
      } else {
        throw new Error('STATUS INDICATOR: Only Number, Percentage, Money, and Callback are allowed for the label.');
      }

      const transformedLabel = this.transformValue(_valueType, extractedFieldValue);
      newLabel = newLabel.replace(labelToReplace, transformedLabel);
    });
    return newLabel;
  }

  private getTableValues(data: any, field: any, additionalTableDetails: any) {
    const dataFromSource = data.values[field.dataSource];
    const headers = additionalTableDetails.map(tableDetails => {
      return {
        value: tableDetails.header,
        alignment: tableDetails.alignment
      };
    });

    const rows = dataFromSource.map(dataSource => {
      return additionalTableDetails.map(tableDetails => {
        return {
          header: tableDetails.header,
          value: dataSource[tableDetails.dataSource],
          alignment: tableDetails.alignment,
          format: tableDetails.format
        };
      });
    });

    if (additionalTableDetails['includeTotals']) {
      const totals = additionalTableDetails.map(tableDetails => {
        return {
          header: tableDetails.header,
          value: data.values['totals'][tableDetails.header],
          alignment: tableDetails.alignment,
          format: tableDetails.format
        };
      });
      return { headers, rows, totals };
    }

    return { headers, rows };
  }

  private formatSectionHeader(header: any, displayOnNextPage?: boolean) {
    if (displayOnNextPage) {
      header[0]['pageBreak'] = 'before';
    }

    return header;
  }

  private formatSectionContent(contentDetails: any[]) {
    return contentDetails.map(detail => {
      const formattedSectionHeader = this.formatSectionHeader(PdfConverterUtils.generateHeader(detail.header), detail.displayOnNextPage);
      const spacer = htmlToPdfMake('&#8192; </br>');
      switch (detail.contentType) {
        case DcSectionTypeEnum.Note:
          return [PdfConverterUtils.generateSection([
            formattedSectionHeader,
            detail.content,
            spacer], detail.displayOnNextPage)];
        case DcSectionTypeEnum.Table:
          const headers = detail.content['headers'];
          const rows = detail.content['rows'];
          const widths = headers.map(() => '*');

          if (rows.length === 0) {
            rows.push([{
              colSpan: headers.length,
              text: PdfConverterUtils.generateText('No records found')
            }]);
          }

          const tableObject = {
            table: {
              headerRows: 1,
              widths: widths,
              body: [
                headers,
                ...rows
              ]
            },
            layout: 'lightHorizontalLines'
          };
          return [PdfConverterUtils.generateSection([
            formattedSectionHeader,
            tableObject,
            spacer], detail.displayOnNextPage)];
        default:
          return [PdfConverterUtils.generateSection([
            formattedSectionHeader,
            PdfConverterUtils.generateTable(detail.content, [280, '*'], false, true),
            spacer], detail.displayOnNextPage)];
      }
    });
  }

  private convertContentToPdfConfig(sections: { name: string, type: string, contents: any[], displayOnNextPage?: boolean, flags?: any }[]) {
    const pdfContents = sections.map(section => {
      let content: any[];

      switch (section.type) {
        case DcSectionTypeEnum.Note:
          content = section.contents;
          break;
        case DcSectionTypeEnum.Status:
          const sc = section.contents;
          if (sc[0].displayOrientation === 'FILL') {
            content = [[PdfConverterUtils
              .generateTable([[sc[0]['statusIndicatorLabel'], [sc[0]['statusIndicatorProgress'], sc[0]['statusIndicatorSubLabel']]]],
                [100, 'auto'], false, true)]];
          } else {
            const indicator1 = PdfConverterUtils
              .generateTable([[sc[0]['statusIndicatorLabel'], [sc[0]['statusIndicatorProgress'], sc[0]['statusIndicatorSubLabel']]]],
                [100, 'auto'], false, true);
            const indicator2 = PdfConverterUtils
              .generateTable([[sc[1]['statusIndicatorLabel'], [sc[1]['statusIndicatorProgress'], sc[1]['statusIndicatorSubLabel']]]],
                ['auto', 'auto'], false, true);
            content = [[indicator1, indicator2]];
          }
          break;
        case DcSectionTypeEnum.Table:
          content = section.contents[0];
          break;
        default:
          // widthConfig represents the width of each column as it pertains to the pdf table
          let sectionContents, widthConfig;
          if (section.flags && section.flags.singleColumnInPdf) {
            widthConfig = [167, 333];
            sectionContents = [section.contents.reduce((previous, current) => [...previous, ...current], [])];
          } else {
            widthConfig = ['*', '*'];
            sectionContents = section.contents;
          }
          const columnContents = sectionContents.map(sectionContent =>
            PdfConverterUtils.generateTable(sectionContent, widthConfig, false, true));
          content = [columnContents];
          break;
      }
      return {
        header: section.name,
        content,
        contentType: section.type,
        displayOnNextPage: section.displayOnNextPage
      };
    });

    return [this.formatSectionContent(pdfContents)];
  }
}
