import { AttributeEnum } from '@app/core/ccp/enums/attribute.enum';
import { IUserAttribute } from '@app/core/ccp/interfaces/user-attribute.interface';

export class UserAttributes {
  protected userAttributes: IUserAttribute[] = [];

  constructor(_userAttributes: IUserAttribute[]) {
    this.userAttributes = _userAttributes;
  }

  /**
   * Fetches all the attributes {@link see AttributeEnum} regardless
   * of type i.e. CCP, RiskTechData
   *
   * @returns
   */
  getAvailableAttributes(): AttributeEnum[] {
    return this.userAttributes.map(userAttribute => userAttribute.attribute);
  }

  /**
   * Fetches the attribute value based on the provided AttributeEnum {@link see AttributeEnum}
   *
   * @param attribute
   * @returns
   */
  getValuesByAttribute(attribute: AttributeEnum): any {
    return this.userAttributes?.find(val => val.attribute === attribute)?.attributeValue;
  }

  /**
   * Checks whether the provided user attribute exists in the user attributes
   *
   * @param value
   * @returns
   */
  doesUserAttributeExist(userAttributeToCheck: IUserAttribute): boolean {
    return this.userAttributes?.find(userAttribute => {
      // Go ahead with the deep checking only when the attributes of both attributes are matching
      if (userAttribute.attribute === userAttributeToCheck.attribute) {
        const userAttrVal = userAttribute.attributeValue;
        const userAttrValToCheck = userAttributeToCheck.attributeValue;
        return this.doesAttributeValueExist(userAttrValToCheck, userAttrVal);
      }
    }) !== undefined;
  }

  /**
   * Checks whether the provided value exists in the user attributes
   *
   * @param attributeValue
   * @param valueToCheck
   * @returns
   */
  doesAttributeValueExist(valueToCheck: any, attributeValue: any[]) {
    return attributeValue.find((attrVal: any) => {
      if (typeof attrVal === 'object') {
        return Object.keys(attrVal).every(key => attrVal[key] === valueToCheck[key]);
      } else if (Array.isArray(attrVal) && Array.isArray(valueToCheck)) {
        return attrVal.some(val => valueToCheck.includes(val));
      }
      return attrVal === valueToCheck;
    }) !== undefined;
  }

  /**
   * Checks if user has the attribute in their user attributes based on the provided
   * AttributeEnum {@link see AttributeEnum}
   *
   * @param attribute e.g. Client, ClientProgram
   * @returns
   */
  hasAttribute(attribute: AttributeEnum): boolean {
    return this.userAttributes.find(userAttribute => userAttribute.attribute === attribute) !== undefined;
  }
}
