
import {
  ClaimSearchQuery
} from '@app/core/ccp/base-classes/claim-search-query';
import {
  SearchQueryField,
  SearchQueryOperator,
} from 'src/component-library';


const AdvancedSearchConfig = require('@assets/configs/advanced-search-config.json');

export class DynamicFormQueryConverter {
  fields: any;

  constructor() {
    this.fields = { };
  }

  convertQuery(query: ClaimSearchQuery) {
    return this
      .convertClaimNumber(query.fields.find(field => field.name === 'Claim Number'))
      .convertClaimantFirstName(query.fields.find(field => field.name === 'Claimant First Name'))
      .convertClaimantLastName(query.fields.find(field => field.name === 'Claimant Last Name'))
      .convertEmployeeNumber(query.fields.find(field => field.name === 'Employee Number'))
      .convertLossDate(query.fields.find(field => field.name === 'Loss Date'))
      .convertClaimStatus(query.fields.find(field => field.name === 'Claim Status'))
      .convertLocationCode(query.fields.find(field => field.name === 'Location Code'))
      .convertClaimType(query.fields.find(field => field.name === 'Claim Type'))
      .convertJurisdiction(query.fields.find(field => field.name === 'Jurisdiction'))
      .convertInsuredDriverFirstName(query.fields.find(field => field.name === 'Insured Driver First Name'))
      .convertInsuredDriverLastName(query.fields.find(field => field.name === 'Insured Driver Last Name'))
      .convertProgramNumber(query.fields.find(field => field.name === 'Program Number'))
      .convertProgramName(query.fields.find(field => field.name === 'Program Name'))
      .convertOccurrenceNumber(query.fields.find(field => field.name === 'Occurrence Number'))
      .convertInsuredName(query.fields.find(field => field.name === 'Insured Name'))
      .convertCoverage(query.fields.find(field => field.name === 'Coverage'))
      .convertAdjusterFirstName(query.fields.find(field => field.name === 'Adjuster First Name'))
      .convertAdjusterLastName(query.fields.find(field => field.name === 'Adjuster Last Name'))
      .convertClaimantSocialSecurity(query.fields.find(field => field.name === 'Claimant SSN/Tax Id'))
      .convertVin(query.fields.find(field => field.name === 'VIN'))
      .convertTotalPaid(query.fields.find(field => field.name === 'Total Paid'))
      .convertTotalOutstanding(query.fields.find(field => field.name === 'Total Outstanding'))
      .convertTotalIncurred(query.fields.find(field => field.name === 'Total Incurred'))
      .convertFederalStateClaimNumber(query.fields.find(field => field.name === 'Federal/State Claim Number'))
      .convertPolicyNumber(query.fields.find(field => field.name === 'Policy Number'))
      .convertDateReported(query.fields.find(field => field.name === 'Date Reported'))
      .convertDateClosed(query.fields.find(field => field.name === 'Date Closed'))
      .convertClientClaimNumber(query.fields.find(field => field.name === AdvancedSearchConfig.CLIENT_CLAIM_NUMBER))
      .fields;
  }

  private convertClaimId(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertClaimNumber(field: SearchQueryField)  {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertClaimantFirstName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertClaimantLastName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertEmployeeNumber(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertLossDate(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    const operator = field.operator;

    if (operator === SearchQueryOperator.Range) {
      value = {
        startDate: new Date(field.values[0]),
        endDate: new Date(field.values[1])
      };
    } else {
      value = {
        startDate: new Date(field.values[0])
      };
    }

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertDateReported(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    const operator = field.operator;

    if (operator === SearchQueryOperator.Range) {
      value = {
        startDate: new Date(field.values[0]),
        endDate: new Date(field.values[1])
      };
    } else {
      value = {
        startDate: new Date(field.values[0])
      };
    }

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertDateClosed(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    const operator = field.operator;

    if (operator === SearchQueryOperator.Range) {
      value = {
        startDate: new Date(field.values[0]),
        endDate: new Date(field.values[1])
      };
    } else {
      value = {
        startDate: new Date(field.values[0])
      };
    }

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertClaimStatus(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    const value = Object.assign({ }, ...field.values.map(entry =>
      ({ [entry]: true })
    ));
    const operator = SearchQueryOperator.Equal;

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertLocationCode(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertClaimType(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertJurisdiction(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertInsuredDriverFirstName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    let operator;

    const nested = field.values
      .filter(entry => !!entry.name); // Name will be null for "hidden" sub-fields

    value = nested[0].values[0]; // Use the first value (will only be one)
    operator = nested[0].operator;

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertInsuredDriverLastName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    let operator;

    const nested = field.values
      .filter(entry => !!entry.name); // Name will be null for "hidden" sub-fields

    value = nested[0].values[0]; // Use the first value (will only be one)
    operator = nested[0].operator;

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertProgramNumber(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertProgramName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertOccurrenceNumber(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertInsuredName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertCoverage(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    const value = Object.assign({ }, ...field.values.map(entry =>
      ({ [entry]: true })
    ));
    const operator = SearchQueryOperator.Equal;

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertAdjusterFirstName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertAdjusterLastName(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertClaimantSocialSecurity(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    const value = field.values[0];
    let operator = field.operator;

    if (operator === SearchQueryOperator.Includes) {
      operator = SearchQueryOperator.Equal;
    }

    if (operator === SearchQueryOperator.Excludes) {
      operator = SearchQueryOperator.NotEqual;
    }

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertVin(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertTotalPaid(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    const operator = field.operator;

    if (operator === SearchQueryOperator.Range) {
      value = {
        firstCurrency: field.values[0],
        secondCurrency: field.values[1]
      };
    } else {
      value = {
        firstCurrency: field.values[0]
      };
    }

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertTotalOutstanding(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    const operator = field.operator;

    if (operator === SearchQueryOperator.Range) {
      value = {
        firstCurrency: field.values[0],
        secondCurrency: field.values[1]
      };
    } else {
      value = {
        firstCurrency: field.values[0]
      };
    }

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertTotalIncurred(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    let value;
    const operator = field.operator;

    if (operator === SearchQueryOperator.Range) {
      value = {
        firstCurrency: field.values[0],
        secondCurrency: field.values[1]
      };
    } else {
      value = {
        firstCurrency: field.values[0]
      };
    }

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertFederalStateClaimNumber(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertPolicyNumber(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }

  private convertToSimpleFormControl(field: SearchQueryField) {
    const value = field.values[0]; // Use the first value (will only be one)
    const operator = field.operator;

    Object.assign(this.fields, {
      [field.name]: {
        field: value,
        comparison: operator
      }
    });

    return this;
  }

  private convertClientClaimNumber(field: SearchQueryField) {
    if (!field) {
      return this;
    }

    return this.convertToSimpleFormControl(field);
  }
}
