import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function isValidDateRange(control: UntypedFormGroup): {[key: string]: any} | null {
  if (!control.get('startDate') || !control.get('endDate')) {
    return;
  }

  if (!control.get('startDate').value || !control.get('endDate').value) {
    return;
  }

  const startDate: Date = control.get('startDate').value;
  const endDate: Date = control.get('endDate').value;
  const startDateGreaterThanEndDate = { startDateGreaterThanEndDate: true };

  if (new Date(startDate).isValid() && new Date(endDate).isValid() && new Date(startDate).isAfter(endDate)) {
    return startDateGreaterThanEndDate;
  }

  return null;
}

export function containsTwoDates(control: UntypedFormGroup): {[key: string]: any} | null {
  if (!control.get('startDate').value && !control.get('endDate').value) {
    return null;
  }

  // START: INVALID
  // These two error keys are not supposed to display any error message, but are
  // important to invalidate the form, thus disabling the SUBMIT button.
  if (!control.get('startDate').touched && !control.get('startDate').value && control.get('endDate').value) {
    return { invalid: true };
  }

  if (!control.get('endDate').touched && !control.get('endDate').value && control.get('startDate').value) {
    return { invalid: true };
  }
  // END: INVALID

  if (!control.get('startDate').value && control.get('startDate').touched) {
    return { missingStartDate: true };
  }

  if (!control.get('endDate').value && control.get('endDate').touched) {
    return { missingEndDate: true };
  }

  return null;
}

export function ssnFieldValidator(claimantField: AbstractControl): ValidationErrors  {
  const fieldValue = claimantField.value;
  const operator = claimantField.parent.get('comparison').value;

  if (operator === 'Wildcard') {
    const validator = ((/^(\d){4}$/.test(fieldValue)) || !fieldValue);
    return validator ? null : { last4Pattern: true };
  } else if (operator === 'Equal') {
    const validator = ((/^(\d){9}$/.test(fieldValue)) || !fieldValue);
    return validator ? null : { exactPattern: true };
  } else {
    return null;
  }
}
