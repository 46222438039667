import { Component, OnInit } from '@angular/core';
import { FaviconService } from '../ccp/services/favicon/favicon.service';

@Component({
  selector: 'app-layout',
  styles: [':host .mat-drawer-content {padding: 0;} .mat-drawer-container {z-index: 1000}'],
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {
  constructor(private faviconService: FaviconService) { }

  ngOnInit() {
    this.setFavicon('Default');
  }

  setFavicon(favicon: string) {
    this.faviconService.setFavicon(favicon);
  }
}
