import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';

import { CdsModalRef } from '@cds/angular';
import { TimerService } from '../../services/timer/timer.service';

@Component({
  selector: 'app-user-inactivity-warning',
  templateUrl: './user-inactivity-warning.component.html'
})
export class UserInactivityWarningComponent implements OnInit, OnDestroy {
  timerSubscription: Subscription;
  humanizedCount: string;
  duration = 120;

  logout = new EventEmitter<boolean>();

  // TODO: Best practice - order must be no access modifiers, protected, public, private
  constructor(private timer: TimerService, public modalRef: CdsModalRef) { }

  ngOnInit() {
    parent.postMessage('hide', '*');
    this.startTimer();
  }

  close() {
    parent.postMessage('show', '*');
    this.modalRef.close();
  }

  startTimer() {
    this.timerSubscription = this.timer.countSeconds(this.duration)
      .subscribe({
        next: (count) => {
          const seconds = ((this.duration - count) % 60);
          const minutes = Math.floor((this.duration - count) / 60);
          this.humanizedCount = `${this.pad(minutes)}:${this.pad(seconds)}`;
        },
        complete: () => {
          this.logout.emit();
        }
      });
  }

  pad(digit: any) {
    return digit = digit <= 9 ? '0' + digit   : digit;
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
