import { Routes } from '@angular/router';

import {
  AuthLayoutComponent,
  AdminLayoutComponent,
  canActivateFunction
} from 'src/app/core';
import { QuickClaimComponent } from './core/quick-claim/quick-claim.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [canActivateFunction],
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
      }, {
        path: 'claims',
        canActivate: [canActivateFunction],
        loadChildren: () => import('./features/claims/claims.module').then(m => m.ClaimsModule)
      }, {
        path: 'claims/:source/:tpa',
        canActivate: [canActivateFunction],
        loadChildren: () => import('./features/claims/claims.module').then(m => m.ClaimsModule)
      }, {
        path: 'claims/:source',
        canActivate: [canActivateFunction],
        loadChildren: () => import('./features/claims/claims.module').then(m => m.ClaimsModule)
      }, {
        path: 'search',
        canActivate: [canActivateFunction],
        loadChildren: () => import('./features/search/search.module').then(m => m.SearchModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  }, {
    path: 'registration',
    loadChildren: () => import('./features/registration/registration.module').then(m => m.RegistrationModule)
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'session',
        loadChildren: () => import('./features/session/session.module').then(m => m.SessionModule)
      }
    ]
  }, {
    path: 'claim/:claimID',
    canActivate: [canActivateFunction],
    component: QuickClaimComponent
  }, {
    path: '**',
    redirectTo: 'session/error'
  }
];
