import { InputType } from './input-definition';
import { SearchQueryOperator } from './abstract-search-query';
import { FormOperator } from './form-operator';

/**
 * @class FormControlDefinition(formControl: {@link FormControlDefinition}) Describes the layout of a page.
 *
 * @property name - a name that is displayed by the control label.
 * @property key - a unique key for the control within the form.
 * @property type - datatype of the control.
 * @property position - position of the control within the form.
 * @property pinned - flag that determines if the control can be hidden.
 * @property defaultOperator - specifies the default operator to be used by this control {@link SearchQueryOperator}. If unset,
 * the operators that may apply to this control are determined by the dynamic form component by using
 * the control's datatype.
 * @property showOperatorDropdown - allows or prevents modification of the operator applied to the control,
 * by displaying or hiding the operator dropdown. A 'false' value will hide the dropdown.
 */

export class FormControlDefinition {
  name: string;
  key: string;
  type: InputType;
  position?: number;        // ordered by importance/weight (for default), else user defined?
  pinned?: boolean;         // should this be true or false by default?
  operators?: FormOperator[];
  defaultOperator?: SearchQueryOperator;
  options?: any [];
  showOperatorDropdown?: boolean;
  autocomplete?: string;

  constructor(formControl: FormControlDefinition) {
    this.name = formControl.name;
    this.key = formControl.key;
    this.type = formControl.type;
    this.position = formControl.position;
    this.pinned = formControl.pinned;
    this.defaultOperator = formControl.defaultOperator;
    this.options = formControl.options;
    this.showOperatorDropdown = formControl.showOperatorDropdown;
    this.autocomplete = formControl.autocomplete;
  }
}
