import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {
  CdsLabelModule,
  CdsInputModule,
  CdsDirectivesModule,
  CdsSelectModule,
  CdsCheckboxModule,
  CdsDatepickerModule,
  CdsButtonModule,
  CdsLoadingSpinnerModule,
  CdsLoadingOverlayModule,
  CdsModalModule
} from '@cds/angular';
import { DcPipesModule } from '@dc/core';

import { AtomsModule } from '../atoms/atoms.module';
import { MoleculesModule } from '../molecules/molecules.module';
import { OrganismsModule } from '../organisms/organisms.module';

import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';

@NgModule({
  declarations: [
    DynamicFormComponent
  ],
  exports: [
    DynamicFormComponent
  ],
  imports: [
    CommonModule,
    AtomsModule,
    MoleculesModule,
    OrganismsModule,
    ReactiveFormsModule,
    CdsLabelModule,
    CdsInputModule,
    CdsSelectModule,
    CdsDatepickerModule,
    CdsCheckboxModule,
    CdsButtonModule,
    CdsLoadingSpinnerModule,
    CdsLoadingOverlayModule,
    CdsModalModule,
    CdsDirectivesModule,
    DcPipesModule
  ]
})
export class TemplatesModule {
}
