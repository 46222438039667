import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  theme: string;

  constructor(translate: TranslateService,
              private router: Router) {
    this.theme = 'default';

    translate.addLangs(['en-US']);
    translate.setDefaultLang('en-US');

    // translate.getBrowserLang() is defaulted to 'en.json' and therefore
    // we're switching to navigator.language instead to ensure that we're
    // getting the default language that we set.
    translate.use(navigator.language);
  }

  ngOnInit() {
    if (this.isInternetExplorer()) {
      this.router.navigateByUrl('/session/ie-not-supported', { replaceUrl: true });
    }
  }

  private isInternetExplorer() {
    return window.navigator.userAgent.toLowerCase().indexOf('trident') > -1;
  }
}
