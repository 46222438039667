export enum AttributeEnum {
  Carrier = "Carrier",
  Client = "Client",
  ClientProgram = "ClientProgram",
  ClientSpecific = "ClientSpecific",
  Functional = "Functional",
  Global = "Global",
  LineOfBusiness = "Line of Business",
  Location = "Location",
  State = "State",
  UserType = "UserType"
}
