import { DcLoadWhenEnum } from '@dc/core';

import { DataRequestUtils } from './data-request';
import { LineOfBusinessDescriptionEnum } from '../../../enums/line-of-business.enum';

export class DataResponse {
  dataResponse: [{
    key: string,
    error?: {
      statusCode: number;
      reasonPhrase: string;
    },
    path?: string,
    values?: any[],
    parameters: any
  }];
}

export class DataResponseUtils {
  static firstValue(results) {
    const data = {};
    for (const key of Object.keys(results)) {
      data[key] = results[key] ? results[key][0] : null;
    }

    return data;
  }

  static zipperMerge(results) {
    let size = Number.POSITIVE_INFINITY;
    for (const key of Object.keys(results)) {
      size = Math.min(size, results[key] ? results[key].length : 0);
    }

    const data = [];
    for (let i = 0; i < size; ++i) {
      const row = {};
      for (const key of Object.keys(results)) {
        row[key] = results[key][i];
      }

      data.push(row);
    }

    return data;
  }

  static aggregate(table, page, callback) {
    if (table.flags && table.flags.includeTotals) {
      callback(page);
    }
  }

  /**
   * This is to arrange the data with the new structure that we introduced where
   * data are in a single array, VALUES, and fields are grouped together making up
   * an object instead of the usual structure where each field contains an array of
   * data:
   * e.g. *new structure*
   * {
   *  FUNDS: [
   *    TOTAL: 2,
   *    VALUES: [
   *      {
   *        'TRANS_ID': 10000,
   *        'CONTROL_NUMBER': 54321,
   *        ...
   *      },
   *      {
   *        'TRANS_ID': 10001,
   *        'CONTROL_NUMBER': 54322,
   *        ...
   *      }
   *    ];
   *  ]
   * }
   *
   * We also included checking the new field flag, loadWhen {@link see FieldDefinition},
   * to ensure that we're not rearranging other tables that do not use the new data structure.
   *
   * @param results - an array of values containing related fields
   * @param layout - the component layout
   * @returns
   */
  static arrangeTable(results: Array<any>, layout, overrideTotal?: number) {
    const tableResults = {};

    layout.sections.map(section => {
      section.columns.map(column => {
        column.fields
          .flatMap(field => DataRequestUtils.getCombinedFields(field))
          .filter(field => field.dataSource).map(field => {
            if (field.type === 'Table' && field.loadWhen === DcLoadWhenEnum.IsCalled) {
              const table = field.tableDefinition;
              tableResults[field.dataSource] = results;
              if (overrideTotal && !tableResults[field.dataSource]['total']) {
                tableResults[field.dataSource]['total'] = overrideTotal;
              }
              this.aggregateTotals(table, tableResults[field.dataSource], results);
            }
          });
      });
    });

    return Object.assign(tableResults);
  }

  static arrange(results, layout, sourceSystem = "RT") {
    const fieldResults = {};
    const tableResults = {};
    const listResults = {};

    layout.sections.map((section) => {
      section.columns.map((column) => {
        column.fields
          .flatMap((field) => DataRequestUtils.getCombinedFields(field))
          .filter((field) => field.dataSource)
          .map((field) => {
            switch (field.type) {
              case 'Table':
                try {
                  const tableResult = {};
                  const table = field.tableDefinition;
                  const countEachColumn = [];

                  table.columns.forEach((tableColumn) => {
                    if (Array.isArray(results[tableColumn.dataSource])) {
                      countEachColumn.push(
                        results[tableColumn.dataSource]?.length
                      );
                    } else {
                      countEachColumn.push(1);
                    }
                  });

                  let highestNumber = 0;
                  const values =  countEachColumn.filter(
                    (num) => num != null && num !== undefined
                  );

                  if (values) {
                    highestNumber = Math.max(
                      ...values
                    );
                  }

                  table.columns.forEach((tableColumn) => {
                    let rowCount = 0;

                    if (results[tableColumn.dataSource]) {
                      if (Array.isArray(results[tableColumn.dataSource])) {
                        rowCount = results[tableColumn.dataSource]?.length;
                      } else {
                        rowCount = 1;
                      }
                    }

                    if (rowCount) {
                      if (Array.isArray(results[tableColumn.dataSource])) {
                        tableResult[tableColumn.dataSource] = results[tableColumn.dataSource];
                      } else {
                        tableResult[tableColumn.dataSource] = [results[tableColumn.dataSource]];
                      }
                    } else {
                      if (highestNumber > 0) {
                        tableResult[tableColumn.dataSource] = [
                          ...Array(highestNumber),
                        ].fill(null);
                      } else {
                        tableResult[tableColumn.dataSource] = [null];
                      }
                    }
                  });

                  let isNull = true;

                  table.columns.forEach((tableColumn) => {
                    const columnData = tableResult[tableColumn.dataSource];
                    if (
                      columnData &&
                      columnData[0] !== null &&
                      columnData[0] !== ''
                    ) {
                      isNull = false;
                    }
                  });

                  if (isNull) {
                    table.columns.forEach((tableColumn) => {
                      tableResult[tableColumn.dataSource] = [];
                    });
                  }

                  const data = DataResponseUtils.zipperMerge(tableResult);
                  tableResults[field.dataSource] = data;
                  this.aggregateTotals(
                    table,
                    tableResults[field.dataSource],
                    results
                  );
                } catch (error) {
                  console.warn(error);
                }
                break;
              case ('Concat'):
              case ('TextList'):
                listResults[field.dataSource] = results[field.dataSource];
                break;
              case ('ButtonWithLoading'):
                break;
              default:
                const fieldValue = results[field.dataSource];
                if (fieldValue && field.displayAs === 'csv') {
                  fieldResults[field.dataSource] = [fieldValue.join(', ')];
                } else {
                  fieldResults[field.dataSource] = fieldValue;
                }
            }
          });
      });
    });

    return Object.assign(
      DataResponseUtils.firstValue(fieldResults),
      tableResults,
      listResults
    );
  }

  static removeCode(value: any) {
    return (value || '')
      .split(' ').splice(1).join(' ');
  }

  static mapConfirmationStatus(data: any, fields: string[]) {
    for (const field of fields) {
      const dataFieldValue = data[field];
      if (dataFieldValue !== undefined && dataFieldValue !== null) {
        if (typeof dataFieldValue !== 'number') {
          if (!Array.isArray(dataFieldValue)) {
            data[field] = this.mapBooleanToString(dataFieldValue);
          } else {
            data[field] = dataFieldValue.map((item: any) => {
              if (item) {
                return this.mapBooleanToString(item);
              }
              return item;
            });
          }
        } else {
          const booleanValues = [0, 1, 2, "0", "1", "2"];
          if (booleanValues.includes(data[field])) {
            data[field] = this.mapBooleanToString(data[field]);
          }
        }
      }
    }
    return data;
  }

  static mapBooleanToString(value, positive = 'Yes', negative = 'No', unknown = 'Unknown') {
    const positiveValues = [1, "1", "Yes", "Y"];
    const negativeValues = [0, "0","No", "N"];
    const unknownValues = [2, "2", "Unknown", "U"];

    if (positiveValues.includes(value)) {
      return positive;
    }

    if (negativeValues.includes(value)) {
      return negative;
    }

    if (unknownValues.includes(value)) {
      return unknown;
    }

    if (value === undefined || value === null) {
      return undefined;
    }

    return value;
  }

  static removeCodeFromData(data: any, fields: string[]): any {
    for (const field of fields) {
      const dataFieldValue = data[field];
      if (dataFieldValue !== undefined && dataFieldValue !== null) {
        if (typeof dataFieldValue !== 'number') {
          if (!Array.isArray(dataFieldValue)) {
            data[field] = this.removeCode(dataFieldValue);
          } else {
            data[field] = dataFieldValue.map((item: any) => {
              if (item) {
                return this.removeCode(item);
              }
              return item;
            });
          }
        } else {
          const booleanValues = [0, 1, "0", "1"];
          if (booleanValues.includes(data[field])) {
            data[field] = this.mapBooleanToString(data[field]);
          }
        }
      }
    }

    return data;
  }

  static removeCodeFromListData(list: any[], fields: string[]): any[] {
    if (list) {
      for (let i = 0; i < list.length; ++i) {
        list[i] = this.removeCodeFromData(list[i], fields);
      }
    }

    return list;
  }

  static removeCodeFromTableData(rows: any[], fields: string[]): any[] {
    if (rows) {
      for (let i = 0; i < rows.length; ++i) {
        rows[i] = this.removeCodeFromData(rows[i], fields);
      }
    }

    return rows;
  }

  static transformLOBAbbrevToDesc(data: any): any {
    data['LINE_OF_BUSINESS_DESCRIPTION'] = LineOfBusinessDescriptionEnum[data['LINE_OF_BUSINESS']];

    return data;
  }

  static transformDmitriLOBAbbrevToDesc(data: any): any {
    const dmLobCode = (data['LINE_OF_BUSINESS'] || '').split(' ')[0];
    data['LINE_OF_BUSINESS_DESCRIPTION'] = LineOfBusinessDescriptionEnum[dmLobCode];

    return data;
  }

  static formatBlanksConsistently(response: any[], fields: string[]) {
    return response.map((data: { [x: string]: string; }) => {
      for (const field of fields) {
        // Check for all possible blank values:
        // null, undefined, empty string, whitespace characters.
        const value = data[field];
        const whitespace = /^\s*$/;
        if ((value === null) || (value === undefined)
          || (value === '') || whitespace.test(value)) {
          // In order for them to have the same value, convert it to
          // \xa0 (equivalent to space)
          data[field] = '\xa0';
        }
      }

      return data;
    });
  }

  private static aggregateTotals(table, page, results) {
    this.aggregate(table, page, result => {
      result.totals = Object.assign({}, ...table.totals.map(entry => {
        let displayToFooter;
        const totalValue = results[entry.dataSource];

        if (!entry.value && !totalValue) {
          displayToFooter = ['No data'];
        } else {
          displayToFooter = entry.value ? [entry.value] : totalValue;
        }

        return DataResponseUtils.firstValue({ [entry.target]:  displayToFooter});
      }));
    });
  }

  static mapTableDataValues(data: any, targetKey: string | string[], newValue: string, tableName: string, callback: (newValue, targetValue) => {} = null) {
    let targetTable = data[tableName];
    let totals = null;
    if (targetTable && targetTable.hasOwnProperty('totals')) {
      totals = targetTable.totals;
    }

    let rows = targetTable.map((table) => {
      if (Array.isArray(targetKey)) {
        if (!callback) {
          targetKey.forEach((key) => {
            table[key] = newValue;
          });
        } else {
          targetKey.forEach((key) => {
            table[key] = callback(newValue, table[key]);
          });
        }
      } else {
        if (!callback) {
          table[targetKey] = newValue;
        } else {
          table[targetKey] = callback(newValue, table[targetKey]);
        }
      }

      return table;
    });

    targetTable = [
      ...rows
    ];

    if (totals) {
      targetTable['totals'] = totals;
    }

    data[tableName] = targetTable;
    return data;
  }
}
