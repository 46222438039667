import { Injectable } from '@angular/core';
import { DcDynamicComponentParent as MainDcDynamicComponentParent } from '@dc/components';
import { DcActionDefinition, IDcDynamicPanel, DcActionTypeEnum } from '@dc/core';
import { Observable, forkJoin, switchMap, take, map, of as observableOf } from 'rxjs';

/**
 * DISCLAIMER: This is an interim solution. As it stands, Claim Lookup web app isn't compatible with the
 * current DC library codebase since it is already using CDS v0.9.6. The initial attempt to upgrade CDS
 * in this feature branch resulted in errors, esp when opening a modal.
 *
 * In addition, the overridden method in this class essentially fixed the issue after upgrading the rxjs
 * version to 7.8.1. It is imperative to explore the latest DC and CDS versions, and adjust logic and
 * design-specific configurations to resolve the issues encountered.
 */
@Injectable()
export class DcDynamicComponentParent extends MainDcDynamicComponentParent {
  handleAction(action: DcActionDefinition, source?: any, component?: IDcDynamicPanel): Observable<any> {
    const actions = action.actions;
    const nestedActions = this.handleActions({ actions, source, component });

    return forkJoin(nestedActions).pipe(
      switchMap(results => {
        switch (action.type) {
          case DcActionTypeEnum.CallService: {
            const layout = component.layout;
            return this.callService(action.dataSource, layout, source)
              .pipe(take(1), map(result => !action.target ? result : { [action.target]: result }));
          }

          case DcActionTypeEnum.OpenDialog: {
            return this.openDialog(action.dataSource, source);
          }

          case DcActionTypeEnum.UpdateComponent: {
            results.forEach(result => this.updateComponent(result, component));
            return observableOf({});
          }

          case DcActionTypeEnum.CloseDialog: {
            setTimeout(() => this.closeDialog());
            return observableOf({});
          }

          default:
            return observableOf({});
        }
      })
    );
  }
};

export const DcDynamicComponentParentProvider = {
  provide: MainDcDynamicComponentParent,
  useClass: DcDynamicComponentParent
};
