import {
  Injectable, OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class HttpCancelService implements OnDestroy {
  private pendingRequests = new Subject<void>();

  constructor() { }

  public cancelPendingRequests() {
    this.pendingRequests.next();
  }

  public onCancelPendingRequests() {
    return this.pendingRequests.asObservable();
  }

  ngOnDestroy() {
    this.pendingRequests.complete();
  }
}
