import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { EventType } from "../../enums/event-type";

const DATE_TIME_FORMAT = `MM dd yyyy hh:mm:ss aaa z`;

export class History {
  aggregationType: string;
  eventTypeId: EventType;
  rowOrder: number;
  pageTitle: string;
  eventDate: string | Date;
  eventData: string;
  accessCount: number;
  daysSinceOldestAccess: number;
  claimData?: any;

  constructor(history: History) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedEventDate = new Date(history.eventDate).zonedTimeToUtc(timeZone);
    this.aggregationType = history.aggregationType;
    this.eventTypeId = history.eventTypeId;
    this.rowOrder = history.rowOrder;
    this.pageTitle = history.pageTitle;
    this.eventDate = zonedEventDate;
    this.eventData = history.eventData;
    this.accessCount = history.accessCount;
    this.daysSinceOldestAccess = history.daysSinceOldestAccess;
    if (history.claimData) {
      const zonedEventACCDate = new Date(
        history.claimData.EVENT_ACC_DATE
      ).zonedTimeToUtc(timeZone);
      history.claimData.EVENT_ACC_DATE = zonedEventACCDate;
      this.claimData = history.claimData;
    }
  }
}

@Injectable()
export class HistoryService {
  private cachedHistorySubject = new BehaviorSubject(new Array<History>());
  private cachedHistorySet = new BehaviorSubject<boolean>(false);

  static localizeHistoryEventDates(histories: History[]) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return histories.map((history) => {
      const localTime = new Date(history.eventDate).formatInTimeZone(
        timeZone,
        DATE_TIME_FORMAT
      );
      history.eventDate = localTime;
      if (history.claimData) {
        const localTimeAccDate = new Date(
          history.claimData.EVENT_ACC_DATE
        ).formatInTimeZone(timeZone, DATE_TIME_FORMAT);
        history.claimData.EVENT_ACC_DATE = localTimeAccDate;
      }
      return history;
    });
  }

  onCachedHistorySet() {
    return this.cachedHistorySet.asObservable();
  }

  getCachedHistory(
    aggregationType: string,
    eventTypeId: EventType,
    count: number
  ): Observable<History[]> {
    return this.cachedHistorySubject.pipe(
      map((historys) => {
        return historys
          .filter(
            (history, num = 0) =>
              history.aggregationType === aggregationType &&
              history.eventTypeId === eventTypeId
          )
          .slice(0, count);
      })
    );
  }

  setCachedHistory(history: History[]) {
    this.cachedHistorySet.next(true);
    this.cachedHistorySubject.next(history.map((h) => new History(h)));
  }
}
