export enum EventType {
  Error = 1,
  Warning = 2,
  Information = 3,
  Claim_View = 4,
  Claim_Search = 5,
  Dashboard_View = 6,
  Successful_Login = 7,
  Login_Failure = 8,
  Search_Export = 9
}
