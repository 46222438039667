import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorage {
  get(key: string): string {
    const found = this.getKeyValues()
      .find(cookie => key === cookie.key);
    return found ? found.value : undefined;
  }

  getJson(key: string): any {
    const found = this.getKeyValues()
      .find(cookie => key === cookie.key);
    return found ? JSON.parse(found.value || '{}') : undefined;
  }

  set(key: string, value: string) {
    this.encodeCookie(key, value);
  }

  setJson(key: string, value: any) {
    this.encodeCookie(key, JSON.stringify(value));
  }

  remove(key: string) {
    this.encodeCookie(key, '');
  }

  private getKeyValues() {
    return document.cookie.split(';')
      .map(cookie => this.decodeCookie(cookie));
  }

  private decodeCookie(cookie) {
    const fields = cookie.split('=');
    return {
      key: decodeURIComponent(fields[0].trim()),
      value: decodeURIComponent(fields[1].trim())
    };
  }

  private encodeCookie(key, value) {
    document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; domain=${document.domain}; path=/`;
  }
}
