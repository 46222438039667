import {
  Injectable
} from '@angular/core';
import {
  saveAs
} from 'file-saver';

const b64ToBlob = require('b64-to-blob');

@Injectable()
export class FileDownloaderService {
  saveBase64EncodedBlob(file: string, base64EncodedBlob: string) {
    saveAs(b64ToBlob(base64EncodedBlob), file);
  }
}
