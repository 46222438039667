import {
  switchMap,
  tap,
  filter,
  debounceTime,
  take,
} from 'rxjs/operators';
import {
  NgZone,
  OnInit,
  Component,
  ViewChild,
  OnDestroy
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import {
  AdvancedSearchComponent
} from './advanced-search/advanced-search.component';
import {
  History,
  HistoryService
} from '../../services/history/history.service';
import {
  ClaimSuggestion,
  SuggestionResult,
  SuggestionService,
  GroupedSuggestionItem
} from '../../services/suggestions/suggestion.service';
import { MessageBusService } from '../../services/message-bus/message-bus.service';
import { ClaimStatus, ClaimStatusService } from '../../services/claim-status.service';

import {
  HumanizedField,
  SearchQueryUtils
} from '../../utils/search-query-utils';
import {
  SearchQueryType
} from 'src/component-library/classes/abstract-search-query';
import { EventType } from '../../enums/event-type';
import { Subscription } from 'rxjs';
import { SearchbarHistoryResult } from './searchbar-history-result';
import { ClaimSearchQuery } from '../../base-classes/claim-search-query';
import { ClaimSearchService } from '../../services/http/claim-search/claim-search.service';
import { SessionService } from '../../services/session/session.service';
import { ClaimType, ClaimTypeService } from '../../services/claim-type.service';
import { ClaimTypeMappingEnum } from '../../enums/claim-type.enum';
import { CaseUtils } from '../../utils/case-utils';
import { FunctionalRolesEnum } from '../../enums/functional-roles.enum';
import { UserAttributeIdentifierEnum } from '../../enums/user-attribute-identifier.enum';
import { User } from '../../models/user/user';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-searchbar',
  styleUrls: ['./searchbar.component.scss'],
  templateUrl: './searchbar.component.html'
})
export class SearchbarComponent implements OnInit, OnDestroy {
  @ViewChild('searchbox', { static: true }) searchbox: any;
  @ViewChild('searchAutocomplete', { static: true }) searchAutocomplete: MatAutocomplete;

  mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);

  isOpen = false;
  formGroup: UntypedFormGroup;

  advancedSearchDialog: MatDialogRef<AdvancedSearchComponent>;

  loading = false;

  suggestions: SuggestionResult;
  history: SearchbarHistoryResult;
  subscriptions: Subscription[] = [];
  defaultSearchText = 'All Claims';

  private claimStatuses: ClaimStatus[] = [];
  private hasQuickReports: boolean;

  // TODO: Best practice - order must be no access modifiers, protected, public, private
  constructor(private router: Router,
              private formBuilder: UntypedFormBuilder,
              private historyService: HistoryService,
              private suggestionService: SuggestionService,
              private messageBusService: MessageBusService,
              private claimStatusService: ClaimStatusService,
              private claimSearchService: ClaimSearchService,
              private sessionService: SessionService,
              private claimTypeService: ClaimTypeService,
              public dialog: MatDialog,
              zone: NgZone) {
    this.history = new SearchbarHistoryResult(this.historyService);
    this.formGroup = this.formBuilder.group({
      Searchbox: this.formBuilder.control('', Validators.required)
    });

    this.mediaMatcher.addEventListener('change', listener => {
      zone.run(() => {
        this.mediaMatcher = listener as any;
        if (this.dialog.openDialogs.includes(this.advancedSearchDialog)) {
          this.advancedSearchDialog.updatePosition({
            top: '64px',
            right: this.mediaMatcher.matches ? '0' : '31.5%'
          });
        }
      });
    });

    this.getClaimType();
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  ngOnInit() {
    const formGroupSubscription = this.formGroup.get('Searchbox').valueChanges
      .pipe(
        debounceTime(200),
        filter(value => typeof value === 'string'),
        tap(_ => this.setSuggestions(true, null)))
      .pipe(
        switchMap(value => this.suggestionService.getMatchingSuggestions(value))
      )
      .subscribe(suggestionResult => {
        this.setSuggestions(false, suggestionResult);
      });

    this.subscriptions.push(formGroupSubscription);

    const advancedSearchOpenEventSubscription = this.messageBusService
      .consume<ClaimSearchQuery>('ADVANCED_SEARCH_DROPDOWN_OPEN').subscribe(withQuery => {
        this.openAdvancedSearchDropdown(withQuery);
      });
    this.subscriptions.push(advancedSearchOpenEventSubscription);

    const claimStatusesSubscription = this.claimStatusService.getClaimStatuses()
      .subscribe((claimStatuses: ClaimStatus[]) => {
        this.claimStatuses = claimStatuses;
      });
    this.subscriptions.push(claimStatusesSubscription);

    const getUserSubscription = this.sessionService.getUser().pipe(
      take(1))
      .subscribe((user: User) => {
        this.hasQuickReports = user.hasAccessToFunction(UserAttributeIdentifierEnum.CCP, FunctionalRolesEnum.QUICK_REPORTS) ? true : false;
      });
    this.subscriptions.push(getUserSubscription);

  }

  getSearchHistoryType(history: History): string {
    return JSON.parse(history.eventData).type;
  }

  getSearchHistoryText(history: History): string {
    return JSON.parse(history.eventData).value;
  }

  getHumanizedQuery(history: History): HumanizedField[] {
    const eventData = JSON.parse(history.eventData);
    for (const field of eventData['fields']) {
      if (field.name === 'Claim Status') {
        const values = (field.values as Array<any>).map((value: string) =>
          this.claimStatuses.find(status => value === status.SHORT_CODE).CODE_DESC
        );
        field.values = values;
      }
    }
    return SearchQueryUtils.humanizeQuery(eventData);
  }

  setSuggestions(loading, suggestions) {
    this.setLoading(loading);
    this.suggestions = suggestions;
  }

  openAdvancedSearchDropdown(withQuery?: ClaimSearchQuery) {
    // TODO: Handle resize properly...
    const position = {
      top: '56px',
      right: '156px'
    };
    let panelClass = 'cc-advanced-search-dialog';

    if (this.hasQuickReports) {
      panelClass = 'cc-advanced-search-dialog-quick-reports';
    }

    this.advancedSearchDialog = this.dialog.open(AdvancedSearchComponent, {
      width: '80%',
      maxWidth: '805px',
      position: position,
      panelClass: panelClass,
      backdropClass: 'cc-advanced-search-dialog-backdrop',
      data: {
        query: withQuery
      }
    });

    const subscription = this.advancedSearchDialog.componentInstance.submitClicked.subscribe(result => {
      if (result) {
        this.searchbox.nativeElement.value = '';
      }
    });

    this.subscriptions.push(subscription);
  }

  getClaimType() {
    const searchQuery = this.claimTypeService.getClaimTypeQuery();

    const claimTypeSubscription = this.claimSearchService.getSearchResults(searchQuery).subscribe(results => {
      const claimTypes: ClaimType[] = [];

      results.Search.aggregations.CLAIM_TYPES.buckets.forEach(type => {
        const label = (ClaimTypeMappingEnum as any)[type.key] || CaseUtils.TitleCase(type.key);
        if (type.key && claimTypes.findIndex((claimType) => claimType.LABEL === label) === -1) {
          claimTypes.push({ LABEL: label, KEY: label });
        }
      });
      this.claimTypeService.setClaimTypes(claimTypes.sort((a, b) => (a.LABEL.toLowerCase() > b.LABEL.toLowerCase()) ? 1 : -1));
    });

    this.subscriptions.push(claimTypeSubscription);
  }

  toggleAdvancedSearch() {
    if (!this.dialog.openDialogs.includes(this.advancedSearchDialog)) {
      this.openAdvancedSearchDropdown();
    } else {
      this.advancedSearchDialog.close();
    }
  }

  listClaimsBy(name: string) {
    return `List Claims by ${name}`;
  }

  onEnterPressed(searchbox: string) {
    if (searchbox) {
      if (!this.searchAutocomplete.isOpen) {
        this.onTextSuggestionSelected(searchbox);
      }
    }
  }

  onOptionSelected(searchbox: string, option) {
    if (option.selected) {
      if (typeof option.value === 'string') {
        this.onTextSuggestionSelected(searchbox);
      } else if (option.value instanceof History) {
        this.onHistorySelected(searchbox, option.value);
      } else if (option.value instanceof ClaimSuggestion) {
        this.onClaimSuggestionSelected(searchbox, option.value);
      } else if (option.value instanceof GroupedSuggestionItem) {
        this.onGroupedSuggestionSelected(searchbox, option.value);
      }

      this.searchbox.nativeElement.value = '';
      this.searchbox.nativeElement.blur();
    }
  }

  private onHistorySelected(searchbox: string, history: History) {
    if (history.eventTypeId === EventType.Claim_View) {
      let url = '';

      if (history.claimData.SOURCE_SYSTEM === 'DM') {
        let lineOfBusiness = history.claimData.LINE_OF_BUSINESS || 'UNK';
        url = `/claims/${history.claimData.SOURCE_SYSTEM}/${history.claimData.TPA_CODE}/${lineOfBusiness}/${history.claimData.CLAIM_NUMBER}`;
      } else {
        url = '/claims/' + history.claimData.LINE_OF_BUSINESS + '/' + history.claimData.CLAIM_NUMBER + '/' + history.claimData.CLAIM_ID;
      }

      this.router.navigateByUrl(url);
    }

    if (history.eventTypeId === EventType.Claim_Search) {
      const extras = {
        queryParams: {
          query: SearchQueryUtils.encodeSearchQuery(JSON.parse(history.eventData))
        }
      };
      const navigateURL = '/search/claims';
      this.router.navigateByUrl(this.router.createUrlTree([navigateURL], extras));
    }
  }

  private onTextSuggestionSelected(searchbox: string) {
    const trimmedSearchValue = searchbox.trim();
    const extras = {
      queryParams: {
        query: SearchQueryUtils.encodeSearchQuery({
          // Note:
          //  Only encode the type and value in the url,
          //  this keeps the query parameter length down.
          //  We build the actual query for Elasticsearch
          //  in the claim-results.component.ts.
          //                    - Brian Rowlett, 2019-11-19
          type: SearchQueryType.Tokenized,
          value: trimmedSearchValue
        })
      }
    };

    const navigateURL = '/search/claims';
    this.router.navigateByUrl(this.router.createUrlTree([navigateURL], extras));
  }

  private onClaimSuggestionSelected(searchbox: string, suggestion: ClaimSuggestion) {
    this.router.navigateByUrl(suggestion.navigateURL);
  }

  private onGroupedSuggestionSelected(searchbox: string, suggestion: GroupedSuggestionItem) {
    const extras = {
      queryParams: {
        query: SearchQueryUtils
          .encodeSearchQuery(suggestion.action.claimSearchQuery)
      }
    };

    const navigateURL = suggestion.action.navigateURL;
    this.router.navigateByUrl(this.router.createUrlTree([navigateURL], extras));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.history.cancelSubscriptions();
  }
}
