import { DcLoadWhenEnum } from '@dc/core';

export class DataRequest {
  parameters?: any;
  dataRequest: [{
    key: string,
    parameters: any
  }];
}

function flatten(array) {
  return [].concat.apply([], array);
}

export class DataRequestUtils {
  static getFieldsFromLayout(layout) {
    return flatten(layout.sections.map(section => {
      return flatten(section.columns.map(column => {
        const fields = column.fields
          .flatMap(field => this.getCombinedFields(field))
          .filter(field => {
            return field.dataSource
            && field.type !== 'Table'
            && !field.dataSource.startsWith('$');
          });

        return flatten(fields.map(field => {
          return field.dataSource;
        }));
      }));
    }));
  }

  static getCombinedFields(field) {
    if (!field.fields) {
      return field;
    }

    let fields = field.fields;

    field.fields.filter(subField => subField.fields)
      .forEach(subField => {
        fields = fields.concat(this.getCombinedFields(subField));
      });

    return fields;
  }

  static findTable(layout, name, callback) {
    DataRequestUtils.forEachTable(layout, field => {
      if (field.name === name) {
        callback(field);
      }
    });
  }

  static findList(layout, name, callback) {
    DataRequestUtils.forEachList(layout, field => {
      if (field.name === name) {
        callback(field);
      }
    });
  }

  static forEachTable(layout, callback) {
    layout.sections.forEach(section => {
      section.columns.forEach(column => {
        const fields = column.fields.filter(field => {
          return field.type === 'Table' && field.loadWhen !== DcLoadWhenEnum.IsCalled;
        });

        fields.forEach(field => {
          callback(field);
        });
      });
    });
  }

  static forEachList(layout, callback) {
    layout.sections.forEach(section => {
      section.columns.forEach(column => {
        const fields = column.fields.filter(field => {
          return field.type === 'List';
        });

        fields.forEach(field => {
          callback(field);
        });
      });
    });
  }

  static getFieldsFromTable(field) {
    const table = field.tableDefinition;
    let columns = flatten(table.columns.map(column => {
      return column.dataSource;
    }));

    let totals;
    if (table.totals) {
      totals = table.totals
        .flatMap(row => this.getCombinedFields(row))
        .filter(cell => cell.dataSource !== undefined);

      totals = flatten(totals.map(cell => {
        return cell.dataSource;
      }));

      columns = columns.concat(totals);
    }

    return columns;
  }

  static getFieldsFromList(field) {
    // return flatten(this.getCombinedFields(field).map(subfield => {
    return flatten(field.fields.map(column => {
      return column.dataSource;
    }));
  }
}
