
import {takeUntil} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';


import { HttpCancelService } from '../services/http-cancel/http-cancel.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private httpCancelService: HttpCancelService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('cancellable')) {
      const headers = { };
      request.headers.keys().map(key => {
        if (key !== 'cancellable') {
          headers[key] = request.headers.get(key);
        }
      });
      request = request.clone({
        headers: new HttpHeaders(headers)
      });
      return next.handle(request).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    } else {
      request = request.clone();
      return next.handle(request);
    }
  }
}
