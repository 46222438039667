import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SessionService as AuthSessionService } from '@auth/auth';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private route: ActivatedRoute, private authSession: AuthSessionService, @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authSession.idTokenClaims$.pipe(
      map((token: any) => {
        return request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token ? token['__raw'] : 'none'}`,
            'client_id': this.environment.appConfig.var1,
            'client_secret': this.environment.appConfig.var2
          }
        });
      }),
      switchMap((request) => {
        return next.handle(request)
          .pipe(
            map((req) => {
            // We should not rely on the error thrown while intercepting requests.
            // As it stands, when a user logs out, Auth0 will throw an HTTP 401 error,
            // which isn't necessary since the user opted to log out. We should handle
            // the rerouting by subscribing to the URL parameters that Auth0 provides
            // where they include error property
              this.route.queryParams.subscribe(queryParam => {
                if (queryParam['error'] && queryParam['error'] === 'unauthorized') {
                  this.router.navigateByUrl('/session/general-error/401');
                }
              });
              return req;
            }),
            catchError(error => {
              if (error instanceof HttpErrorResponse) {
                console.error('==', error); // Keeping this so we can see the error in the console

                // This is to handle HTTP error codes except statuses 401 and 0 to redirect the
                // user to generic error page.
                if (error.status !== 401 && error.status !== 0) {
                  this.router.navigateByUrl('/session/error');
                }
                return throwError(error);
              }
            }));
      }));
  }
}
