import { DcFieldTypeEnum } from '@dc/core';

import {
  ClaimSearchQuery
} from '@app/core/ccp/base-classes/claim-search-query';
import {
  SearchQueryType,
  SearchQueryField,
  SearchQueryOperator
} from 'src/component-library';
import {
  BenefitStatusCodeEnum,
  BenefitStatusOptionEnum,
  ReleaseStatusCodeEnum,
  ReleaseStatusOptionEnum,
  WorkStatusCodeEnum,
  WorkStatusOptionEnum
} from '../enums/return-to-work.enum';

export class AdvancedQueryBuilder {
  query: ClaimSearchQuery;

  constructor() {
    this.query = new ClaimSearchQuery(SearchQueryType.Advanced);
  }

  buildQuery() {
    return this.query;
  }

  addClaimId(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'CLAIM_ID',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addClaimIds(name: string, values: any[], operator: SearchQueryOperator) {
    if (values && values.length) {
      if (operator === SearchQueryOperator.Equal) {
        operator = SearchQueryOperator.Includes;
      } else if (operator === SearchQueryOperator.NotEqual) {
        operator = SearchQueryOperator.Excludes;
      }

      const field = new SearchQueryField({
        key: 'CLAIM_ID',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: values,
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addClaimNumber(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'CLAIM_NUMBER.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addClaimantFirstName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'CLAIMANT_FIRST_NAME.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addClaimantLastName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'CLAIMANT_LAST_NAME.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addEmployeeNumber(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'EMPLOYEE_NUMBER.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addLossDate(name: string, value: any, operator: SearchQueryOperator) {
    /*value = {
      startDate: '01/01/2010',
      endDate: '12/31/2010'
    };*/

    if (value) {
      const values = [];
      if (value.startDate) { values.push(value.startDate); }
      if (value.endDate) { values.push(value.endDate); }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'DATE_OF_EVENT',
          name: name,
          type: DcFieldTypeEnum.Date,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addDateReported(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value.startDate) { values.push(value.startDate); }
      if (value.endDate) { values.push(value.endDate); }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'DATE_REPORTED',
          name: name,
          type: DcFieldTypeEnum.Date,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addDateClosed(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value.startDate) { values.push(value.startDate); }
      if (value.endDate) { values.push(value.endDate); }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'DATE_CLOSED',
          name: name,
          type: DcFieldTypeEnum.Date,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addDateOfClaim(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value.startDate) { values.push(value.startDate); }
      if (value.endDate) { values.push(value.endDate); }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'DATE_OF_CLAIM',
          name: name,
          type: DcFieldTypeEnum.Date,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addClaimStatus(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value.O) { values.push('O'); }
      if (value.C) { values.push('C'); }
      if (value.R) { values.push('R'); }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'CLAIM_STATUS.CODE_ABBREV.keyword',
          name: name,
          type: DcFieldTypeEnum.Text,
          values: values,
          operator: SearchQueryOperator.Includes
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addLocationCode(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'LOCATION_CODE.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addClaimType(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'CLAIM_TYPE.CODE_DESCRIPTION.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addJurisdiction(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'JURISDICTION.STATE_NAME.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addInsuredDriverFirstName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [
        new SearchQueryField({
          key: 'PERSON_INVOLVED.PI_TYPE.CODE_ID',
          type: DcFieldTypeEnum.Text,
          values: [240],
          operator: SearchQueryOperator.Equal
        }),
        new SearchQueryField({
          key: 'PERSON_INVOLVED.PI_ENTITY_TABLE_ID',
          type: DcFieldTypeEnum.Text,
          values: [2106],
          operator: SearchQueryOperator.Equal
        }),
        new SearchQueryField({
          key: 'PERSON_INVOLVED.PI_FIRST_NAME.keyword',
          name: name,
          type: DcFieldTypeEnum.Text,
          values: [value],
          operator: operator
        })
      ];

      const field = new SearchQueryField({
        key: 'PERSON_INVOLVED',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: values,
        operator: SearchQueryOperator.Nested
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addInsuredDriverLastName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [
        new SearchQueryField({
          key: 'PERSON_INVOLVED.PI_TYPE.CODE_ID',
          type: DcFieldTypeEnum.Text,
          values: [240],
          operator: SearchQueryOperator.Equal
        }),
        new SearchQueryField({
          key: 'PERSON_INVOLVED.PI_ENTITY_TABLE_ID',
          type: DcFieldTypeEnum.Text,
          values: [2106],
          operator: SearchQueryOperator.Equal
        }),
        new SearchQueryField({
          key: 'PERSON_INVOLVED.PI_LAST_NAME.keyword',
          name: name,
          type: DcFieldTypeEnum.Text,
          values: [value],
          operator: operator
        })
      ];

      const field = new SearchQueryField({
        key: 'PERSON_INVOLVED',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: values,
        operator: SearchQueryOperator.Nested
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addProgramNumber(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      if ((operator === SearchQueryOperator.Equal)
        || (operator === SearchQueryOperator.NotEqual)) {
        value = value.padStart(6, '0');
      }

      const field = new SearchQueryField({
        key: 'CLIENT_PROGRAM_NUMBER.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addProgramName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'CLIENT_PROGRAM_NAME.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addOccurrenceID(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'EVENT_ID',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }
    return this;
  }

  addOccurrenceNumber(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'EVENT_NUMBER.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }
    return this;
  }

  addInsuredName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'INSURED_NAME.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addCoverage(name: string, value: any, operator: SearchQueryOperator) {
    /*value = {
      WC: true,
      GL: false,
      AL: false
    };*/

    if (value) {
      const values = [];
      if (value.GL) { values.push('GL'); }
      if (value.AL) { values.push('AL'); }
      if (value.WC) { values.push('WC'); }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'LINE_OF_BUSINESS.CODE_ABBREV.keyword',
          name: name,
          type: DcFieldTypeEnum.Text,
          values: values,
          operator: SearchQueryOperator.Includes
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addAdjusterFirstName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'ADJUSTER_FIRST_NAME.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addAdjusterLastName(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'ADJUSTER_LAST_NAME.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addClaimantSocialSecurity(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = new Set();
      const modified = value.split('-').join('');

      values.add(modified);
      if (modified.length === 9) {
        if (value !== modified) {
          values.add(value);
        }

        const substr02 = modified.substr(0, 2);
        const substr27 = modified.substr(2, 7);
        values.add(`${substr02}-${substr27}`); // EIN

        const substr03 = modified.substr(0, 3);
        const substr32 = modified.substr(3, 2);
        const substr54 = modified.substr(5, 4);
        values.add(`${substr03}-${substr32}-${substr54}`); // TIN
      }

      if (operator === SearchQueryOperator.Equal) {
        operator = SearchQueryOperator.Includes;
      }
      if (operator === SearchQueryOperator.NotEqual) {
        operator = SearchQueryOperator.Excludes;
      }

      const field = new SearchQueryField({
        key: 'CLAIMANT_TAX_ID.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: Array.from(values),
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addVin(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'VEHICLE.VEHICLE_VIN.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }

    return this;
  }

  addTotalPaid(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value.firstCurrency) {
        values.push(value.firstCurrency);
      }
      if (value.secondCurrency && operator === SearchQueryOperator.Range) {
        values.push(value.secondCurrency);
      }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'TOTAL_PAID',
          name: name,
          type: DcFieldTypeEnum.Money,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addTotalOutstanding(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value.firstCurrency) {
        values.push(value.firstCurrency);
      }
      if (value.secondCurrency && operator === SearchQueryOperator.Range) {
        values.push(value.secondCurrency);
      }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'TOTAL_OUTSTANDING',
          name: name,
          type: DcFieldTypeEnum.Money,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addTotalIncurred(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value.firstCurrency) {
        values.push(value.firstCurrency);
      }
      if (value.secondCurrency && operator === SearchQueryOperator.Range) {
        values.push(value.secondCurrency);
      }

      if (values && values.length) {
        const field = new SearchQueryField({
          key: 'TOTAL_INCURRED',
          name: name,
          type: DcFieldTypeEnum.Money,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addFederalStateClaimNumber(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'STATE_FEDERAL_NUMBER.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });
      this.query.fields.push(field);
    }
    return this;
  }

  addPolicyNumber(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'POLICY.POLICY_NUMBER.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });

      this.query.fields.push(field);
    }
    return this;
  }

  addClientClaimNumber(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'CLIENT_CLAIM_NUMBER.keyword',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });
      this.query.fields.push(field);
    }
    return this;
  }

  addPrivacyIndicator(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      let values = [];
      if (Array.isArray(value) && value.length > 0) {
        values = value;
      } else {
        values = [value];
      }
      const field = new SearchQueryField({
        key: 'PRIVACY_INDICATOR',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: values,
        operator: operator
      });
      this.query.fields.push(field);
    }
    return this;
  }

  addRtwCoordinated(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const field = new SearchQueryField({
        key: 'RTW_COORDINATED',
        name: name,
        type: DcFieldTypeEnum.Text,
        values: [value],
        operator: operator
      });
      this.query.fields.push(field);
    }
	  return this;
  }

  addReleaseStatus(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value[ReleaseStatusOptionEnum.ReleasedWithRestrictions]) { values.push(ReleaseStatusCodeEnum.WithRestrictions); }
      if (value[ReleaseStatusOptionEnum.ReleasedWithoutRestrictions]) { values.push(ReleaseStatusCodeEnum.WithoutRestrictions); }
      if (value[ReleaseStatusOptionEnum.NotReleasedFullyRestricted]) { values.push(ReleaseStatusCodeEnum.NotReleasedFullyRestricted); }
      if (value[ReleaseStatusOptionEnum.NoStatusFromDoctor]) {
        values.push(ReleaseStatusCodeEnum.ReleaseNotAddressed);
        values.push(ReleaseStatusCodeEnum.Empty);
      }

      if (values.length > 0) {
        const field = new SearchQueryField({
          key: 'RTW_DOCTOR_RELEASE_TYPE.CODE_ID',
          name: name,
          type: DcFieldTypeEnum.Text,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addWorkStatus(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value[WorkStatusOptionEnum.FullDuty]) { values.push(WorkStatusCodeEnum.FullDuty); }
      if (value[WorkStatusOptionEnum.ModifiedDuty]) { values.push(WorkStatusCodeEnum.ModifiedDuty); }
      if (value[WorkStatusOptionEnum.OutOfWork]) { values.push(WorkStatusCodeEnum.OffWork); }
      if (value[WorkStatusOptionEnum.TerminatedResigned]) { values.push(WorkStatusCodeEnum.TerminatedResigned); }
      if (value[WorkStatusOptionEnum.RefusedToWork]) { values.push(WorkStatusCodeEnum.RefusedToWork); }

      if (values.length > 0) {
        const field = new SearchQueryField({
          key: 'RTW_WORK_STATUS_TYPE.CODE_ID',
          name: name,
          type: DcFieldTypeEnum.Text,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }

  addBenefitStatus(name: string, value: any, operator: SearchQueryOperator) {
    if (value) {
      const values = [];
      if (value[BenefitStatusOptionEnum.TemporaryTotalDisability]) { values.push(BenefitStatusCodeEnum.TemporaryTotalDisability); }
      if (value[BenefitStatusOptionEnum.TemporaryPartialDisability]) { values.push(BenefitStatusCodeEnum.TemporaryPartialDisability); }
      if (value[BenefitStatusOptionEnum.PermanentPartialDisability]) { values.push(BenefitStatusCodeEnum.PermanentPartialDisability); }
      if (value[BenefitStatusOptionEnum.NotReceivingBenefits]) { values.push(BenefitStatusCodeEnum.Empty); }

      if (values.length > 0) {
        const field = new SearchQueryField({
          key: 'RTW_DISABILITY_TYPE.RTW_DISABILITY_TYPE_ABBREV.keyword',
          name: name,
          type: DcFieldTypeEnum.Text,
          values: values,
          operator: operator
        });

        this.query.fields.push(field);
      }
    }

    return this;
  }
}
