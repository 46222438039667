import { DatePipe, TitleCasePipe } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { BidiModule } from '@angular/cdk/bidi';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

import { DynamicModule } from 'ng-dynamic-component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { v4 as Uuidv4 } from 'uuid';

import {
  CdsButtonModule,
  CdsDirectivesModule,
  CdsModalModule,
  CdsNavigationModule,
  CdsTooltipModule,
  CdsAlertModule,
  CdsSelectModule,
  CdsLoadingOverlayModule
} from '@cds/angular';

import {
  SessionService,
  LoggingService,
  MenuService,
  ExcelService,
  StateService,
  TimerService,
  MenuComponent,
  FaviconService,
  HistoryService,
  SessionStorage,
  AuthInterceptor,
  CorrelationInterceptor,
  HttpCancelInterceptor,
  HttpCancelService,
  HeaderComponent,
  ClaimTypeService,
  SidebarComponent,
  SuggestionService,
  ClaimStatusService,
  SearchbarComponent,
  AuthLayoutComponent,
  BaseScreenComponent,
  ConfigService,
  UniversalApiService,
  AdminLayoutComponent,
  ClaimsLoadingService,
  FileDownloaderService,
  AdvancedSearchComponent,
  QuickClaimInfoComponent,
  ClaimSearchServiceProvider,
  GlobalErrorHandlerProvider,
  DcDynamicComponentParentProvider,
  UserInactivityWarningComponent,

  // Directives
  AccordionDirective,
  AccordionLinkDirective,
  AccordionAnchorDirective,
  MessageBusService
} from './core';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import {
  AtomsModule,
  MoleculesModule,
  OrganismsModule,
  TemplatesModule
} from '../component-library';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SessionKey } from './core/ccp/services/session/session-storage-key.const';
import { AlertService } from './core/ccp/services/alert/alert.service';
import { AlertsApiService } from './core/ccp/services/http/alerts/alerts-api.service';
import { AlertComponent } from './core/ccp/components/alert/alert.component';

import { DcPipesModule } from '@dc/core';
import { QuickClaimComponent } from './core/quick-claim/quick-claim.component';

import { AuthModule } from '@auth/auth';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const ENVIRONMENT = new InjectionToken('ENVIRONMENT');


@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    SearchbarComponent,
    AuthLayoutComponent,
    BaseScreenComponent,
    AdminLayoutComponent,
    AdvancedSearchComponent,
    QuickClaimInfoComponent,
    UserInactivityWarningComponent,
    AlertComponent,

    // Directives
    AccordionDirective,
    AccordionLinkDirective,
    AccordionAnchorDirective,
    QuickClaimComponent
  ],
  imports: [
    BidiModule,
    FormsModule,
    BrowserModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    DynamicModule.withComponents([
      QuickClaimInfoComponent
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: 'reload'
    }),
    SharedModule,
    AtomsModule,
    MoleculesModule,
    OrganismsModule,
    TemplatesModule,
    CdsButtonModule,
    CdsModalModule,
    CdsNavigationModule,
    CdsDirectivesModule,
    CdsTooltipModule,
    CdsAlertModule,
    CdsSelectModule,
    CdsLoadingOverlayModule,
    DcPipesModule,
    AuthModule.forRoot()
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      deps: [APP_ENVIRONMENT],
      useFactory: (appEnvironment: AppEnvironment) => appEnvironment.appConfig
    },
    DatePipe,
    TitleCasePipe,
    SessionService,
    LoggingService,
    FaviconService,
    MenuService,
    MessageBusService,
    ExcelService,
    SessionStorage,
    StateService,
    HistoryService,
    HttpCancelService,
    ClaimTypeService,
    SuggestionService,
    ClaimStatusService,
    UniversalApiService,
    ConfigService,
    ClaimsLoadingService,
    FileDownloaderService,
    ClaimSearchServiceProvider,
    TimerService,
    AlertService,
    AlertsApiService,
    GlobalErrorHandlerProvider,
    // DC Override
    DcDynamicComponentParentProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
  constructor(private sessionStorage: SessionStorage, private router: Router) {
    this.sessionStorage.set('Session-Id', Uuidv4());
    this.listenToRouter();
  }

  private listenToRouter() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const navigationHistorySession = sessionStorage.getItem(SessionKey.routeHistory);
        let navigationHistory = JSON.parse(navigationHistorySession) ?? [];
        if (navigationHistory.length > 1) {
          navigationHistory.shift();
        }
        navigationHistory = [...navigationHistory, event.urlAfterRedirects];
        sessionStorage.setItem(SessionKey.routeHistory, JSON.stringify(navigationHistory));
      }
    });
  }
}

