import {
  Component,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Inject,
  OnDestroy
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { Subscription, of as observableOf } from 'rxjs';

import {
  StateService
} from 'src/app/core/ccp/services/state.service';
import {
  ClaimStatusService
} from 'src/app/core/ccp//services/claim-status.service';

import { SearchQueryBuilder, SearchQueryUtils } from '@clu/search-query-builder';

import { Layouts } from 'src/assets/dynamic-forms';
import { DcDynamicComponentParent } from '@dc/components';
import { ClaimSearchQuery, ClaimSearchStateService, ClaimTypeService, SessionService } from '@app/core/ccp';
import { ClaimSearchQueryConverter } from '@app/core/ccp/utils/claim-search-query-converter';
import { UntypedFormGroup } from '@angular/forms';
import { map, take } from 'rxjs/operators';

import { CdsModalService } from '@cds/angular';
import { LineOfBusinessDescriptionEnum, LineOfBusinessEnum } from '@app/core/ccp/enums/line-of-business.enum';
import { UserAttributeIdentifierEnum } from '@app/core/ccp/enums/user-attribute-identifier.enum';


const AdvancedSearchConfig = require('@assets/configs/advanced-search-config.json');

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html'
})
export class AdvancedSearchComponent extends DcDynamicComponentParent implements OnInit, AfterViewInit, OnDestroy {
  layout: any;
  services: any;
  initialFormValues: any;
  humanizedQuery: any;
  data = {};

  formGroup: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  @Output() submitClicked = new EventEmitter<any>();

  // TODO: Best practice - order must be no access modifiers, protected, public, private
  constructor(private router: Router,
              private stateService: StateService,
              private claimStatusService: ClaimStatusService,
              private claimTypeService: ClaimTypeService,
              private claimSearchStateService: ClaimSearchStateService,
              private changeDetectorRef: ChangeDetectorRef,
              private dialogRef: MatDialogRef<AdvancedSearchComponent>,
              private sessionService: SessionService,
              public modalService: CdsModalService,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    super(modalService);
    this.layout = Layouts.getAdvancedSearchLayouts().advancedSearchForm;
    this.services = {
      getLineOfBusinessOptions: (layout, source, parameters) => {
        return this.sessionService.getUser().pipe(take(1), map((user) => {
          const lineOfBusinessOptions = [];

          Object.keys(LineOfBusinessEnum).forEach((lob) => {
            if (user.hasAccessToLOB(UserAttributeIdentifierEnum.RiskTechData, LineOfBusinessEnum[lob])) {
              lineOfBusinessOptions.push({
                label: LineOfBusinessDescriptionEnum[lob],
                control: lob,
                disabled: false,
                value: false
              });
            }
          });

          if (lineOfBusinessOptions.length === 1) {
            lineOfBusinessOptions[0].value = lineOfBusinessOptions[0].disabled = true;
          }

          return lineOfBusinessOptions;
        }));
      },
      getStateOptions: (layout, source, parameters) => {
        return this.stateService.getStateOptions()
          .pipe(
            map(states => states.map(state => ({
              label: state.text === 'Unknown State' ? 'Unknown' : state.text,
              key: state.value
            }))),
            map(states => {
              const sortedStates = this.sortObjectByKey(states, 'label');
              const uniqueStates = Array.from(new Map(sortedStates.map(item => [item.label, item])).values());
              return [{ key: '', label: 'All Jurisdictions' }, ...uniqueStates];
            })
          );
      },
      getClaimStatusOptions: (layout, source, parameters) => {
        return this.claimStatusService.getClaimStatusOptions();
      },
      submitSearch: (layout, source, parameters) => {
        claimSearchStateService.clearSearchStateValues();
        this.dialogRef.close();
        this.submitClicked.emit(true);
        const query = SearchQueryUtils.encodeSearchQuery(this.getQuery(source));

        const extras = {
          queryParams: { query: query }
        };

        this.router.navigateByUrl(this.router.createUrlTree(['/search/claims'], extras));
        return observableOf([]); // @HACK: because DynamicPanelParent.CallService expects a return value from a service call
      }
    };
  }

  ngOnInit() {
    if (this.dialogData?.query) {
      this.initialFormValues = ClaimSearchQueryConverter.convertToDynamicFormGroup(this.dialogData.query as ClaimSearchQuery);
    }

    const claimTypeSubscription = this.claimTypeService.getClaimTypes().subscribe((claimTypes) => {
      if (claimTypes) {
        this.setClaimTypePlaceholder('Choose Claim Type');
        let newClaimTypes = claimTypes.map((claimType) => ({ label: claimType.LABEL, key: claimType.KEY }));
        newClaimTypes.splice(0, 0, { label: 'All Claim Types', key: '' });
        this.data['Claim Type'] = newClaimTypes;
      } else {
        this.setClaimTypePlaceholder('Loading....');
      }
    });

    this.subscriptions.push(claimTypeSubscription);
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  sortObjectByKey(arr: any[], key: string) {
    return arr.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }

  isNotEmpty(target: any) {
    if (target && target instanceof Date) {
      return !!target;
    } else if (target && typeof target === 'object') {
      return Object.keys(target).some(key => this.isNotEmpty(target[key]));
    } else {
      return !!target;
    }
  }

  onFormStateChanged(formGroup: UntypedFormGroup) {
    this.formGroup = formGroup;
    const isNotEmpty = Object.keys(this.formGroup.value).some(key => this.isNotEmpty(this.formGroup.value[key].field));

    if (isNotEmpty && this.formGroup.valid) {
      const claimSearchQuery = this.getQuery(this.formGroup.value);
      const humanizedQuery = SearchQueryUtils.humanizeQuery(claimSearchQuery)
        .map(field => {
          if (field.name === 'Claim Status') {
            this.claimStatusService.getClaimStatuses().subscribe(statuses => {
              const values = (field.rawValues as Array<any>).map((value: string) =>
                statuses.find(status => value === status.SHORT_CODE).CODE_DESC
              );

              field.rawValues = values;
            });
          } else if (field.name === 'Jurisdiction' && field.rawValues[0] === 'Unknown State') {
            field.rawValues = ['Unknown'];
          }
          return field;
        });
      this.humanizedQuery = humanizedQuery;
    } else {
      this.humanizedQuery = '';
    }

    this.changeDetectorRef.detectChanges();
  }

  setClaimTypePlaceholder(placeholder: string) {
    const claimTypeControlIndex = this.layout.controls.findIndex((control) => control.name === 'Claim Type');
    const newControls = [...this.layout.controls];
    newControls[claimTypeControlIndex] = {
      ...newControls[claimTypeControlIndex],
      placeholder: placeholder
    };

    this.layout = {
      ...this.layout,
      controls: newControls
    };
  }

  getQuery(model) {
    const builder = new SearchQueryBuilder()
      .addClaimNumber(
        'Claim Number',
        getValue('Claim Number'),
        getOperator('Claim Number')
      )

      .addClaimantFirstName(
        'Claimant First Name',
        getValue('Claimant First Name'),
        getOperator('Claimant First Name')
      )

      .addClaimantLastName(
        'Claimant Last Name',
        getValue('Claimant Last Name'),
        getOperator('Claimant Last Name')
      )

      .addLossDate(
        'Loss Date',
        getDateValue('Loss Date'),
        getOperator('Loss Date')
      )

      .addClaimStatus(
        'Claim Status',
        getValue('Claim Status'),
        getOperator('Claim Status')
      )

      .addLocationCode(
        'Location Code',
        getValue('Location Code'),
        getOperator('Location Code')
      )

      .addCoverage(
        'Coverage',
        getValue('Coverage'),
        getOperator('Coverage')
      )

      .addClaimType(
        'Claim Type',
        getValue('Claim Type'),
        getOperator('Claim Type')
      )

      .addJurisdiction(
        'Jurisdiction',
        getValue('Jurisdiction'),
        getOperator('Jurisdiction')
      )

      .addInsuredDriverFirstName(
        'Insured Driver First Name',
        getValue('Insured Driver First Name'),
        getOperator('Insured Driver First Name')
      )

      .addInsuredDriverLastName(
        'Insured Driver Last Name',
        getValue('Insured Driver Last Name'),
        getOperator('Insured Driver Last Name')
      )

      .addProgramNumber(
        'Program Number',
        getValue('Program Number'),
        getOperator('Program Number')
      )

      .addProgramName(
        'Program Name',
        getValue('Program Name'),
        getOperator('Program Name')
      )

      .addOccurrenceNumber(
        'Occurrence Number',
        getValue('Occurrence Number'),
        getOperator('Occurrence Number')
      )

      .addInsuredName(
        'Insured Name',
        getValue('Insured Name'),
        getOperator('Insured Name')
      )

      .addAdjusterFirstName(
        'Adjuster First Name',
        getValue('Adjuster First Name'),
        getOperator('Adjuster First Name')
      )

      .addAdjusterLastName(
        'Adjuster Last Name',
        getValue('Adjuster Last Name'),
        getOperator('Adjuster Last Name')
      )

      .addClaimantSocialSecurity(
        'Claimant SSN/Tax Id',
        getValue('Claimant SSN/Tax Id'),
        getOperator('Claimant SSN/Tax Id')
      )

      .addVin(
        'VIN',
        getValue('VIN'),
        getOperator('VIN')
      )

      .addEmployeeNumber(
        'Employee Number',
        getValue('Employee Number'),
        getOperator('Employee Number')
      )

      .addTotalPaid(
        'Total Paid',
        getValue('Total Paid'),
        getOperator('Total Paid')
      )

      .addTotalOutstanding(
        'Total Outstanding',
        getValue('Total Outstanding'),
        getOperator('Total Outstanding')
      )

      .addTotalIncurred(
        'Total Incurred',
        getValue('Total Incurred'),
        getOperator('Total Incurred')
      )

      .addFederalStateClaimNumber(
        'Federal/State Claim Number',
        getValue('Federal/State Claim Number'),
        getOperator('Federal/State Claim Number')
      )

      .addPolicyNumber(
        'Policy Number',
        getValue('Policy Number'),
        getOperator('Policy Number')
      )

      .addDateReported(
        'Date Reported',
        getDateValue('Date Reported'),
        getOperator('Date Reported')
      )

      .addDateClosed(
        'Date Closed',
        getDateValue('Date Closed'),
        getOperator('Date Closed')
      )

      .addClientClaimNumber(
        AdvancedSearchConfig.CLIENT_CLAIM_NUMBER,
        getValue(AdvancedSearchConfig.CLIENT_CLAIM_NUMBER),
        getOperator(AdvancedSearchConfig.CLIENT_CLAIM_NUMBER)
      );

    return builder.buildQuery();

    function getValue(control: string) {
      if (model[control]) {
        const value = model[control]['field'];
        if (typeof(value) === 'string') {
          return value.trim();
        } else {
          return value;
        }
      }

      return null;
    }

    function getDateValue(control: string) {
      const dates = getValue(control);
      let value = null;

      if (dates && dates['startDate']) {
        let startDate = dates['startDate'];
        value = { startDate: new Date(startDate).format('MM-dd-yyyy') };
      }

      if (dates && dates['endDate']) {
        let endDate = dates['endDate'];
        value = { ...value, endDate: new Date(endDate).format('MM-dd-yyyy') };
      }

      return value;
    }

    function getOperator(control: string) {
      if (model[control]) {
        return model[control]['comparison'];
      }

      return null;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
