import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Menu,
  MenuService
} from '../../../menu/menu.service';

import { EventType } from '../../enums/event-type';
import { AlertService } from '../../services/alert/alert.service';
import { AlertsApiService } from '../../services/http/alerts/alerts-api.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-base-screen',
  template: ''
})
export class BaseScreenComponent {
  constructor(protected title: Title,
              protected router: Router,
              protected menuService: MenuService,
              protected sessionService: SessionService,
              protected alertService: AlertService,
              protected alertsApiService: AlertsApiService,
              protected activatedRoute: ActivatedRoute) {
  }

  async initScreen(title: string, eventType: EventType, eventData, eventMessage: string = (<any>EventType)[eventType]) {
    try {
      const params = this.activatedRoute.snapshot.params;
      this.title.setTitle(`${title} - Broadspire Claims`);
      this.sessionService.log(eventType, eventMessage, eventData, null, null, params);
      const alerts = await this.alertsApiService.getAlerts().toPromise();
      this.alertService.setAlerts(alerts);
    } catch (err) {
      this.sessionService.log(EventType.Error, (<any>EventType)[EventType.Error], eventData, err);
    }
  }

  setMenuItems(menuItems: Menu[]) {
    this.menuService.setMenuItems(menuItems);
  }

  setMenuWidget(menuWidget: string, parameters: any) {
    this.menuService.setMenuWidget(menuWidget, parameters);
  }
}
