import { ClaimSearchQuery } from '@app/core/ccp/base-classes/claim-search-query';

export class QuickSearchAction {
  navigateURL: string;
  claimSearchQuery: ClaimSearchQuery;

  constructor(action: QuickSearchAction) {
    this.navigateURL = action.navigateURL;
    this.claimSearchQuery = action.claimSearchQuery;
  }
}

export type QuickSearchGenerator = (value: string) => QuickSearchAction;
