import { AttributeEnum } from '../../enums/attribute.enum';
import { FunctionalRolesEnum } from '../../enums/functional-roles.enum';
import { GlobalFunctionsEnum } from '../../enums/global-functions.enum';
import { LineOfBusinessEnum } from '../../enums/line-of-business.enum';
import { UserAttributeIdentifierEnum } from '../../enums/user-attribute-identifier.enum';
import { UserAttributes } from './user-attribute/user-attribute';

export class User {
  readonly ccpUserAttributes: UserAttributes;
  readonly riskTechDataUserAttributes: UserAttributes;

  constructor(eaObject: any) {
    this.ccpUserAttributes = new UserAttributes(eaObject[UserAttributeIdentifierEnum.CCP]);
    this.riskTechDataUserAttributes = new UserAttributes(eaObject[UserAttributeIdentifierEnum.RiskTechData]);
  }

  /**
   * Checks if the user has access to a certain function {@link see FunctionalRolesEnum}
   *
   * @param identifier
   * @param functionalRole
   * @returns
   */
  hasAccessToFunction(identifier: UserAttributeIdentifierEnum, functionalRole: FunctionalRolesEnum) { // Generic
    const userAttribute = this.getUserAttribute(identifier);
    const attributeValue = userAttribute.getValuesByAttribute(AttributeEnum.Functional);
    return attributeValue && userAttribute.doesAttributeValueExist(functionalRole, attributeValue);
  }

  /**
   * Checks if the user has access to a certain LOB {@link see LineOfBusinessEnum}
   *
   * @param identifier
   * @param lob
   * @returns
   */
  hasAccessToLOB(identifier: UserAttributeIdentifierEnum, lob: LineOfBusinessEnum) { // Generic
    const userAttribute = this.getUserAttribute(identifier);
    const attributeValue = userAttribute.getValuesByAttribute(AttributeEnum.LineOfBusiness);
    return attributeValue && userAttribute.doesAttributeValueExist(lob, attributeValue) ||
    userAttribute.doesUserAttributeExist({ attribute: AttributeEnum.Global, attributeValue: GlobalFunctionsEnum.GLOBAL_ACCESS });
  }

  /**
   * Checks if the route rules required to access a page are in the user attributes
   *
   * @param identifier
   * @param routeRules
   * @returns
   */
  isAllowed(identifier: UserAttributeIdentifierEnum, routeRules: { userAttributes: any[] }) { // Generic
    return routeRules?.userAttributes?.some(userAttribute => this.getUserAttribute(identifier).doesUserAttributeExist(userAttribute));
  }

  private getUserAttribute(identifier: UserAttributeIdentifierEnum) {
    return identifier === UserAttributeIdentifierEnum.CCP ? this.ccpUserAttributes : this.riskTechDataUserAttributes;
  }
}
