function FunctionScore(clauses, functions) {
  return [
    {
      'function_score': {
        'query': {
          'bool': {
            'must': clauses
          }
        },
        'functions': functions
      }
    }
  ];
}

export function WeightByClaimId(clauses, claimIds) {
  return FunctionScore(clauses, [
    {
      'filter': {
        'terms': {
          'CLAIM_ID.keyword': claimIds.map( claimId => {
            return typeof(claimId) === 'string' ? claimId : `${claimId}`;
          })
        }
      },
      'weight': 10.0
    }
  ]);
}
