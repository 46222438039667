import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
  UrlSegment,
  CanActivateChildFn
} from '@angular/router';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { IAppState } from '@auth/auth';
import { RedirectLoginOptions } from '@auth0/auth0-angular';

import { SessionService } from '../session/session.service';
import { BrowserUtils } from '../../utils/browser-utils';
import { EventType } from '../../enums/event-type';

export const canMatchFunction: CanMatchFn = (
  _route: Route, _segments: UrlSegment[]) => redirectWhenLoggedOut(); // eslint-disable-line @typescript-eslint/no-unused-vars

export const canActivateChildFunction: CanActivateChildFn = (
  _route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => redirectWhenLoggedOut(state); // eslint-disable-line @typescript-eslint/no-unused-vars

export const canActivateFunction: CanActivateFn = (
  _route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => redirectWhenLoggedOut(state); // eslint-disable-line @typescript-eslint/no-unused-vars

function redirectWhenLoggedOut(state?: RouterStateSnapshot): Observable<boolean> {
  const sessionService: SessionService = inject(SessionService);

  return sessionService.isAuthenticated$.pipe(
    catchError((error: any) => throwError(() => new Error(`There was an error while authenticating your access. \n${error}`))),
    mergeMap((loggedIn: boolean) => {
      const target = state?.url ?? window.location.pathname;
      if (!loggedIn) {
        localStorage.setItem('search_params', window.location.search);
        const redirectOptions: RedirectLoginOptions<IAppState> = {
          appState: { target }
        };
        return sessionService.login(redirectOptions).pipe(
          mergeMap(() => of(loggedIn))
        );
      }

      sessionService.log(EventType.Successful_Login, 'AuthService: Successful login', BrowserUtils.getBrowserInfo(), null, () => {});
      return of(loggedIn);
    })
  );
}
