import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';
import { SessionStorage } from '../../services/session-storage/session-storage.service';
import { IAlert } from './alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
  alerts: IAlert[] = [];
  closedAlertIds: number[] = [];

  constructor(private alertService: AlertService, private sessionStorage: SessionStorage) { }

  ngOnInit(): void {
    this.getAlerts();
  }

  closeAlert(id: number) {
    this.closedAlertIds.push(id);
    this.sessionStorage.setJson('closedAlerts', { alertIds: this.closedAlertIds });
  }

  private isValidAlert(alert: IAlert) {
    return Object.keys(alert).every(key => alert[key] !== null && alert[key] !== undefined);
  }

  private getAlerts() {
    this.alertService.getAlerts().subscribe((data: IAlert[]) => {
      this.getClosedAlertIds();
      this.alerts = data.filter(alert => !this.closedAlertIds.includes(alert.AlertId) && this.isValidAlert(alert));
    });
  }

  private getClosedAlertIds() {
    const closedAlerts = this.sessionStorage.getJson('closedAlerts');
    this.closedAlertIds = [];
    if (closedAlerts && Object.keys(closedAlerts).length) {
      this.closedAlertIds = closedAlerts?.alertIds;
    }
  }
}
