import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class ConfigService {
  private serviceCache: { [key: string]: Observable<any> } = {};

  constructor(private httpClient: HttpClient) { }

  getConfig(key, url) {
    let observable: Observable<any>;

    if (!this.serviceCache[key]) {
      observable = this.httpClient.get<JSON>(url).pipe(shareReplay(1));
    } else {
      return this.serviceCache[key];
    }

    return observable.pipe(map(result => {
      this.serviceCache[key] = observable;
      return result;
    }));
  }
}
