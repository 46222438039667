import {
  Input,
  Component,
  OnInit,
  OnChanges,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  UntypedFormControl,
  UntypedFormGroup,
  AbstractControl
} from '@angular/forms';

@Component({
  selector: 'app-currency-range',
  templateUrl: './currency-range.component.html',
  providers: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class CurrencyRangeComponent implements OnInit, OnChanges, AfterViewInit {
  errorMessages = { firstCurrencyGreaterThanOrEqualToSecondCurrency: `The second amount must be greater than the first amount.` };

  @Input() name: string;
  @Input() placeholder?: string;
  @Input() maxlength: number;
  @Input() isRange: boolean;

  firstCurrency: AbstractControl;
  secondCurrency: AbstractControl;

  @ViewChild('range', { static: true }) range: ElementRef;
  el: HTMLInputElement;

  firstTimeRange = true;

  constructor(public parent: FormGroupDirective) {
    if (!this.parent) {
      throw new Error('FormGroup is missing from CurrencyRange.');
    }
  }

  ngOnInit() {
    if (!this.parent.form.get('firstCurrency')) {
      this.parent.form.addControl('firstCurrency', new UntypedFormControl(null));
    }
    this.firstCurrency = this.parent.form.get('firstCurrency');

    if (!this.parent.form.get('secondCurrency')) {
      this.parent.form.addControl('secondCurrency', new UntypedFormControl(null));
    }
    this.secondCurrency = this.parent.form.get('secondCurrency');
    this.firstCurrency.valueChanges.subscribe(() => {
      this.validationCheck();
    });
    this.secondCurrency.valueChanges.subscribe(() => {
      this.validationCheck();
    });
  }

  ngAfterViewInit() {
    this.el = this.range.nativeElement;
  }

  ngOnChanges() {
    if (this.firstCurrency && this.secondCurrency) {
      if (!this.firstCurrency.value || !this.secondCurrency.value) {
        this.firstTimeRange = true;
      }
      this.validationCheck();
    }
  }

  validationCheck() {
    if (!this.isRange) {
      // If all inputs are empty, we should remove all validators
      if (!this.firstCurrency.value && !this.secondCurrency.value) {
        this.parent.form.clearValidators();
      }
      // Always update value and validity for non-range
      this.parent.form.updateValueAndValidity();

    // If first time as range, set the "contains two currencies" validator
    }

    if (this.isRange && this.firstTimeRange) {
      this.parent.form.setValidators([this.containsTwoCurrencies]);

    // If moving from an input with both fields populated, add "contains two currencies" and "less than" validators
    } else if (this.isRange && !this.firstTimeRange && !this.el.contains(document.activeElement) &&
        (this.firstCurrency.value && this.secondCurrency.value)) {
      this.parent.form.setValidators([this.containsTwoCurrencies, this.firstCurrencyLessThanSecondCurrency]);
    }
  }

  private firstCurrencyLessThanSecondCurrency = (control: UntypedFormGroup): {[key: string]: any} | null => {
    // Do not invalidate if both fields in a range are not populated
    if (!this.firstCurrency.value || !this.secondCurrency.value || this.isRange === false) {
      return null;
    }

    // Get number values
    const firstCurrencyValue: any = parseFloat(control.get('firstCurrency').value.replace(/[^0-9.-]+/g, ''));
    const secondCurrencyValue: any = parseFloat(control.get('secondCurrency').value.replace(/[^0-9.-]+/g, ''));
    const firstCurrencyGreaterThanOrEqualToSecondCurrency = { 'firstCurrencyGreaterThanOrEqualToSecondCurrency': true };

    // Allow zero and check for "less than"
    if ((firstCurrencyValue || firstCurrencyValue === 0) && (secondCurrencyValue || secondCurrencyValue === 0) &&
        firstCurrencyValue >= secondCurrencyValue) {
      return firstCurrencyGreaterThanOrEqualToSecondCurrency;
    }

    return null;
  };

  private containsTwoCurrencies = (control: UntypedFormGroup): {[key: string]: any} | null => {
    // If either input is active or no range, validate
    if (this.el.contains(document.activeElement) || this.isRange === false) {
      return null;
    }

    // Give the user an opportunity to edit the inputs
    // The form ought to run validation for its children again on submit
    if (this.firstTimeRange) {
      this.firstTimeRange = false;
      return null;
    }

    // If both values empty, validate
    if (!this.firstCurrency.value && !this.secondCurrency.value) {
      return null;
    }

    // If missing only one value, invalidate
    if (!this.firstCurrency.value || !this.secondCurrency.value) {
      return { doesNotContainTwoDates: true };
    }

    return null;
  };
}
