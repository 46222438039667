export const ExcelConfig = {
  dataType: {
    boolean: 'b',
    date: 'd',
    error: 'e',
    number: 'n',
    text: 's',
    stub: 'z'
  },
  currencyFormat: '$#,##0.00',
  dateFormat: 'MM/dd/yyyy',
  dateTimeFormat: 'MM/dd/yyyy hh:mm:ss',
  timeFormat: 'hh:mm:ss'
};
