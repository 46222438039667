export class CaseUtils {
  static TitleCase(str: string) {
    if (!str || typeof str !== 'string') {
      return str;
    }
    return str.replace(
      /(\w+[-|\s]*)/g,
      (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
  }
}
