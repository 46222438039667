import { Input, Component } from '@angular/core';
import { HumanizedField } from '@app/core/ccp/utils/search-query-utils';

@Component({
  selector: 'app-search-criteria',
  templateUrl: './search-criteria.component.html'
})
export class SearchCriteriaComponent {
  @Input() classes = 'craw-text-medium';
  @Input() humanizedQuery: HumanizedField[];
}
