
import {from as observableFrom,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';


export class State {
  CODE_ID: number;
  SHORT_CODE: string;
  CODE_DESC: string;
}

@Injectable()
export class StateService {
  getStates(): Observable<State[]> {
    // NOTE
    //  These values now flow all the way from the form control, through
    //  the ClaimSearchQueryBuilder and into the ClaimSearchQueryConverter.
    //  These values are ultimately what will get sent to the backend for a search.
    //
    //  THEY MUST MATCH THE DATABASE EXACTLY!
    //
    //  If we ever want to show a different value on the front end, we can do that by
    //  changing the `text` field in getStateOptions below, but the `value` field MUST
    //  remain as CODE_DESC!
    //                                                      - Brian Rowlett, 2019-10-24
    return observableFrom([
      [
        {
          CODE_ID: 116,
          SHORT_CODE: 'AA',
          CODE_DESC: 'Armed Forces in America'
        }, {
          CODE_ID: 3,
          SHORT_CODE: 'AB',
          CODE_DESC: 'Alberta'
        }, {
          CODE_ID: 117,
          SHORT_CODE: 'AE',
          CODE_DESC: 'Armed Forces in Europe'
        }, {
          CODE_ID: 2,
          SHORT_CODE: 'AK',
          CODE_DESC: 'Alaska'
        }, {
          CODE_ID: 1,
          SHORT_CODE: 'AL',
          CODE_DESC: 'Alabama'
        }, {
          CODE_ID: 118,
          SHORT_CODE: 'AP',
          CODE_DESC: 'Armed Forces in Pacific'
        }, {
          CODE_ID: 119,
          SHORT_CODE: 'AQ',
          CODE_DESC: 'Aruba'
        }, {
          CODE_ID: 5,
          SHORT_CODE: 'AR',
          CODE_DESC: 'Arkansas'
        }, {
          CODE_ID: 120,
          SHORT_CODE: 'AS',
          CODE_DESC: 'American Samoa'
        }, {
          CODE_ID: 4,
          SHORT_CODE: 'AZ',
          CODE_DESC: 'Arizona'
        }, {
          CODE_ID: 67,
          SHORT_CODE: 'BC',
          CODE_DESC: 'British Columbia'
        }, {
          CODE_ID: 7,
          SHORT_CODE: 'CA',
          CODE_DESC: 'California'
        }, {
          CODE_ID: 8,
          SHORT_CODE: 'CO',
          CODE_DESC: 'Colorado'
        }, {
          CODE_ID: 121,
          SHORT_CODE: 'CS',
          CODE_DESC: 'Cmnwlth North Mariana ISL'
        }, {
          CODE_ID: 9,
          SHORT_CODE: 'CT',
          CODE_DESC: 'Connecticut'
        }, {
          CODE_ID: 57,
          SHORT_CODE: 'DC',
          CODE_DESC: 'Washington D.C.'
        }, {
          CODE_ID: 10,
          SHORT_CODE: 'DE',
          CODE_DESC: 'Delaware'
        }, {
          CODE_ID: 66,
          SHORT_CODE: 'FD',
          CODE_DESC: 'Federal'
        }, {
          CODE_ID: 73,
          SHORT_CODE: 'FDA',
          CODE_DESC: 'Food and Drug Admin'
        }, {
          CODE_ID: 74,
          SHORT_CODE: 'FECA',
          CODE_DESC: 'Federal Employee Comp'
        }, {
          CODE_ID: 11,
          SHORT_CODE: 'FL',
          CODE_DESC: 'Florida'
        }, {
          CODE_ID: 12,
          SHORT_CODE: 'GA',
          CODE_DESC: 'Georgia'
        }, {
          CODE_ID: 82,
          SHORT_CODE: 'GU',
          CODE_DESC: 'Guam'
        }, {
          CODE_ID: 13,
          SHORT_CODE: 'HI',
          CODE_DESC: 'Hawaii'
        }, {
          CODE_ID: 17,
          SHORT_CODE: 'IA',
          CODE_DESC: 'Iowa'
        }, {
          CODE_ID: 14,
          SHORT_CODE: 'ID',
          CODE_DESC: 'Idaho'
        }, {
          CODE_ID: 15,
          SHORT_CODE: 'IL',
          CODE_DESC: 'Illinois'
        }, {
          CODE_ID: 16,
          SHORT_CODE: 'IN',
          CODE_DESC: 'Indiana'
        }, {
          CODE_ID: 122,
          SHORT_CODE: 'JM',
          CODE_DESC: 'Jamaica'
        }, {
          CODE_ID: 18,
          SHORT_CODE: 'KS',
          CODE_DESC: 'Kansas'
        }, {
          CODE_ID: 19,
          SHORT_CODE: 'KY',
          CODE_DESC: 'Kentucky'
        }, {
          CODE_ID: 20,
          SHORT_CODE: 'LA',
          CODE_DESC: 'Louisiana'
        }, {
          CODE_ID: 75,
          SHORT_CODE: 'LHWA',
          CODE_DESC: 'Longshore and Harbor Work'
        }, {
          CODE_ID: 24,
          SHORT_CODE: 'MA',
          CODE_DESC: 'Massachusetts'
        }, {
          CODE_ID: 22,
          SHORT_CODE: 'MB',
          CODE_DESC: 'Manitoba'
        }, {
          CODE_ID: 23,
          SHORT_CODE: 'MD',
          CODE_DESC: 'Maryland'
        }, {
          CODE_ID: 21,
          SHORT_CODE: 'ME',
          CODE_DESC: 'Maine'
        }, {
          CODE_ID: 25,
          SHORT_CODE: 'MI',
          CODE_DESC: 'Michigan'
        }, {
          CODE_ID: 26,
          SHORT_CODE: 'MN',
          CODE_DESC: 'Minnesota'
        }, {
          CODE_ID: 28,
          SHORT_CODE: 'MO',
          CODE_DESC: 'Missouri'
        }, {
          CODE_ID: 27,
          SHORT_CODE: 'MS',
          CODE_DESC: 'Mississippi'
        }, {
          CODE_ID: 29,
          SHORT_CODE: 'MT',
          CODE_DESC: 'Montana'
        }, {
          CODE_ID: 123,
          SHORT_CODE: 'NA',
          CODE_DESC: 'Unknown State'
        }, {
          CODE_ID: 32,
          SHORT_CODE: 'NB',
          CODE_DESC: 'New Brunswick'
        }, {
          CODE_ID: 37,
          SHORT_CODE: 'NC',
          CODE_DESC: 'North Carolina'
        }, {
          CODE_ID: 38,
          SHORT_CODE: 'ND',
          CODE_DESC: 'North Dakota'
        }, {
          CODE_ID: 30,
          SHORT_CODE: 'NE',
          CODE_DESC: 'Nebraska'
        }, {
          CODE_ID: 68,
          SHORT_CODE: 'NF',
          CODE_DESC: 'Newfoundland'
        }, {
          CODE_ID: 33,
          SHORT_CODE: 'NH',
          CODE_DESC: 'New Hampshire'
        }, {
          CODE_ID: 34,
          SHORT_CODE: 'NJ',
          CODE_DESC: 'New Jersey'
        }, {
          CODE_ID: 35,
          SHORT_CODE: 'NM',
          CODE_DESC: 'New Mexico'
        }, {
          CODE_ID: 40,
          SHORT_CODE: 'NS',
          CODE_DESC: 'Nova Scotia'
        }, {
          CODE_ID: 39,
          SHORT_CODE: 'NT',
          CODE_DESC: 'Northwest Territories'
        }, {
          CODE_ID: 69,
          SHORT_CODE: 'NU',
          CODE_DESC: 'Nunavut'
        }, {
          CODE_ID: 31,
          SHORT_CODE: 'NV',
          CODE_DESC: 'Nevada'
        }, {
          CODE_ID: 36,
          SHORT_CODE: 'NY',
          CODE_DESC: 'New York'
        }, {
          CODE_ID: 41,
          SHORT_CODE: 'OH',
          CODE_DESC: 'Ohio'
        }, {
          CODE_ID: 42,
          SHORT_CODE: 'OK',
          CODE_DESC: 'Oklahoma'
        }, {
          CODE_ID: 43,
          SHORT_CODE: 'ON',
          CODE_DESC: 'Ontario'
        }, {
          CODE_ID: 44,
          SHORT_CODE: 'OR',
          CODE_DESC: 'Oregon'
        }, {
          CODE_ID: 45,
          SHORT_CODE: 'PA',
          CODE_DESC: 'Pennsylvania'
        }, {
          CODE_ID: 70,
          SHORT_CODE: 'PE',
          CODE_DESC: 'Prince Edward Island'
        }, {
          CODE_ID: 81,
          SHORT_CODE: 'PR',
          CODE_DESC: 'Puerto Rico'
        }, {
          CODE_ID: 47,
          SHORT_CODE: 'QC',
          CODE_DESC: 'Quebec'
        }, {
          CODE_ID: 48,
          SHORT_CODE: 'RI',
          CODE_DESC: 'Rhode Island'
        }, {
          CODE_ID: 124,
          SHORT_CODE: 'RX',
          CODE_DESC: 'Turks & Caicos Islands'
        }, {
          CODE_ID: 49,
          SHORT_CODE: 'SC',
          CODE_DESC: 'South Carolina'
        }, {
          CODE_ID: 50,
          SHORT_CODE: 'SD',
          CODE_DESC: 'South Dakota'
        }, {
          CODE_ID: 71,
          SHORT_CODE: 'SK',
          CODE_DESC: 'Saskatchewan'
        }, {
          CODE_ID: 51,
          SHORT_CODE: 'TN',
          CODE_DESC: 'Tennessee'
        }, {
          CODE_ID: 125,
          SHORT_CODE: 'TO',
          CODE_DESC: 'Tonga'
        }, {
          CODE_ID: 52,
          SHORT_CODE: 'TX',
          CODE_DESC: 'Texas'
        }, {
          CODE_ID: 53,
          SHORT_CODE: 'UT',
          CODE_DESC: 'Utah'
        }, {
          CODE_ID: 55,
          SHORT_CODE: 'VA',
          CODE_DESC: 'Virginia'
        }, {
          CODE_ID: 83,
          SHORT_CODE: 'VI',
          CODE_DESC: 'Virgin Islands'
        }, {
          CODE_ID: 54,
          SHORT_CODE: 'VT',
          CODE_DESC: 'Vermont'
        }, {
          CODE_ID: 56,
          SHORT_CODE: 'WA',
          CODE_DESC: 'Washington'
        }, {
          CODE_ID: 59,
          SHORT_CODE: 'WI',
          CODE_DESC: 'Wisconsin'
        }, {
          CODE_ID: 58,
          SHORT_CODE: 'WV',
          CODE_DESC: 'West Virginia'
        }, {
          CODE_ID: 60,
          SHORT_CODE: 'WY',
          CODE_DESC: 'Wyoming'
        }, {
          CODE_ID: 72,
          SHORT_CODE: 'YT',
          CODE_DESC: 'Yukon Territory'
        }, {
          CODE_ID: 126,
          SHORT_CODE: 'ZZ',
          CODE_DESC: 'Unknown State'
        }
      ]
    ]);
  }

  getStateOptions(): Observable<{ value: string, text: string }[]> {
    return this.getStates().pipe(map(states =>
      states.map(state => {
        return {
          value: state.CODE_DESC, // DON'T TOUCH ME!
          text: state.CODE_DESC
        };
      })
    ));
  }
}
