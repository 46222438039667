export enum ClaimTypeMappingEnum {
  "AUTO BI STAND ALONE SUBROGATION" = "AUTO BI STAND ALONE SUBROGATION",
  "AUTO COLLISION STAND ALONE SUBROGATION" = "AUTO COLLISION STAND ALONE SUBROGATION",
  "AUTO PD STAND ALONE SUBROGATION" = "AUTO PD STAND ALONE SUBROGATION",
  "ADVERTISING LIABILITY (ACCIDENT/OCCURRENCE)" = "Advertising Liability (Accident/Occurrence)",
  "ADVERTISING LIABILITY (CLAIMS MADE)" = "Advertising Liability (Claims Made)",
  "ATHLETIC PARTICIPATION LIABILITY-BI" = "Athletic Participation Liability-BI",
  "AUTO LIABILITY-PUERTO RICO" = "Auto Liability-Puerto Rico",
  "BAILEE COVERAGE" = "Bailee Coverage",
  "BAILEE LIABILITY" = "Bailee Liability",
  "BOAT LIABILITY-BI" = "Boat Liability-BI",
  "BOAT LIABILITY-PD" = "Boat Liability-PD",
  "BOAT-HULL LOSS" = "Boat-Hull Loss",
  "BOILER & MACHINERY" = "Boiler & Machinery",
  "BUILDER'S RISK/CONTRACTOR'S ALL RISK" = "Builder's Risk/Contractor's All Risk",
  "CARGO-CARRIER'S LIABILITY" = "Cargo-Carrier's Liability",
  "CARGO-SHIPPER'S COVERAGE" = "Cargo-Shipper's Coverage",
  "CARPAL TUNNEL/CUM. TRAUMA OTMO - AK FL, HI, NY" = "Carpal Tunnel and Cumulative Trauma Other than Med Only-AK, FL, HI, NY",
  "CARPAL TUNNEL/CUM. TRAUMA OTMO ALL OTHER STATES" = "Carpal Tunnel and Cumulative Trauma Other than Med Only-All Other States",
  "CARPAL TUNNEL/CUM. TRAUMA OTMO - CA" = "Carpal Tunnel and Cumulative Trauma Other than Med Only-CA",
  "CARPAL TUNNEL/CUM. TRAUMA OTHER THAN MED ONLY - TX" = "Carpal Tunnel and Cumulative Trauma Other than Med Only-TX",
  "COMMERCIAL AUTO - BI" = "Commercial Auto - BI",
  "COMMERCIAL AUTO - COLLISION" = "Commercial Auto - Collision",
  "COMMERCIAL AUTO - INCIDENT REPORT" = "Commercial Auto - Incident Report",
  "COMMERCIAL AUTO - MED PAY" = "Commercial Auto - Med Pay",
  "COMMERCIAL AUTO - NO FAULT BI" = "Commercial Auto - No Fault BI",
  "COMMERCIAL AUTO - NO FAULT PD" = "Commercial Auto - No Fault PD",
  "COMMERCIAL AUTO - NON-OWNERSHIP BI" = "Commercial Auto - Non-Ownership BI",
  "COMMERCIAL AUTO - NON-OWNERSHIP PD" = "Commercial Auto - Non-Ownership PD",
  "COMMERCIAL AUTO - PD" = "Commercial Auto - PD",
  "COMMERCIAL AUTO - UNINSURED MOTORIST" = "Commercial Auto - Uninsured Motorist",
  "COMMERCIAL AUTO-COMPREHENSIVE" = "Commercial Auto-Comprehensive",
  "COMMERCIAL AUTO-FIRE" = "Commercial Auto-Fire",
  "COMMERCIAL AUTO-THEFT" = "Commercial Auto-Theft",
  "COMMERCIAL AUTO-UNINSURED MOTORISTS-PD" = "Commercial Auto-Uninsured Motorists-PD",
  "COMMERCIAL CRIME" = "Commercial Crime",
  "COMMERCIAL GENERAL LIABILITY-BI" = "Commercial General Liability-BI",
  "COMMERCIAL GENERAL LIABILITY-INCIDENT REPORT" = "Commercial General Liability-Incident Report",
  "COMMERCIAL GENERAL LIABILITY-MED PAY" = "Commercial General Liability-Med Pay",
  "COMMERCIAL GENERAL LIABILITY-PD" = "Commercial General Liability-PD",
  "COMMERCIAL GENERAL LIABILITY-PUERTO RICO" = "Commercial General Liability-Puerto Rico",
  "COMMERCIAL GENERAL LIABILITY-SUBSIDENCE-BI" = "Commercial General Liability-Subsidence-BI",
  "COMMERCIAL GENERAL LIABILITY-SUBSIDENCE-PD" = "Commercial General Liability-Subsidence-PD",
  "COMMERCIAL PROPERTY" = "Commercial Property",
  "COMMERCIAL TIME ELEMENT" = "Commercial Time Element",
  "COMPLETED OPERATIONS-BI" = "Completed Operations-BI",
  "COMPLETED OPERATIONS-PD" = "Completed Operations-PD",
  "COMPLETED OPERATIONS-SUBSIDENCE-BI" = "Completed Operations-Subsidence-BI",
  "COMPLETED OPERATIONS-SUBSIDENCE-PD" = "Completed Operations-Subsidence-PD",
  "CONTINGENT LIABILITY-BI" = "Contingent Liability-BI",
  "CONTINGENT LIABILITY-PD" = "Contingent Liability-PD",
  "CONTRACTUAL LIABILITY-BI" = "Contractual Liability-BI",
  "CONTRACTUAL LIABILITY-PD" = "Contractual Liability-PD",
  "CORPORAL PUNISHMENT LIABILITY (CLAIMS MADE)" = "Corporal Punishment Liability (Claims Made)",
  "CORPORAL PUNISHMENT LIABILITY(ACCIDENT/OCCURRENCE)" = "Corporal Punishment Liability(Accident/Occurrence)",
  "CUSTOMER SERVICE INCIDENT BI" = "Customer Service Incident BI",
  "CUSTOMER SERVICE INCIDENT PD" = "Customer Service Incident PD",
  "DATA PROCESSING EQUIPMENT COVERAGE" = "Data Processing Equipment Coverage",
  "DEALER'S FLOATERS" = "Dealer's Floaters",
  "DIFFERENCES IN CONDITIONS" = "Differences in Conditions",
  "DRUGGIST LIABILITY-BI (ACCIDENT/OCCURRENCE)" = "Druggist Liability-BI (Accident/Occurrence)",
  "DRUGGIST LIABILITY-BI (CLAIMS MADE)" = "Druggist Liability-BI (Claims Made)",
  "ELEVATOR LIABILITY-BI" = "Elevator Liability-BI",
  "ELEVATOR LIABILITY-PD" = "Elevator Liability-PD",
  "EMPLOYEE BENEFITS LIABILITY-PD" = "Employee Benefits Liability-PD",
  "EMPLOYER'S LIABILITY" = "Employer's Liability",
  "EMPLOYER'S LIABILITY-OCCUPATIONAL DISEASE" = "Employer's Liability-Occupational Disease",
  "EMPLOYMENT PRACTICES LIABILITY (ACCIDENT/OCCURRENCE)" = "Employment Practices Liability (Accident/Occurrence)",
  "EMPLOYMENT PRACTICES LIABILITY (CLAIMS MADE)" = "Employment Practices Liability (Claims Made)",
  "ERRORS & OMISSIONS LIABILITY (ACCIDENT/OCCURRENCE)" = "Errors & Omissions Liability (Accident/Occurrence)",
  "ERRORS & OMISSIONS LIABILITY (CLAIMS MADE)" = "Errors & Omissions Liability (Claims Made)",
  "FELA-MED ONLY" = "FELA-Med Only",
  "FELA-OCCUPATIONAL DISEASE-MED ONLY" = "FELA-Occupational Disease-Med Only",
  "FELA-OCCUPATIONAL DISEASE-OTHER THAN MED ONLY" = "FELA-Occupational Disease-Other than Med Only",
  "FELA-OTHER THAN MED ONLY" = "FELA-Other than Med Only",
  "FAST TRACK GL - BI" = "Fast Track GL - BI",
  "FAST TRACK GL - PD" = "Fast Track GL - PD",
  "FAST TRACK PL - BI" = "Fast Track PL - BI",
  "FAST TRACK PL - PD" = "Fast Track PL - PD",
  "FIDELITY BONDS" = "Fidelity Bonds",
  "FINE ARTS FLOATERS" = "Fine Arts Floaters",
  "FIRE - (INACTIVED - USE 146)" = "Fire - (Inactived - Use 146)",
  "FIRE LEGAL LIABILITY-PD" = "Fire Legal Liability-PD",
  "FURRIER'S BLOCK" = "Furrier's Block",
  "GC ADMIN MISCELLANEOUS FEES" = "GC Admin Miscellaneous Fees",
  "GL BI STAND ALONE SUBROGATION" = "GL BI Stand Alone Subrogation",
  "GL PD STAND ALONE SUBROGATION" = "GL PD Stand Alone Subrogation",
  "GARAGE KEEPERS LEGAL LIAB-PD (ACCIDENT/OCCURRENCE)" = "Garage Keepers Legal Liab-PD (Accident/Occurrence)",
  "GARAGE KEEPERS LEGAL LIABILITY-COLLISION" = "Garage Keepers Legal Liability-Collision",
  "GARAGE KEEPERS LEGAL LIABILITY-COMPREHENSIVE" = "Garage Keepers Legal Liability-Comprehensive",
  "GARAGE KEEPERS LEGAL LIABILITY-PD" = "Garage Keepers Legal Liability-PD",
  "GARAGE LIABILITY-BI (ACCIDENT/OCCURRENCE)" = "Garage Liability-BI (Accident/Occurrence)",
  "GARAGE LIABILITY-BI (CLAIMS MADE)" = "Garage Liability-BI (Claims Made)",
  "GARAGE LIABILITY-PD (ACCIDENT/OCCURRENCE)" = "Garage Liability-PD (Accident/Occurrence)",
  "GARAGE LIABILITY-PD (CLAIMS MADE)" = "Garage Liability-PD (Claims Made)",
  "HOUSEHOLD GOODS (RETIRED - USE 289-CARGO CARRIER'S LIABILITY)" = "Household Goods (Retired - Use 289-Cargo Carrier's Liability)",
  "INCIDENT ONLY - PROPERTY" = "Incident Only - Property",
  "INDEPENDENT CONTRACTOR-BI" = "Independent Contractor-BI",
  "INDEPENDENT CONTRACTOR-PD" = "Independent Contractor-PD",
  "INNKEEPERS LIABILITY-PD" = "Innkeepers Liability-PD",
  "INNKEEPERS LIABILITY_BI (RETIRED)" = "Innkeepers Liability_BI (Retired)",
  "JEWELER'S BLOCK" = "Jeweler's Block",
  "JONES ACT" = "Jones Act",
  "JONES ACT-OCCUPATIONAL DISEASE" = "Jones Act-Occupational Disease",
  "JONES ACT/M&C-OCC DISEASE-CUMULATIVE TRAUMA" = "Jones Act/M&C-Occupational Disease-Cumulative Trauma",
  "LIQUOR LIABILITY-BI" = "Liquor Liability-BI",
  "LIQUOR LIABILITY-PD" = "Liquor Liability-PD",
  "MAINTENANCE & CURE" = "Maintenance & Cure",
  "MAINTENANCE & CURE-OCCUPATIONAL DISEASE" = "Maintenance & Cure-Occupational Disease",
  "MANAGED MEDICAL" = "Managed Medical",
  "MANUFACTURERS & CONTRACTORS-BI" = "Manufacturers & Contractors-BI",
  "MANUFACTURERS & CONTRACTORS-PD" = "Manufacturers & Contractors-PD",
  "MARINE SURVEYS-CARGO-PD" = "Marine Surveys-Cargo-PD",
  "MARINE SURVEYS-HULL-PD" = "Marine Surveys-Hull-PD",
  "MEDICAL MALPRACTICE" = "Medical Malpractice",
  "MEDICAL ONLY - DOWNGRADED FROM OTHER THAN MEDICAL ONLY" = "Medical Only - Downgraded from Other than Medical Only",
  "MOBILE HOME BI" = "Mobile Home-BI",
  "MOBILE HOME COLLISION, THEFT" = "Mobile Home Collision (theft)",
  "MOBILE HOME FIRE (RETIRED - USE 240-MOBILE HOME PROPERTY)" = "Mobile Home Fire (Retired - Use 240-Mobile Home Property)",
  "MOBILE HOME PROPERTY" = "Mobile Home Property",
  "MOBILE HOME-PD" = "Mobile Home-PD",
  "MOTORCYCLE - BI" = "Motorcycle-BI",
  "MOTORCYCLE - MED PAY" = "Motorcycle-Med Pay",
  "MOTORCYCLE - PD" = "Motorcycle-PD",
  "MOTORCYCLE - UNINSURED MOTORIST" = "Motorcycle-Uninsured Motorist-BI",
  "MOTORCYCLE PHYSICAL DAMAGE" = "Motorcycle Physical Damage ",
  "NATURAL CAT PROPERTY" = "Natural Cat Property",
  "NON-STATUTORY EMPLOYEE COMP-CT/CT-OTMO-AK CA FL TX" = "Non-statutory Employee Compensation-Carpal Tunnel and Cumulative Trauma-Other Than Med Only-AK, CA, FL, TX",
  "NON-STATUTORY EMPLOYEE COMP-CT/CT-OTMO-ALL OTHERS" = "Non-statutory Employee Compensation-Carpal Tunnel and Cumulative Trauma-Other Than Med Only-All Other States",
  "NON-STATUTORY EMPLOYEE COMP-MED ONLY" = "Non-statutory Employee Compensation-Med Only",
  "NON-STATUTORY EMPLOYEE COMP-OTMO-AK CA FL TX" = "Non-statutory Employee Compensation-Other Than Med Only-AK, CA, FL, TX",
  "NON-STATUTORY EMPLOYEE COMP-OTMO-ALL OTHER STATES" = "Non-statutory Employee Compensation-Other Than Med Only-All Other States",
  "NON-STATUTORY EMPLOYEE COMP-OCC DISEASE-MED ONLY" = "Non-statutory Employee Compensation-Occupational Disease-Med Only",
  "NON-STATUTORY EMPLOYEE COMP-OCC.DISEASE-OTMO" = "Non-statutory Employee Compensation-Occupational Disease-Other Than Med Only",
  "NOT APPLICABLE" = "Not Applicable",
  "OTHER INLAND MARINE POLICY/FLOATER" = "Other Inland Marine Policy/Floater",
  "OTHER MARINE LIABILITY-BI" = "Other Marine Liability-BI",
  "OTHER MARINE LIABILITY-PD" = "Other Marine Liability PD",
  "OTHER PROF. LIAB PD" = "Other Prof. Liab - PD",
  "OWNERS & CONTRACTORS LIABILITY-BI" = "Owners & Contractors Liability-BI",
  "OWNERS & CONTRACTORS LIABILITY-PD" = "Owners & Contractors Liability-PD",
  "OWNERS, LANDLORDS & TENANTS-BI" = "Owners, Landlords, & Tenants-BI",
  "OWNERS, LANDLORDS, & TENANTS-BI" = "Owners, Landlords, & Tenants-BI",
  "OWNERS, LANDLORDS, & TENANTS-PD" = "Owners, Landlords, & Tenants-PD",
  "PERSONAL AUTO - BI" = "Personal Auto-BI",
  "PERSONAL AUTO - COLLISION" = "Personal Auto-Collision",
  "PERSONAL AUTO - INCIDENT REPORT" = "Personal Auto-Incident Report",
  "PERSONAL AUTO - MED PAY" = "Personal Auto-Med Pay",
  "PERSONAL AUTO - NO FAULT BI" = "Personal Auto-No Fault-BI",
  "PERSONAL AUTO - NO FAULT PD" = "Personal Auto-No Fault-PD",
  "PERSONAL AUTO - NON-OWNERSHIP BI" = "Personal Auto-Non-Ownership-BI",
  "PERSONAL AUTO - NON-OWNERSHIP PD" = "Personal Auto-Non-Ownership-PD",
  "PERSONAL AUTO - PD" = "Personal Auto-PD",
  "PERSONAL AUTO - UNINSURED MOTORIST" = "Personal Auto-Uninsured Motorist-BI",
  "PERSONAL AUTO-COMPREHENSIVE" = "Personal Auto-Comprehensive",
  "PERSONAL AUTO-FIRE" = "Personal Auto-Fire",
  "PERSONAL AUTO-THEFT" = "Personal Auto-Theft",
  "PERSONAL AUTO-UNINSURED MOTORIST-PD" = "Personal Auto-Uninsured Motorist-PD",
  "PERSONAL INJURY LIABILITY (ACCIDENT/OCCURRENCE)" = "Personal Injury Liability (Accident/Occurrence)",
  "PERSONAL INJURY LIABILITY (CLAIMS MADE)" = "Personal Injury Liability (Claims Made)",
  "PHYSICIANS MEDICAL MALPRACTICE-BI (ACC/OCCUR)" = "Physicians Medical Malpractice-BI (Acc/Occur)",
  "PHYSICIANS MEDICAL MALPRACTICE-BI (CLAIMS MADE)" = "Physicians Medical Malpractice-BI (Claims Made)",
  "PHYSICIANS MEDICAL MALPRACTICE-PD (ACC/OCCUR)" = "Physicians Medical Malpractice-PD (Acc/Occur)",
  "PHYSICIANS MEDICAL MALPRACTICE-PD (CLAIMS MADE)" = "Physicians Medical Malpractice-PD (Claims Made)",
  "PLATE GLASS LOSSES" = "Plate Glass Losses",
  "POLLUTION/OIL SPILLS/ETC. BI" = "Pollution/Oil Spills/Etc. BI",
  "POLLUTION/OIL SPILLS/ETC.-PD" = "Pollution/Oil Spills/etc.-PD",
  "PRODUCT RECALL" = "Product Recall",
  "PRODUCTS LIABILITY-BI (ACCIDENT/OCCURRENCE)" = "Products Liability-BI (Accident/Occurrence)",
  "PRODUCTS LIABILITY-BI (CLAIMS MADE)" = "Products Liability-BI (Claims Made)",
  "PRODUCTS LIABILITY-PD ( CLAIMS MADE)" = "Products Liability-PD ( Claims Made)",
  "PRODUCTS LIABILITY-PD (ACCIDENT/OCCURRENCE)" = "Products Liability-PD (Accident/Occurrence)",
  "PROFESSIONAL LIABILITY-BI (ACCIDENT/OCCURRENCE)" = "Professional Liability-BI (Accident/Occurrence)",
  "PROFESSIONAL LIABILITY-BI (CLAIMS MADE)" = "Professional Liability-BI (Claims Made)",
  "PROFESSIONAL LIABILITY-PD (ACCIDENT/OCCURRENCE)" = "Professional Liability-PD (Accident/Occurrence)",
  "PROPERTY STAND ALONE SUBROGATION" = "Property Stand Alone Subrogation",
  "RESIDENTIAL COMP. PERSONAL LIABILITY-MED PAY" = "Residential Comp. Personal Liability-Med Pay",
  "RESIDENTIAL COMPREHENSIVE PERSONAL LIABILITY-BI" = "Residential Comprehensive Personal Liability-BI",
  "RESIDENTIAL COMPREHENSIVE PERSONAL LIABILITY-PD" = "Residential Comprehensive Personal Liability-PD",
  "RESIDENTIAL FIRE LOSS (RETIRED - USE 248-RESIDENTIAL PROPERTY COVERAGE)" = "Residential Fire Loss (Retired - Use 248-Residential Property Coverage)",
  "RESIDENTIAL PROPERTY" = "Residential Property",
  "RESIDENTIAL THEFT ADJUSTMENT" = "Residential Theft Adjustment",
  "SAFETY DEPOSIT BOX LIABILITY (ACCIDENT/OCCURRENCE)" = "Safety Deposit Box Liability (Accident/Occurrence)",
  "SAFETY DEPOSIT BOX LIABILITY (CLAIMS MADE)" = "Safety Deposit Box Liability (Claims Made)",
  "STATE WC-INCIDENT REPORT" = "State Workers Compensation-Incident Report",
  "STATE WC-MED ONLY" = "State Workers Compensation-Med Only",
  "STATE WC-OCC DISEASE-OTHER THAN MED ONLY" = "State Workers Compensation-Occupational Disease-Other than Med Only",
  "STATE WC-OCC.DISEASE-MED ONLY" = "State Workers Compensation-Occupational Disease-Med Only",
  "STATE WC-OTHER THAN MED ONLY" = "State Workers Compensation-Other than Med Only",
  "STATE WC-OTHER THAN MED ONLY-AK, FL, HI, NY" = "State Workers Compensation-Other than Med Only - AK, FL, HI, NY",
  "STATE WC-OTHER THAN MED ONLY-CA" = "State Workers Compensation-Other than Med Only - CA",
  "STATE WC-OTHER THAN MED ONLY-TX" = "State Workers Compensation-Other than Med Only - TX",
  "STATE WORKERS COMPENSATION-SUBROGATION" = "State Workers Compensation-Subrogation",
  "SURETY BONDS" = "Surety Bonds",
  "USL&H-MED ONLY" = "USL&H-Med Only",
  "USL&H-OCCUPATIONAL DISEASE-CUMULATIVE TRAUMA" = "USL&H-Occupational Disease-Cumulative Trauma",
  "USL&H-OCCUPATIONAL DISEASE-MED ONLY" = "USL&H-Occupational Disease-Med Only",
  "USL&H-OCCUPATIONAL DISEASE-OTHER THAN MED ONLY" = "USL&H-Occupational Disease-Other than Med Only",
  "USL&H-OTHER THAN MED ONLY" = "USL&H-Other than Med Only",
  "UMBRELLA LIABILITY-BI" = "Umbrella Liability-BI",
  "UMBRELLA LIABILITY-PD" = "Umbrella Liability-PD",
  "VA ADMIN MISCELLANEOUS FEES" = "VA ADMIN MISCELLANEOUS FEES",
  "VENDORS SINGLE INTEREST" = "Vendors Single Interest",
  "WC ADMIN MISCELLANEOUS FEES" = "WC ADMIN MISCELLANEOUS FEES",
  "WC MED ONLY DOWNGRADED TO RECORD ONLY" = "WC Med Only Downgraded to Record Only",
  "WC STAND ALONE SUBROGATION" = "WC STAND ALONE SUBROGATION"
}
