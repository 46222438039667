/**
 * The following are the predefined formats that you can share
 * when creating a new instance of Date extension
 *
 * For other formats, please visit: https://date-fns.org/v2.14.0/docs/format
*/

export enum DateFormatEnum {
  shortDate = 'MM/dd/yyyy', // format2
  full      = "MM/dd/yyyyy hh:mm:ssaaaaa'm' zzz",  // format5
}
