import { IDcFieldDefinition } from '@dc/core';

import { ObjectValueChecker } from '@app/core/ccp/utils/object-value-checker';
import { PdfTable, ReplaceValueDefinition } from '../pdf-definitions';

const StatusIndicatorConfig = require('@assets/configs/status-indicator-config.json');
const beautifyHtml = require('js-beautify').html;
const htmlToPdfMake = require('html-to-pdfmake');

const SUCCESS_THRESHOLD = StatusIndicatorConfig.THRESHOLD.SUCCESS_THRESHOLD;
const WARNING_THRESHOLD = StatusIndicatorConfig.THRESHOLD.WARNING_THRESHOLD;

export class PdfConverterUtils {
  static generateStatusIndicator(maxValue: number, currentValue: number, displayOrientation: string, reversed = false) {
    let graphSpread = displayOrientation === 'FILL' ? 4 : 1;

    let percentage = (currentValue / maxValue) * 100;
    const progressWidth = !isFinite(percentage) || percentage < 1 ? 0 : percentage > 100 ? 100 : percentage;
    const successColor = '#79b700';
    const warningColor = '#79b700';
    const dangerColor = '#79b700';

    let lineColor: string;
    if (reversed) {
      lineColor = successColor;
      if (percentage >= SUCCESS_THRESHOLD) {
        lineColor = dangerColor;
      } else if (percentage >= WARNING_THRESHOLD && percentage < SUCCESS_THRESHOLD) {
        lineColor = warningColor;
      }
    } else {
      lineColor = dangerColor;
      if (percentage >= SUCCESS_THRESHOLD) {
        lineColor = successColor;
      } else if (percentage >= WARNING_THRESHOLD && percentage < SUCCESS_THRESHOLD) {
        lineColor = warningColor;
      }
    }

    const canvas = [
      {
        type: 'line',
        x1: 5, y1: 5,
        x2: 100  * graphSpread, y2: 5,
        lineWidth: 10,
        lineColor: '#eef2d7',
        lineCap: 'round'
      }
    ];

    if (progressWidth > 0) {
      canvas.push({
        type: 'line',
        x1: 5, y1: 5,
        x2: progressWidth * graphSpread, y2: 5,
        lineWidth: 10,
        lineColor: lineColor,
        lineCap: 'round'
      });
    }

    return { canvas, margin: [0, 0, 0, 10] };
  }

  static generateTable(contents: any[], widths?: any[], withHeader: boolean = false, noBorder?: boolean, id?: string) {
    const table: PdfTable = {} as PdfTable;

    table.id = id;
    table.widths = widths;

    if (withHeader) {
      table.headerRows = 1;
    }

    table.body = [...contents];

    const tableObject: any = {
      table,
    };

    if (noBorder !== undefined) {
      tableObject['layout'] = {
        defaultBorder: false
      };
    }

    return tableObject;
  }

  static generateHeader(text: string, withSeparator: boolean = true) {
    const headerText = { text: text, style: 'header' };
    const separator = { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 560, y2: 0, lineWidth: 1 }] };

    let header: any = headerText;
    if (withSeparator) {
      header = [
        headerText,
        separator,
        { text: ' ' }
      ];
    }

    return header;
  }

  static generateLabel(text: string, alignment: string = 'left', id?: string) {
    return { text: text, id: id, style: 'label', alignment: alignment };
  }

  static generateSubfield(text: any, alignment: string = 'left', id?: string) {
    return { text: text, id: id, style: 'subfield', alignment: alignment };
  }

  static generateText(text: any, alignment: string = 'left', id?: string) {
    return { text: text, id: id, style: 'p', alignment: alignment };
  }

  static generateSection(contents: any[], displayOnNextPage: boolean = false) {
    const id = displayOnNextPage ? 'displayOnNextPageSection' : 'section'; // to separate the normal section with the section that was forced to displayOnNextPage
    return {
      id: id,
      stack: [
        ...contents
      ]
    };
  }

  /**
   * Beautifies HTML texts and replaces some tags and values to conform
   * with the application's default styling
   * @param htmlText
   */
  static beautifyHtml(htmlText: string) {
    const textToFormat = beautifyHtml(htmlText);
    const replaceDef = [
      { regex: 'font-family\s*:((&quot;|&apos;)*([^\"\'&;]*))+', newValue: 'font-family: Roboto' },
      { regex: 'font-size:\\s*\\d*\\w*', newValue: '' },
      { regex: 'color:\\s*#000000', newValue: 'color: #1A1919' },
      { regex: '\\s\\s+', newValue: '' },
      { regex: '\\>\\s\\<', newValue: '><' },
      { regex: '\\<\\s+', newValue: '<' },
      { regex: '\\s+\\>', newValue: '>' }
    ];
    return this.replace(textToFormat, replaceDef);
  }

  /**
   * Allows single or multiple regex-newValue pair/s to replace originalValue
   * per provided regex and newValue
   * @param originalValue
   * @param replaceDef See ReplaceValueDefinition
   */
  static replace(originalValue: string, replaceDef: ReplaceValueDefinition | ReplaceValueDefinition[]) {
    let newValue = null;
    if (replaceDef instanceof Array) {
      replaceDef.forEach(key => {
        if (newValue === null) {
          newValue = originalValue.replace(new RegExp(key.regex, 'g'), key.newValue);
        } else {
          newValue = newValue.replace(new RegExp(key.regex, 'g'), key.newValue);
        }
      });
    } else {
      newValue = originalValue.replace(new RegExp(replaceDef.regex, 'g'), replaceDef.newValue);
    }
    return newValue;
  }

  /**
   * Converts string/HTML values to acceptable PDFMake document definition
   * @param data
   */
  static convertToDocumentDefinition(data: any) {
    const convertedData = JSON.stringify(htmlToPdfMake(data));
    // htmlToPdfMake automatically sets the margin
    // thus adding arbitrary spaces
    const replaceDef = [
      { regex: '\\"margin\\"\\:\\[\\d+\\,\\d+\\,\\d+\\,\\d+\\]', newValue: '\"margin\": 0' },
      { regex: '\\"marginBottom\\"\\:\\d+', newValue: '\"marginBottom\": 0' },
    ];
    return JSON.parse(PdfConverterUtils.replace(convertedData, replaceDef));
  }

  /**
   * Maps the dataPlaceHolder into a dataSource and appends
   * additionalData into the mainData.
   * @param mainData main data fetched on initial page load.
   * @param additionalData additional data fetched after the page load.
   */
  static mapAdditionalData(mainData: any, additionalData: any[]) {
    if (mainData.layout !== undefined) {
      let { sections } = mainData.layout;
      sections = sections.map(section => {
        const columns = section.columns.map(column => {
          const fields = column.fields.map((field: IDcFieldDefinition) => {
            if (field.hasOwnProperty('dataPlaceHolder')) {
              field = { ...field, dataSource: field.dataPlaceHolder };
              return field;
            }
            return field;
          });
          column = { ...column, fields: fields };
          return column;
        });
        section = { ...section, columns: columns };
        return section;
      });
      mainData = { ...mainData, layout: { ...mainData.layout, sections: [...sections] } };
    }
    return PdfConverterUtils.appendAdditionalData(mainData, additionalData);
  }

  /**
   * Appends additionalData into mainData.
   * @param mainData main data fetched on initial page load.
   * @param additionalData additional data fetched after the page load.
   */
  static appendAdditionalData(mainData: any, additionalData: any[]) {
    additionalData?.forEach((datum) => {
      const datumValue = datum[Object.keys(datum)[0]].values;
      if (!ObjectValueChecker.isEmpty(datumValue)) {
        mainData = {
          ...mainData,
          values: { ...mainData.values, ...datumValue }
        };
      }
    });
    return mainData;
  }

  /**
   * Converts local date and time into EST format.
   */
  static getESTDateTime() {
    const timeZone = 'EST';
    const estDateTime = new Date().toLocaleString('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
    const timeString = estDateTime.replace(',', '').toLowerCase();
    const pattern = /\s+(pm|am)/i;
    return `${timeString.replace(pattern,'$1')} ${timeZone}`;
  }
}
