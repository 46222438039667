import {
  Injectable
} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as Uuidv4 } from 'uuid';

import {
  SessionStorage
} from '../services/session-storage/session-storage.service';

@Injectable()
export class CorrelationInterceptor implements HttpInterceptor {
  constructor(private sessionStorage: SessionStorage) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Caller-Id': 'Claim Lookup App',
        'Issued-By': 'Claim Lookup App',
        'Request-Id': Uuidv4(),
        'Session-Id': this.sessionStorage.get('Session-Id')
      }
    });

    return next.handle(request);
  }
}
