import { BenefitStatusMapValueEnum, ReleaseStatusEnum, ReleaseStatusMapValueEnum, WorkStatusEnum, WorkStatusMapValueEnum } from "../enums/return-to-work.enum";

export class ReturnToWorkUtils {
  static mapReleaseStatus(value) {
    switch (value) {
      case ReleaseStatusEnum.WithRestrictions:
        return ReleaseStatusMapValueEnum.ReleasedWithRestrictions;
      case ReleaseStatusEnum.WithoutRestrictions:
        return ReleaseStatusMapValueEnum.ReleasedWithoutRestrictions;
      case ReleaseStatusEnum.ReleaseNotAddressed:
      case null:
      case '':
        return ReleaseStatusMapValueEnum.NoStatusFromDoctor;
      default:
        return value;
    }
  }

  static mapWorkStatus(value) {
    switch (value) {
      case WorkStatusEnum.OffWork:
        return WorkStatusMapValueEnum.OutOfWork;
      default:
        return value;
    }
  }

  private static isBenefitStatusEmpty(value: Array<object>) {
    if (value.length === 1 &&
      !value[0]['RTW_DISABILITY_TYPE_DESC'] &&
      !value[0]['RTW_DISABILITY_TYPE_ABBREV'] &&
      !value[0]['RTW_NUM_DAYS_IN_DISABILITY_TYPE']) {
      return true;
    }
    return false;
  }

  private static transformBenefitStatus(value: object) {
    return `${value['RTW_DISABILITY_TYPE_DESC']} (${value['RTW_DISABILITY_TYPE_ABBREV']})`;
  }

  static mapBenefitStatus(value: Array<object>): string {
    if (!this.isBenefitStatusEmpty(value)) {
      const mapValues = value.map((item) => {
        return this.transformBenefitStatus(item);
      });
      return mapValues.join(', ');
    }
    return BenefitStatusMapValueEnum.NotReceivingBenefits;
  }

  static mapCurrentInformation(data) {
    data['RTW_DOCTOR_RELEASE_TYPE_DESC'] = ReturnToWorkUtils.mapReleaseStatus(data['RTW_DOCTOR_RELEASE_TYPE_DESC']);
    if (data['RTW_DOCTOR_RELEASE_TYPE_DESC'] !== ReleaseStatusMapValueEnum.ReleasedWithRestrictions) {
      data['RTW_RESTRICTION_DESC'] = 'N/A';
    }

    data['RTW_WORK_STATUS_TYPE_DESC'] = ReturnToWorkUtils.mapWorkStatus(data['RTW_WORK_STATUS_TYPE_DESC']);

    data['RTW_DISABILITY_TYPE'] = ReturnToWorkUtils.mapBenefitStatus(data['RTW_DISABILITY_TYPE']);
    return data;
  }
}
