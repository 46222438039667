import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface Menu {
  state?: string;
  name: string;
  type: string;
  icon: string;
  routerLink?: string[];
  badge?: BadgeItem[];
  children?: Menu[];
}

const MENUITEMS = [
  {
    state: '/',
    name: 'Dashboard',
    type: 'link',
    icon: 'explore'
  }, {
    name: 'Claims',
    type: 'link',
    icon: 'how_to_vote',
    routerLink: ['/', 'search', 'claims']
  }
];

@Injectable()
export class MenuService {
  menuItems: Subject<Menu[]>;
  menuWidgetConfig: Subject<any>;

  constructor() {
    this.clearMenuItems();
    this.clearMenuWidget();
    this.setMenuItems(MENUITEMS);
  }

  setMenuItems(menuItems: Menu[]) {
    this.menuItems.next(menuItems);
  }

  clearMenuItems() {
    this.menuItems = new Subject();
  }

  setMenuWidget(widget: string, parameters: any) {
    const menuWidgetConfig = {
      widget: widget,
      parameters: parameters
    };

    this.menuWidgetConfig.next(menuWidgetConfig);
  }

  clearMenuWidget() {
    this.menuWidgetConfig = new Subject();
  }
}
