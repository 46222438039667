import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdsModalModule, CdsButtonModule } from '@cds/angular';
import {
  DcDataFieldModule, DcDialogBoxModule,
  DcShowingResultsModule, DcTextGroupModule
} from '@dc/components';

import { FormFieldComponent } from './form-field/form-field.component';


@NgModule({
  declarations: [
    FormFieldComponent
  ],
  exports: [
    FormFieldComponent,
    DcDataFieldModule,
    DcDialogBoxModule,
    DcShowingResultsModule,
    DcTextGroupModule
  ],
  imports: [
    CommonModule,
    CdsModalModule,
    CdsButtonModule
  ]
})
export class MoleculesModule {
}
