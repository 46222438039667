import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  DcDateModule, DcDateTimeModule, DcLinkModule,
  DcMailtoModule, DcMoneyModule, DcPageTitleModule,
  DcParagraphModule, DcSectionTitleModule,
  DcTextAreaModule, DcTextModule, DcTimeModule
} from '@dc/components';
import { DcPipesModule } from '@dc/core';

import { CurrencyInputComponent } from './input/currency-input/currency-input.component';

@NgModule({
  declarations: [
    CurrencyInputComponent,
  ],
  exports: [
    CurrencyInputComponent,

    DcDateModule,
    DcDateTimeModule,
    DcLinkModule,
    DcMailtoModule,
    DcMoneyModule,
    DcPageTitleModule,
    DcParagraphModule,
    DcSectionTitleModule,
    DcTextModule,
    DcTextAreaModule,
    DcTimeModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DcPipesModule
  ]
})
export class AtomsModule {
}
