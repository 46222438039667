import {
  Component,
  NgZone,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
  Inject
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

import { Observable, Subscription } from 'rxjs';
import { filter, delay } from 'rxjs/operators';

import { Screenfull } from 'screenfull';

import { CdsModalService, CdsModalConfig, CdsModalRef } from '@cds/angular';

import {
  SessionService,
  ClaimsLoadingService,
  ConfigService,
  UserInactivityWarningComponent,
  TimerService
} from '../ccp';
import { MenuService } from '../menu/menu.service';

import { User } from '../ccp/models/user/user';
import { AttributeEnum } from '../ccp/enums/attribute.enum';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';


const SMALL_WIDTH_BREAKPOINT = 960;
const chevronClientIds: number[] = require('@assets/data/chevron-client-ids.json');

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  private screenfull: Screenfull;
  private _router: Subscription;
  private user: User;
  private timeoutConfig: any;
  public currentYear: number = new Date().getFullYear();

  mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  options = {
    collapsed: false,
    collapsedHover: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };
  showMenu = false;

  @ViewChild('sideBarMenu', { static: false }) sideBarMenu: ElementRef;

  inactive$: Observable<any>;
  timerSubscription: Subscription;
  claimsLoadingSubscription: Subscription;
  userInactivityWarningDialog: CdsModalRef<UserInactivityWarningComponent>;
  loadingClaim: boolean;
  sideBarMenuScrollbarWidth: number;
  sideBarMenuWidth: number;
  activityTime: number;
  isDashboard: boolean;

  // TODO: Best practice - order must be no access modifiers, protected, public, private
  constructor(private zone: NgZone, // TODO: Remove if unused
              public router: Router,
              public sessionService: SessionService,
              public menuService: MenuService,
              public breakpointObserver: BreakpointObserver,
              public modalService: CdsModalService,
              private timer: TimerService,
              private configService: ConfigService,
              private claimsLoadingService: ClaimsLoadingService,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) {
    this.mediaMatcher.addEventListener('change', listener => {
      zone.run(() => {
        this.mediaMatcher = listener as any;
        this.options.collapsed = this.mediaMatcher.matches;
      });
    });
    this.activityTime = Date.now();
    this.configService.getConfig('timeout-config', './assets/configs/timeout-config.json')
      .subscribe(config => this.timeoutConfig = config);

    this.sessionService.getUser().subscribe((user: User) => this.user = user);
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    if (!this.modalService.getOpenModals().includes(this.userInactivityWarningDialog)) {
      this.activityTime = Date.now();
      this.startTimer();
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event:any) {
    if (event.data === 'resetTimer') {
      this.activityTime = Date.now();
      this.startTimer();
    }
  }

  ngOnInit() {
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        document.querySelector('.app-inner > .mat-drawer-content > div').scrollTop = 0;
        this.setIsDashboard();
      });
    this.menuService.menuItems
      .pipe(delay(0))
      .subscribe(menuItems => {
        this.showMenu = menuItems && (menuItems.length > 0);
      });
    if (this.breakpointObserver.isMatched([`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`])) {
      this.options.collapsed = true;
    }
    this.startTimer();
    this.claimsLoadingSubscription = this.claimsLoadingService.onLoadingChanged()
      .subscribe((loadingClaim) => {
        this.loadingClaim = loadingClaim;
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          if (!loadingClaim && !this.sideBarMenuScrollbarWidth) {
            this.sideBarMenuScrollbarWidth = this.getSideBarMenuScrollbarWidth();
          }

          if (!loadingClaim) {
            this.setSideBarMenuWidth();
          }
        }, 100);
      });
  }

  ngAfterViewInit() {
    this.setSideBarMenuWidth();
    this.getVerticalHeight();
    window.addEventListener('resize', this.onResize.bind(this));
    this.setIsDashboard();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this._router.unsubscribe();
    this.timerSubscription.unsubscribe();
    this.claimsLoadingSubscription.unsubscribe();
    window.removeEventListener('resize', this.setSideBarMenuWidth.bind(this));
  }

  startTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    const duration = this.environment.appConfig.userInactivityPollingInterval;

    this.inactive$ = this.timer.countSeconds(duration).pipe(filter(value => value === duration));

    const logout = () => {
      this.onLogOut();
    };

    const outputs: CdsModalConfig = {
      outputs: {
        logout
      }
    };

    this.timerSubscription = this.inactive$.subscribe({
      complete: () => {
        const timeDiff = (Date.now() - this.activityTime) / 1000;
        const clientAttribute = this.user.riskTechDataUserAttributes.getValuesByAttribute(AttributeEnum.Client) || [];
        const clientIdentifier: number[] = clientAttribute.map(client => client.ClientId);
        const chevronClientFlag = clientIdentifier.some(clientId => chevronClientIds.includes(Number(clientId)));
        const userInactivityTimeout = chevronClientFlag ? this.timeoutConfig.chevron : this.timeoutConfig.default;

        if (timeDiff > userInactivityTimeout) {
          this.userInactivityWarningDialog = this.modalService.openModal(UserInactivityWarningComponent, outputs);
        } else {
          this.startTimer();
        }
      }
    });
  }

  fullScreenToggle(): void {
    if (this.screenfull.enabled) {
      this.screenfull.toggle();
    }
  }

  hoverSidebar(state) {
    // Preventing the sidebar from expanding on hover
    // if (this.options.collapsed) {
    //   this.options.collapsedHover = state;
    // }
  }

  onLogOut() {
    this.sessionService.terminateSession();
  }

  toggleCollapsedSidebar() {
    this.options.collapsed = !this.options.collapsed;
    this.options.collapsedHover = false;
    this.changeDetectorRef.detectChanges();
    this.setSideBarMenuWidth();
  }

  onResize() {
    this.setSideBarMenuWidth();
    this.getVerticalHeight();
    setTimeout(() => {
      this.setSideBarMenuWidth();
    }, 400);
  }

  setSideBarMenuWidth() {
    if (this.options.collapsed) {
      if (this.sideBarMenu.nativeElement.scrollHeight > this.sideBarMenu.nativeElement.offsetHeight) {
        this.sideBarMenuWidth = 48 + this.sideBarMenuScrollbarWidth;
      } else {
        this.sideBarMenuWidth = 48;
      }
    } else {
      this.sideBarMenuWidth = null;
    }
    this.changeDetectorRef.detectChanges();
  }

  getVerticalHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vertical-height', `${vh}px`);
  }

  getSideBarMenuScrollbarWidth() {
    this.sideBarMenu.nativeElement.style.overflow = 'scroll';
    const scrollbarWidth = this.sideBarMenu.nativeElement.offsetWidth - this.sideBarMenu.nativeElement.clientWidth;
    this.sideBarMenu.nativeElement.style.overflow = 'auto';
    this.sideBarMenu.nativeElement.style.overflowX = 'hidden';
    return scrollbarWidth;
  }

  private setIsDashboard(): void {
    const url = this.router.routerState.snapshot.url;
    const dashboardPath = '/dashboard';
    this.isDashboard = url.startsWith(dashboardPath);
  }
}
