import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { A11yModule } from '@angular/cdk/a11y';


import {
  CdsLabelModule, CdsControlBlockModule,
  CdsLoadingSpinnerModule, CdsSelectModule,
  CdsInputModule
} from '@cds/angular';
import {
  DcTableModule, DcTableChildrenComponentsModule,
  DcSectionModule, DcStatusIndicatorModule
} from '@dc/components';

import { AtomsModule } from '../atoms/atoms.module';
import { MoleculesModule } from '../molecules/molecules.module';

import { CurrencyRangeComponent } from './currency-range/currency-range.component';

@NgModule({
  declarations: [
    CurrencyRangeComponent
  ],
  exports: [
    CurrencyRangeComponent,
    DcTableModule,
    DcTableChildrenComponentsModule,
    DcSectionModule,
    DcStatusIndicatorModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    AtomsModule,
    MoleculesModule,
    A11yModule,
    CdsLabelModule,
    CdsControlBlockModule,
    CdsSelectModule,
    CdsLoadingSpinnerModule,
    CdsInputModule
  ]
})
export class OrganismsModule {
}
