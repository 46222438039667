import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SessionService } from '../services/session/session.service';
import { EventType } from '../enums/event-type';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error) {
    this.injector.get(SessionService)
      .log(EventType.Error, 'Uncaught Error', '', error);
  }
}

export const GlobalErrorHandlerProvider = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler
};
