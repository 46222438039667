export class SessionStorageStub {
  storage = new Map<string, any>();

  get(key: string): string {
    return this.storage.get(key);
  }

  getJson(key: string): any {
    return JSON.parse(this.storage.get(key));
  }

  set(key: string, value: string) {
    this.storage.set(key, value);
  }

  setJson(key: string, value: any) {
    this.storage.set(key, JSON.stringify(value));
  }

  remove(key: string) {
    this.storage.delete(key);
  }
}
