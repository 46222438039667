export enum FunctionalRolesEnum {
  // RISK TECH DATA (RTD)
  GENERAL_ATTACHMENTS = "GENERAL_ATTACHMENTS",
  CLAIM_DIARIES = "CLAIM_DIARIES",
  MEDICAL_BILLS = "MEDICAL_BILLS",
  RESTRICTED_ATTACHMENTS = "RESTRICTED_ATTACHMENTS",
  PRIVACY_CLAIMS = "PRIVACY_CLAIMS",
  // CLIENT CONNECT PORTAL (CCP)
  CLAIM_OVERVIEW = "Claim_Overview",
  RTW_MODULE = "RTW_Module",
  QUICK_REPORTS = "Quick_Reports"
}
