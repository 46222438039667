import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { CdsButtonGroupModule, CdsButtonModule, CdsLoadingOverlayModule, CdsModalModule, CdsModalRef } from '@cds/angular';
import { DcDialogBoxModule } from '@dc/components';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

@Component({
  selector: 'app-quick-reports',
  templateUrl: './quick-reports.component.html',
  styleUrls: ['./quick-reports.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CdsLoadingOverlayModule,
    DcDialogBoxModule,
    CdsModalModule,
    CdsButtonModule,
    CdsButtonGroupModule
  ]
})

export class QuickReportsComponent implements OnInit {
  hidden = false;
  frame:any;
  loading: boolean = true;

  reportUrl: any;
  reportingAppUrl:any;

  constructor(public modalRef: CdsModalRef,
    private sanitizer: DomSanitizer,
    private element: ElementRef,
    @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) {
    const { reportType } = this.modalRef.data;
    let url = `${this.environment.appConfig.reportingAppUrl}/reportRequest/quick/${reportType}/selectAccount`;
    this.reportingAppUrl = `${this.environment.appConfig.reportingAppUrl}`;
    this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event:any) {
    if (event) {
      switch (event.data){
        case 'hide':
          this.hidden = true;
          this.frame.postMessage('stopTimer', this.reportingAppUrl);
          break;
        case 'show':
          this.hidden = false;
          this.frame.postMessage('resetTimer', this.reportingAppUrl);
          break;
        case 'close':
          this.modalRef.close();
          break;
        case 'removeBackground':
          const modalRef = this.element.nativeElement.querySelector(".cds-modal-container.-is-visible");
          modalRef.style.backgroundColor="transparent";
          break;
        case 'closeLoading':
          this.loading = false;
          break;
      }
    }
  }


  ngOnInit(){
    this.frame = document.querySelector("iframe").contentWindow;
  }

  cancel(){
    this.modalRef.close();
  }
}
