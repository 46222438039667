import {
  Input,
  Component,
  OnChanges,
  OnInit,
} from '@angular/core';

import {
  ClaimsLoadingService
} from '../../../services/claims-loading/claims-loading.service';

@Component({
  selector: 'app-quick-claim-info',
  templateUrl: './quick-claim-info.component.html'
})
export class QuickClaimInfoComponent implements OnInit, OnChanges {
  claimStatus: string;
  claimantName: string;
  claimNumber: string;
  coverageCode: string;
  totalIncurred: number;
  totalPaid: number;
  lossDate: Date;
  location: string;
  adjuster: string;
  officePhone: string;
  adjusterEmail: string;
  supervisorEmail: string;
  emailSubject: string;
  privacyIndicator: boolean;

  @Input()
    parameters: any;

  constructor(private claimsLoadingService: ClaimsLoadingService) {
  }

  ngOnInit() {
    this.claimsLoadingService.updateLoading(true);
  }

  ngOnChanges(changes) {
    this.claimsLoadingService.updateLoading(true);
    this.clearAll();
    this.extract(this.parameters);
    this.claimsLoadingService.updateLoading(false);
  }

  clearAll() {
    this.claimStatus = null;
    this.claimantName = null;
    this.claimNumber = null;
    this.coverageCode = null;
    this.totalIncurred = null;
    this.totalPaid = null;
    this.lossDate = null;
    this.location = null;
    this.adjuster = null;
    this.officePhone = null;
    this.adjusterEmail = null;
    this.supervisorEmail = null;
    this.emailSubject = null;
    this.privacyIndicator = null;
  }

  extract(results) {
    this.claimStatus     = results['CLAIM_STATUS']              || '';
    this.claimantName    = results['CLAIMANT_NAME']             || '';
    this.claimNumber     = results['CLAIM_NUMBER']              || '';
    this.coverageCode    = results['LINE_OF_BUSINESS']          || 'Unknown';
    this.totalIncurred   = results['RESERVE_INCURRED_TOTAL']    || '';
    this.totalPaid       = results['RESERVE_PAID_TOTAL']        || '';
    this.lossDate        = results['DATE_TIME_OF_EVENT']        || '';
    this.location        = results['LEVEL_8_NAME']             || '';
    this.adjuster        = `${results['ADJUSTER_FIRST_NAME']    || ''} ${results['ADJUSTER_LAST_NAME'] || ''}`.trim();
    this.officePhone     = results['ADJUSTER_OFFICE_PHONE']     || '';
    this.adjusterEmail   = results['ADJUSTER_EMAIL']            || '';
    this.supervisorEmail = results['ADJUSTER_SUPERVISOR_EMAIL'] || '';
    this.emailSubject    = `Claim # ${this.claimNumber} (${this.coverageCode}) ${this.claimantName}`;
    this.privacyIndicator = results['PRIVACY_INDICATOR'] ? true : false;
  }
}
