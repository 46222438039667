import { DcActionDefinition, DcFieldDefinition, IDcFieldDefinition } from '@dc/core';

import { FormControlDefinition } from './form-control-definition';

/**
 * @class FormDefinition(form: {@link FormDefinition}) Describes the layout of a page.
 *
 * @property controls - a list of controls to be displayed in the form.
 * @property initialization - actions to be taken when the form is initialized, typically calling a service
 * and updating the controls within the form. Any parameters needed by the form on initialization
 * should be provided in a 'params' object passed as an input to the dynamic form component.
 * @property submit - actions to be taken when the form is submitted, typically calling a service.
 */

export class FormDefinition {
  initialization?: DcActionDefinition [];
  submit: IDcFieldDefinition;
  controls: FormControlDefinition [];
  name: string;


  constructor(form: FormDefinition) {
    if (!form.name) {
      throw new Error('A name should be provided for this form.');
    }

    if (!form.submit) {
      throw new Error('Configuration should be provided for the Submit button of this form.');
    }

    if (!form.controls) {
      throw new Error('Form should contain at least one form control.');
    }

    this.name = form.name;
    this.submit = new DcFieldDefinition(form.submit);
    this.controls = form.controls
      .map(control => new FormControlDefinition(control));

    if (form.initialization) {
      this.initialization = form.initialization
        .map(action => new DcActionDefinition(action).get());
    }
  }
}
