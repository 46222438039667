import { HttpClient } from '@angular/common/http';

import { ClaimSearchService } from './claim-search.service';
import { ClaimSearchApiService } from './claim-search-api.service';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

export function ClaimSearchServiceFactory(httpClient: HttpClient, environment: AppEnvironment): ClaimSearchService {
  return new ClaimSearchApiService(httpClient, environment);
}

export const ClaimSearchServiceProvider = {
  provide: ClaimSearchService,
  useFactory: ClaimSearchServiceFactory,
  deps: [HttpClient, APP_ENVIRONMENT]
};
