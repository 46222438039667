import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdvancedQueryBuilder, ClaimSearchInput, ClaimSearchService, ClaimSearchSortDirection, SearchQueryUtils } from '../ccp';
import { SearchQueryOperator } from 'src/component-library';

@Component({
  selector: 'app-quick-claim',
  templateUrl: './quick-claim.component.html'
})
export class QuickClaimComponent {
  snapshot: any;
  loadingClaim: boolean;
  primaryLoadingMessage = 'Loading Claim Data';
  secondaryLoadingMessage = 'Wait a moment while we retrieve your claim data.';
  constructor(private _activatedRoute: ActivatedRoute, private _router: Router,  private claimSearchService: ClaimSearchService) {
    this.snapshot = _activatedRoute.snapshot;
    const claimSearchInput: ClaimSearchInput = {
      "Search": {
        "size": 2,
        "offset": 0,
        "columns": [
          "CLAIM_ID",
          "CLAIM_NUMBER",
          "LINE_OF_BUSINESS",
          "SOURCE_SYSTEM",
          "TPA_CODE"
        ],
        "sort": {
          "EVENT_ACC_DATE": ClaimSearchSortDirection.Ascending
        },
        "clauses": [
          {
            "wildcard": {
              "CLAIM_NUMBER.keyword": `*${this.snapshot.params.claimID}*`
            }
          }
        ]
      }
    };

    this.claimSearchService.getSearchResults(claimSearchInput).subscribe(results => {
      if (results.Search?.results.length === 1) {
        const claimInfo = results.Search.results[0];
        if (claimInfo.SOURCE_SYSTEM === 'DM') {
          this._router.navigate([
            `/claims/${claimInfo.SOURCE_SYSTEM}/${claimInfo.TPA_CODE}/${claimInfo.LINE_OF_BUSINESS}/${claimInfo.CLAIM_NUMBER}`
          ]);
        } else {
          this._router.navigate([
            `/claims/${results.Search.results[0].LINE_OF_BUSINESS}/${results.Search.results[0].CLAIM_NUMBER}/${results.Search.results[0].CLAIM_ID}`
          ]);
        }
      } else {
        const searchQuery = new AdvancedQueryBuilder()
          .addClaimNumber(
            'Claim Number',
            this.snapshot.params.claimID,
            SearchQueryOperator.Wildcard
          ).buildQuery();
        const query = SearchQueryUtils.encodeSearchQuery(searchQuery);

        const extras = {
          queryParams: { query: query }
        };
        this._router.navigateByUrl(this._router.createUrlTree(['/search/claims'], extras));
      }
    });
  }
}
