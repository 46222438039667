import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IAlert } from '../../components/alert/alert';

@Injectable()
export class AlertService implements OnDestroy {
  private alert = new Subject<IAlert[]>();

  constructor() { }

  public setAlerts(alert: IAlert[]) {
    this.alert.next(alert);
  }

  public getAlerts(): Observable<IAlert[]> {
    return this.alert.asObservable();
  }

  ngOnDestroy() {
    this.alert.complete();
  }
}
