import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable } from 'rxjs';

import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

import { EventType } from '../../enums/event-type';
import { SessionStorage } from '../session-storage/session-storage.service';
import { History } from '../history/history.service';

const ErrorCallsites = require('error-callsites');

export enum LoggingLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFORMATION = 'INFORMATION',
  EVENT = 'EVENT'
}

export class DatabaseLoggingResponse {
  userHistory: History[];
  userAttributes: any;
}

@Injectable()
export class LoggingService {

  styles: { [TKey in LoggingLevel]: string } = {
    [LoggingLevel.ERROR]: 'color: red; font-family: Monospace',
    [LoggingLevel.WARNING]: 'color: orange; font-family: Monospace',
    [LoggingLevel.INFORMATION]: 'color: blue; font-family: Monospace',
    [LoggingLevel.EVENT]: 'color: green; font-family: Monospace'
  };

  constructor(private title: Title,
              private httpClient: HttpClient,
              private sessionStorage: SessionStorage,
              @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) {
  }

  log(userid: string, eventType: EventType, message: string, eventData?: any, error?: Error, params?: Params): Observable<DatabaseLoggingResponse> {
    const loggingLevel = this.getLoggingLevel(eventType);

    if (!this.environment.appConfig.production) {
      this.logToConsole(loggingLevel, message, error);
    }
    return this.logToDatabase(userid, loggingLevel, message, eventType, eventData, error, params);
  }

  private logToConsole(loggingLevel: LoggingLevel, message: string, error: Error = null) {
    const style = this.styles[loggingLevel];

    console.info(`%c ${message}`, style);

    if (error) {
      if (error.stack) {
        console.info(`%c ${error.stack}`, style);
      } else {
        console.info(`%c ${error.name}: ${error.message}`, style);
      }
    }
  }

  private logToDatabase(userid: string, loggingLevel: LoggingLevel, message: string,
    eventType: EventType, eventData: any, error: Error = null, params?: Params): Observable<DatabaseLoggingResponse> {

    const callsite = this.getCallsite(error);
    const sessionId = this.sessionStorage.get('Session-Id');

    if (eventType == EventType.Successful_Login) {
      if (eventData) {
        eventData['viewPortWidth'] = window.innerWidth;
      } else {
        eventData = { viewPortWidth: window.innerWidth };
      }
    }

    if (params && (params['source'] === 'DM' && params['tpa'] !== null)) {
      eventData = params.claimNumber + "|" + params['tpa'];
      message = 'Dmitri_Claim_View';
    } else {
      eventData = eventData ? JSON.stringify(eventData) : null;
    };

    const event: any = {
      // NOTE:
      //  We will be removing the `loggingUnit` in a future pass,
      //  instead, the ClientConnect-papi will use the Request-Id / Session-Id
      //  headers associated with the request when logging to the database.
      //
      //  In the mean time, we use the `sessionId`.
      //                      - Brian Rowlett, 2020-08-03
      loggingUnit: sessionId || 'None',
      topLevelName: `CCP-${LoggingLevel[loggingLevel]}`,
      middleLevelName: callsite.getTypeName() ?? 'Unknown Class',
      bottomLevelName: callsite.getMethodName() ?? 'Unknown Method',
      message: message,
      machine: 'User Machine',
      userName: userid || 'None',
      eventTypeId: eventType,
      eventData: eventData,
      pageTitle: this.title ? this.title.getTitle() : '',
      applicationPath: window.location.href.substring(0, 5000),
      fileName: callsite.getFileName(),
      lineNumber: callsite.getLineNumber() || 0
    };

    if (error) {
      event.exceptions = [{
        message: error.message,
        type: error.name,
        source: callsite.getFunctionName(),
        stackTrace: error.stack
      }];
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.post<DatabaseLoggingResponse>(`${this.environment.appConfig.apiUrl}/log`, event, options);
  }

  private getCallsite(original: Error) {
    if (original) {
      const callsites = ErrorCallsites(original);
      if (callsites) {
        return callsites[0];
      }
    }

    return {
      getTypeName: () => 'Unknown Class',
      getMethodName: () => 'Unknown Method',
      getFileName: () => 'Unknown File',
      getLineNumber: () => 0,
      getColumnNumber: () => 0,
      getFunctionName: () => 'Unknown Source'
    };
  }

  private getLoggingLevel(eventType: EventType): LoggingLevel {
    eventType = eventType === EventType.Login_Failure ? EventType.Error : eventType;
    if ([EventType.Error, EventType.Warning, EventType.Information].indexOf(eventType) > -1) {
      return (<any>LoggingLevel)[(<any>EventType)[eventType].toUpperCase()];
    }
    return LoggingLevel.EVENT;
  }
}
