import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class FaviconService {
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument) { }

  setFavicon(icon: string) {
    if (!icon) {
      icon = 'Default';
    }

    this._document.getElementById('icon').setAttribute('href', '/assets/favicons/' + icon + '/favicon.ico');
    this._document.getElementById('apple57x57').setAttribute('href', '/assets/favicons/' + icon + '/apple-touch-icon-57x57.png');
    this._document.getElementById('apple114x114').setAttribute('href', '/assets/favicons/' + icon + '/apple-touch-icon-114x114.png');
    this._document.getElementById('apple72x72').setAttribute('href', '/assets/favicons/' + icon + '/apple-touch-icon-72x72.png');
    this._document.getElementById('apple144x144').setAttribute('href', '/assets/favicons/' + icon + '/apple-touch-icon-144x144.png');
    this._document.getElementById('apple60x60').setAttribute('href', '/assets/favicons/' + icon + '/apple-touch-icon-60x60.png');
    this._document.getElementById('apple120x120').setAttribute('href', '/assets/favicons/' + icon + '/apple-touch-icon-120x120.png');
    this._document.getElementById('apple76x76').setAttribute('href', '/assets/favicons/' + icon + '/apple-touch-icon-76x76.png');
    this._document.getElementById('apple152x152').setAttribute('href', '/assets/favicons/' + icon + 'apple-touch-icon-152x152.png');
    this._document.getElementById('png196x196').setAttribute('href', '/assets/favicons/' + icon + '/favicon-196x196.png');
    this._document.getElementById('png96x96').setAttribute('href', '/assets/favicons/' + icon + '/favicon-96x96.png');
    this._document.getElementById('png32x32').setAttribute('href', '/assets/favicons/' + icon + '/favicon-32x32.pn');
    this._document.getElementById('png16x16').setAttribute('href', '/assets/favicons/' + icon + '/favicon-16x16.png');
    this._document.getElementById('png128x128').setAttribute('href', '/assets/favicons/' + icon + '/favicon-128.png');
    this._document.getElementById('ms144x144').setAttribute('href', '/assets/favicons/' + icon + '/mstile-144x144.png');
    this._document.getElementById('ms70x70').setAttribute('href', '/assets/favicons/' + icon + '/mstile-70x70.png');
    this._document.getElementById('ms150x150').setAttribute('href', '/assets/favicons/' + icon + '/mstile-150x150.png');
    this._document.getElementById('ms310x150').setAttribute('href', '/assets/favicons/' + icon + '/mstile-310x150.png');
    this._document.getElementById('ms310x310').setAttribute('href', '/assets/favicons/' + icon + '/favicon.ico/mstile-310x310.png');
  }
}
