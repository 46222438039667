import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DcPage, DcSort } from '@dc/core';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import { SearchQueryType } from 'src/component-library';

export class ClaimSearchInput {
  [key: string]: ClaimSearchPayload;
}

export class ClaimSearchPayload {
  constructor(public size: number,
              public offset: number,
              public columns: string[],
              public sort: ClaimSearchSort,
              public clauses: any[]) {
  }
}

export class ClaimSearchSort {
  [key: string]: ClaimSearchSortDirection;
}

export enum ClaimSearchSortDirection {
  Ascending = 'asc',
  Descending = 'desc'
}

export class ClaimSearchOutput {
  [key: string]: ClaimSearchResponse;
}

export class ClaimSearchResponse {
  took: number;
  total: number;
  results: ClaimSearchResult[];
  aggregations: any;
}

export class ClaimSearchResult {
  CLAIM_ID: number;
  CLAIM_NUMBER: string;
  CLAIMANT_NAME: string;
  CLAIMANT_LAST_NAME: string;
  CLAIMANT_FIRST_NAME: string;
  EVENT_ACC_DATE: string; // Date, Moment?
  CLAIM_STATUS: string;
  CLAIM_TYPE: string;
  CURRENT_ADJUSTER: string;
  ADJUSTER_LAST_NAME: string;
  ADJUSTER_FIRST_NAME: string;
  PROGRAM_INFO: string;
  LINE_OF_BUSINESS: string;
  JURISDICTION: string;
  SOURCE: string;
  INSURED_DRIVER_NAME: string;
  TOTAL_PAID: number;
  TOTAL_OUTSTANDING: number;
  TOTAL_INCURRED: number;
  STATE_FEDERAL_NUMBER: string;
  POLICY_NUMBER: string;
  DATE_REPORTED: string;
  SOURCE_SYSTEM: string;
  TPA_CODE: string;
}

export enum sessionStorageKeys {
  claimValues = 'claimValues',
  sortValues = 'sortValues',
  pageValues = 'pageValues'
}

export abstract class ClaimSearchService {
  abstract getSearchResults(input: ClaimSearchInput): Observable<ClaimSearchOutput>;
  abstract getSearchSuggestions(input: ClaimSearchInput): Observable<ClaimSearchOutput>;
}

@Injectable({providedIn:'root'})
export class ClaimSearchStateService {
  browserRefresh: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh.next(!router.navigated);
      }
    });
  }

  setPageValues(pageValues: DcPage): void {
    sessionStorage.setItem(sessionStorageKeys.pageValues, JSON.stringify(pageValues));
  }

  getPageValues(): DcPage {
    return JSON.parse(sessionStorage.getItem(sessionStorageKeys.pageValues));
  }

  setSortValues(sortValues: DcSort): void {
    sessionStorage.setItem(sessionStorageKeys.sortValues, JSON.stringify(sortValues));
  }

  getSortValues(): DcSort {
    return JSON.parse(sessionStorage.getItem(sessionStorageKeys.sortValues));
  }

  setClaimValues(claimValues: any): void {
    sessionStorage.setItem(sessionStorageKeys.claimValues, JSON.stringify(claimValues));
  }

  getClaimValues(searchType: string): any {
    if (sessionStorage.getItem(sessionStorageKeys.claimValues)) {
      return searchType === SearchQueryType.Tokenized ? JSON.parse(sessionStorage.getItem(sessionStorageKeys.claimValues)).text : JSON.parse(sessionStorage.getItem(sessionStorageKeys.claimValues)).fields;
    } else {
      return null;
    }
  }

  clearSearchStateValues(): void {
    sessionStorage.removeItem(sessionStorageKeys.pageValues);
    sessionStorage.removeItem(sessionStorageKeys.sortValues);
    sessionStorage.removeItem(sessionStorageKeys.claimValues);
  }
}