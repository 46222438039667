import {
  of,
  Observable
} from 'rxjs';

import {
  map,
  mergeMap,
  shareReplay,
  switchMap
} from 'rxjs/operators';
import {
  Inject,
  Injectable
} from '@angular/core';
import {
  HttpClient, HttpHeaders
} from '@angular/common/http';

import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';

import {
  DataRequest,
  DataRequestUtils
} from './data-request';
import {
  DataResponse,
  DataResponseUtils
} from './data-response';
import {
  PrefetchResponse
} from './prefetch-response';
import {
  EndpointSpecification,
  NewKeySpecification
} from './specifications';
import {
  FileDownloaderService
} from '../../file-downloader.service';

@Injectable()
export class UniversalApiService {
  private serviceCache: { [key: string]: Observable<any> } = {};
  private keysSpecification: Observable<NewKeySpecification[]>;
  private endpointsSpecification: Observable<EndpointSpecification[]>;
  private baseUrl: string;

  constructor(private httpClient: HttpClient,
    private fileDownloader: FileDownloaderService,
    @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) {
    this.baseUrl = window.location.origin;
  }

  getAllData(key, fields, layout, source, parameters, callback = null) {
    key += this.encodeParameters(parameters);

    if (!this.serviceCache[key]) {
      fields = fields.concat(DataRequestUtils.getFieldsFromLayout(layout));
      DataRequestUtils.forEachTable(layout, field => {
        fields = fields.concat(DataRequestUtils.getFieldsFromTable(field));
      });

      const observable = this.getUniversalData(fields, parameters);

      if (callback) {
        observable
          .subscribe(results => callback(results));
      }

      return observable.pipe(map(results => {
        this.serviceCache[key] = of(results);
        return DataResponseUtils.arrange(results, layout, source.source_system);
      }));
    }

    return this.serviceCache[key].pipe(map(results => {
      return DataResponseUtils.arrange(results, layout, source.source_system);
    }));
  }

  getBlobData(key, file, field, source, parameters) {
    key += this.encodeParameters(parameters);

    if (!this.serviceCache[key]) {
      return this.getUniversalData([field], parameters).pipe(map(results => {
        const base64EncodedBlob = results[field][0];
        this.fileDownloader.saveBase64EncodedBlob(file, base64EncodedBlob);
        this.serviceCache[key] = of(results);
        return of({});
      }));
    }

    return this.serviceCache[key].pipe(map(results => {
      const base64EncodedBlob = results[field][0];
      this.fileDownloader.saveBase64EncodedBlob(file, base64EncodedBlob);
      return of({});
    }));
  }

  getSpoaBlobDownload(key, field, parameters) {
    key += this.encodeParameters(parameters);

    if (!this.serviceCache[key]) {
      return this.getUniversalData([field], parameters).pipe(map(results => {
        const base64EncodedBlob = results[field][0]['DOCUMENT'];
        const fileName = results[field][0]['DOCUMENT_FILENAME'];
        this.fileDownloader.saveBase64EncodedBlob(fileName, base64EncodedBlob);
        this.serviceCache[key] = of(results);
        return of({});
      }));
    }

    return this.serviceCache[key].pipe(map(results => {
      const base64EncodedBlob = results[field][0]['DOCUMENT'];
      const fileName = results[field][0]['DOCUMENT_FILENAME'];
      this.fileDownloader.saveBase64EncodedBlob(fileName, base64EncodedBlob);
      return of({});
    }));
  }

  getIsoDocumentBlobData(key, file, field, source, parameters) {
    key += this.encodeParameters(parameters);
    if (!this.serviceCache[key]) {
      return this.getUniversalData([field], parameters).pipe(map(results => {
        const base64EncodedBlob = results[field][0]['ISO_DOCUMENT'];
        this.fileDownloader.saveBase64EncodedBlob(file, base64EncodedBlob);
        this.serviceCache[key] = of(results);
        return of({});
      }));
    }
    return this.serviceCache[key].pipe(map(results => {
      const base64EncodedBlob = results[field][0]['ISO_DOCUMENT'];
      this.fileDownloader.saveBase64EncodedBlob(file, base64EncodedBlob);
      return of({});
    }));
  }

  getExtraData(key, fields, layout, source, parameters) {
    key += this.encodeParameters(parameters);

    if (!this.serviceCache[key]) {
      return this.getUniversalData(fields, parameters).pipe(map(results => {
        this.serviceCache[key] = of(results);
        return { ...source, ...DataResponseUtils.firstValue(results) };
      }));
    } else {
      return this.serviceCache[key].pipe(map(results => {
        return { ...source, ...DataResponseUtils.firstValue(results) };
      }));
    }
  }

  getTableData(key, table, layout, source, parameters): Observable<any[]> {
    let observable: Observable<any>;
    key += this.encodeParameters(parameters);

    if (!this.serviceCache[key]) {
      DataRequestUtils.findTable(layout, table, field => {
        const fields = DataRequestUtils.getFieldsFromTable(field);
        observable = this.getUniversalData(fields, parameters);
      });
    } else {
      return this.serviceCache[key].pipe(map(results => {
        return DataResponseUtils.zipperMerge(results);
      }));
    }

    return observable.pipe(map(results => {
      this.serviceCache[key] = of(results);
      return DataResponseUtils.zipperMerge(results);
    }));
  }

  getUniversalData(fields, parameters, cancellable = true) {
    const url = `${this.environment.appConfig.apiUrl}/dataRequest`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    if (cancellable === true) {
      options.headers = options.headers.append('cancellable', 'true');
    }

    return this.getPayload(fields, parameters).pipe(switchMap(body => {
      return this.httpClient.post<DataResponse>(url, body, options).pipe(
        map(results => this.extractResults(fields, parameters, results)));
    }));
  }

  getCachedUniversalData(key, fields, parameters, cancellable = false) {
    key += this.encodeParameters(parameters);

    if (!this.serviceCache[key]) {
      return this.getUniversalData(fields, parameters, cancellable).pipe(map(results => {
        this.serviceCache[key] = of(results);
        return results;
      }));
    } else {
      return this.serviceCache[key];
    }
  }

  startPrefetchingData(fields, parameters) {
    const url = `${this.environment.appConfig.apiUrl}/prefetchData`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.getPayload(fields, parameters).pipe(mergeMap(body => {
      return this.httpClient.post<PrefetchResponse>(url, body, options);
    }));
  }

  flushCache() {
    this.serviceCache = {};
  }

  private getKeys(): Observable<NewKeySpecification[]> {
    if (!this.keysSpecification) {
      // This is a temporary solution
      const url = `${this.baseUrl}/assets/data/keys-endpoints.json`;

      return this.keysSpecification = this.httpClient // Cache
        .get<NewKeySpecification[]>(url).pipe(shareReplay(1));

      // const url = `${environment.apiUrl}/specs/keys`;
      // const options = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json'
      //   })
      // };

      // return this.keysSpecification = this.httpClient // Cache
      //   .get<KeySpecification[]>(url, options).pipe(shareReplay(1));
    }

    return this.keysSpecification;
  }

  private getEndpoints(): Observable<EndpointSpecification[]> {
    if (!this.endpointsSpecification) {
      // This is a temporary solution
      const url = `${this.baseUrl}/assets/data/endpoints.json`;

      return this.endpointsSpecification = this.httpClient // Cache
        .get<EndpointSpecification[]>(url).pipe(shareReplay(1));

      // const url = `${environment.apiUrl}/specs/endpoints`;
      // const options = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json'
      //   })
      // };

      // return this.endpointsSpecification = this.httpClient // Cache
      //   .get<EndpointSpecification[]>(url, options).pipe(shareReplay(1));
    }

    return this.endpointsSpecification;
  }

  private getPayload(fields, parameters) {
    const isDmitriClaim = 'source_system' in parameters && parameters['source_system'] === 'DM';
    return this.getKeys().pipe(
      mergeMap((keys: NewKeySpecification[]) => {
        let body: DataRequest = {
          dataRequest: fields.map(field => {
            const possibleKeys = keys.find(key => key.Key === field);
            const parameterValues = {};

            if (!possibleKeys) {
              console.warn(`No endpoint was found for the key: ${field}`);
            } else {
              const possibleEndpoints = possibleKeys.Endpoints.filter(endpoint =>
                isDmitriClaim ? endpoint.Api === 'Dmitri-sapi' : endpoint.Api !== 'Dmitri-sapi'
              );
              possibleEndpoints.forEach(endpoint => {
                endpoint.Parameters.forEach(parameter => {
                  parameterValues[parameter.Key] = parameters[parameter.Key];
                });
              });
            }

            if (isDmitriClaim) {
              if (field.startsWith('RESERVE_')){
                parameterValues['type'] = 'Monthly';
              }

              if (field.startsWith('NOTE_')){
                if (fields.includes('NOTE_MEMO_TEXT')){
                  if (parameters['p_cl_prog_note_ids']){
                    parameterValues['NOTE_ID'] = parameters['p_cl_prog_note_ids'][0];
                    parameterValues['NOTE_LENGTH'] = null;
                  }
                } else if (!field.includes('NOTE_OF_TYPE_EXISTS')){
                  parameterValues['NOTE_LENGTH'] = 1000;
                }
              }
            }

            return {
              key: field,
              parameters: parameterValues
            };
          })
        };

        if (isDmitriClaim) {
          body['parameters'] = {
            'CLAIM_NUMBER': parameters['claim_number'],
            'source_system': parameters['source_system'],
            'tpa_code': parameters['tpa_code']
          };
        }

        return of(body);
      })
    );
  }

  private extractResults(fields, parameters, results) {
    const data = {};
    const errorsMap: Map<string, Map<string, any>> = new Map();

    for (const field of fields) {
      const response = results.dataResponse
        .find(value => value.key === field);

      if (!response || response.error) {
        this.manageResponseError(field, errorsMap, response);
      }

      data[field] = response.values;
    }

    if (errorsMap.values() && errorsMap.size > 0) {
      errorsMap.forEach((errorDetailsMap: Map<string, any>) => {
        errorDetailsMap.forEach((error: any) => {
          console.warn(
            `The following fields generated an error:\n${error.fields.join(', ')}`,
            `\n${error.statusCode}: ${error.reasonPhrase}`
          );
        });
      });
    }

    return data;
  }

  /**
   * This is to ensure that we're keeping all related fields together based on the endpoint
   * and error status code.
   *
   * @param field
   * @param errorsMap
   * @param response
   */
  private manageResponseError(field: string, errorsMap: Map<string, Map<string, any>>, response: any) {
    const statusCode = response.error.statusCode;
    const reasonPhrase = response.error.reasonPhrase;
    const newErrorDetails = {
      statusCode: statusCode,
      reasonPhrase: reasonPhrase,
      fields: [field]
    };

    if (errorsMap.has(response.path)) {
      const mapValue = errorsMap.get(response.path);
      if (!mapValue.has(statusCode)) {
        mapValue.set(statusCode, newErrorDetails);
      } else {
        mapValue.get(statusCode).fields.push(field);
      }

    } else {
      const errorDetailsMap: Map<string, any> = new Map();
      errorDetailsMap.set(statusCode, newErrorDetails);
      errorsMap.set(response.path, errorDetailsMap);
    }
  }

  private encodeParameters(parameters) {
    return `?data=${window.btoa(JSON.stringify(parameters))}`;
  }
}
