/******************************************************
 *         C L A S S   D E F I N I T I O N S          *
 ******************************************************/
export class PdfConfig {
  pageTitle?: string;
  contents?: any[];
  headerTitle?: string;
  subheader?: string;
  downloadDate?: string;
  claimantName?: string;
  claimNumber?: string;
  contentTitle?: string;
  url?: string;
}

export class ReplaceValueDefinition {
  regex: string;
  newValue: string;

  constructor(regex: string, newValue: string) {
    this.regex = regex;
    this.newValue = newValue;
  }
}
/******************************************************
*                 I N T E R F A C E S                 *
******************************************************/
export interface PdfTable {
  headerRows?: number;
  id?: string;
  width?: any;
  widths?: any[];
  body: any[];
  layout: any;
}

/**
 * @property displayOnNextPage - flag the determines whether to display the section on the next page or not
 * @property sectionName - the section name that will be used in lieu of the section name that was indicated in the component panel
 *                          e.g. overview-wc-panel.json
 * @property appendixNameKey - the key that is used to get the appendix name that will be used as the section name in lieu of the section
 *                          name that was indicated in the component panel e.g. overview-wc-panel.json
 */
export interface PdfCustomDataConfig {
  displayOnNextPage?: boolean;
  sectionName?: string;
  appendixNameKey?: string;
}
/******************************************************
 *                C O N S T A N T S                   *
 ******************************************************/
export const PdfTableLayout = {
  NO_BORDERS: 'noBorders',
  HEADER_LINE_ONLY: 'headerLineOnly',
  LIGHT_HORIZONTAL_LINES: 'lightHorizontalLines'
};
