import {
  Input,
  OnInit,
  Output,
  Component,
  EventEmitter,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';

import { FormDefinition } from '../../../classes/form-definition';
import { FormControlDefinition } from 'src/component-library/classes/form-control-definition';
import { SearchQueryOperator } from 'src/component-library/classes/abstract-search-query';
import { InputType } from 'src/component-library/classes/input-definition';
import { Subscription } from 'rxjs';
import { containsTwoDates, isValidDateRange, ssnFieldValidator } from './dynamic-form.validators';

import { FormOperator } from 'src/component-library';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  initialState: any;
  subscriptions: Subscription[] = [];

  textOperators: FormOperator[];
  dateOperators: FormOperator[];
  idTextOperators: FormOperator[];
  currencyOperators: FormOperator[];
  rangedDatepickers: { [control: string]: boolean } = { };

  minYear = 1950;
  maxYear = 2050;

  @Input() initialFormValues?: any;      // initial values for the form
  @Input() data = { };        // supplementary data for the form
  @Input() layout: FormDefinition;
  @Input() parameters = {};
  @Output() actions = new EventEmitter<any>();
  @Output() formStateChange = new EventEmitter<any>();

  @ViewChild('submitButton', { read: ElementRef, static: true }) submitButton: ElementRef;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.textOperators = [
      {
        label: 'Exact',
        key: SearchQueryOperator.Equal
      }, {
        label: 'Contains',
        key: SearchQueryOperator.Wildcard
      }
    ];

    this.idTextOperators = [
      {
        label: 'Exact',
        key: SearchQueryOperator.Equal
      }, {
        label: 'Last 4',
        key: SearchQueryOperator.Wildcard
      }
    ];

    this.dateOperators = [
      {
        label: 'Exact',
        key: SearchQueryOperator.Equal
      }, {
        label: 'Before',
        key: SearchQueryOperator.LessThan
      }, {
        label: 'After',
        key: SearchQueryOperator.GreaterThan
      }, {
        label: 'Range',
        key: SearchQueryOperator.Range
      }
    ];

    this.currencyOperators = [
      {
        label: 'Above',
        key: SearchQueryOperator.GreaterThan
      }, {
        label: 'Below',
        key: SearchQueryOperator.LessThan
      }, {
        label: 'Between',
        key: SearchQueryOperator.Range
      }, {
        label: 'Exact',
        key: SearchQueryOperator.Equal
      }
    ];
  }

  ngOnInit() {
    if (this.layout.initialization) {
      this.onAction(this.layout.initialization, this.data);
    }

    const controls = { };
    (this.layout.controls as Array<FormControlDefinition>).forEach(control => {
      if (control.showOperatorDropdown !== false) {
        control.showOperatorDropdown = true;
      }

      const operator = control.defaultOperator ?
        control.defaultOperator : SearchQueryOperator.Equal;
      const comparison = new UntypedFormControl(operator);

      let field;

      switch (control.type) {
        case (InputType.CheckboxGroup):
          field = new UntypedFormGroup(
            Object.assign({},
              ...(this.data[control.name] as Array<{ label: string, control: string, value?: boolean, disabled?: boolean }>)
                .map(option => ({ [option.control]: new UntypedFormControl(option.value ?? false) }))
            )
          );
          break;
        case (InputType.Datepicker):
          field = new UntypedFormGroup({
            startDate: new UntypedFormControl(null),
            endDate: new UntypedFormControl(null)
          });
          this.setDatepickerType(operator, control.name, field);
          const subscription = comparison.valueChanges.subscribe(selection => {
            this.setDatepickerType(selection, control.name, field);
          });
          this.subscriptions.push(subscription);
          break;
        case (InputType.Currency):
          field = new UntypedFormGroup({
            firstCurrency: new UntypedFormControl(null),
            secondCurrency: new UntypedFormControl(null)
          });
          if (comparison.value === 'Range') {
            control['isRange'] = true;
          } else {
            control['isRange'] = false;
          }
          const currencyComparisonSubscription = comparison.valueChanges.subscribe(selection => {
            if (selection === 'Range') {
              control['isRange'] = true;
            } else {
              control['isRange'] = false;
            }
          });
          this.subscriptions.push(currencyComparisonSubscription);
          break;
        default:
          field = new UntypedFormControl(null);
          break;
      }

      controls[control.name] = new UntypedFormGroup({
        field,
        // Do we want to disable the control if options cannot be loaded for the field (i.e. for Dropdowns)?
        // field: new FormControl({ value: null, disabled: !this.data[control.name] }),
        comparison
      });

      // Following if statement to be changed upon a different identification
      // key value
      if ((control.type as string) === 'IdText') {
        this.setClaimantSsnFieldValidator(controls[control.name], control);
      }
    });
    this.formGroup = this.formBuilder.group(controls);
    this.initialState = this.formGroup.value;

    if (this.initialFormValues) {
      this.formGroup.patchValue(this.initialFormValues);
    }

    const formStateSubscription = this.formGroup.valueChanges.subscribe(() => {
      this.emitFormState();
    });

    this.subscriptions.push(formStateSubscription);
    this.emitFormState();
  }

  emitFormState() {
    this.formStateChange.emit(this.formGroup);
  }

  getTextOperators(control: any) {
    return control.operators || this.textOperators;
  }

  getIdTextOperators(control: any) {
    return control.operators || this.idTextOperators;
  }

  getDateOperators(control: any) {
    return control.operators || this.dateOperators;
  }

  getCurrencyOperators(control: any) {
    return control.operators || this.currencyOperators;
  }

  setDatepickerType(operator, controlName: string, field: UntypedFormGroup) {
    field.clearValidators();
    switch (operator) {
      case ('Range'):
        this.rangedDatepickers[controlName] = true;
        break;
      default:
        this.rangedDatepickers[controlName] = false;
        break;
    }
  }

  getErrorMessages(control: AbstractControl, controlName: string): string[] {
    const errorMessages = [];
    const errors = control.errors;

    if (errors && control.touched) {
      const layoutControl = this.layout.controls.find(ctrl => ctrl.name === controlName);

      Object.keys(errors).forEach(errorKey => {
        if (layoutControl['errorMessages'].hasOwnProperty(errorKey)) {
          const errorMessage = layoutControl['errorMessages'][errorKey];
          errorMessages.push(errorMessage);
        }
      });
    }

    return errorMessages;
  }

  getDatePickerErrorMessages(control: AbstractControl): string[] {
    const startDate = control.get('startDate');
    const endDate = control.get('endDate');
    let errorMessages = [];
    const formGroupErrors = control.errors;
    const startDateErrors = startDate.errors;
    const endDateErrors = endDate.errors;

    if (formGroupErrors && control.touched) {
      this.manageDatePickerErrorMessages(errorMessages, formGroupErrors);
    }

    if (startDateErrors) {
      this.manageDatePickerErrorMessages(errorMessages, startDateErrors);
    }

    if (endDateErrors) {
      this.manageDatePickerErrorMessages(errorMessages, endDateErrors);
    }

    return errorMessages.filter((message, index) => errorMessages.indexOf(message) === index);
  }

  updateFieldValue(group, control, value) {
    (this.formGroup.controls[group] as UntypedFormGroup).controls[control].setValue(value);
  }

  submit() {
    this.focusSubmitButton();
    this.formGroup.markAllAsTouched();

    const formValue = { };
    this.layout.controls.forEach(control => {
      formValue[control.name] = this.formGroup.value[control.name];
      const name = control.name;
      const child: any = this.formGroup.get(name);
      const field = child.controls.field;

      if ((control.type as string) === InputType.Datepicker
          && this.formGroup.get(name).value.comparison === 'Range') {
        const datePickerField: UntypedFormGroup = (this.formGroup.get(name) as UntypedFormGroup);
        this.setDateRangePickerValidatorAtFormSubmit(datePickerField);
      }
      field.updateValueAndValidity();
    });

    if (this.formGroup.valid) {
      this.onAction(this.layout.submit.actions, formValue);
    }
  }

  clear() {
    this.formGroup.reset(this.initialState);
  }

  onAction(actions, source, callback = null) {
    source = source ? source : this.data;
    Object.keys(this.parameters).forEach(key => {
      source[key] = this.parameters[key];
    });

    const $event = {
      actions, source, component: this, callback
    };

    this.actions.emit($event);
  }

  onUpdate(updates: any[]) {
    updates.forEach(update => this.data[update['target']] = update['value']);
  }

  // @TODO: Hack for the currency range and currency input component.
  // Context:
  // 1. Hitting enter on an input field in a form creates a click event on the first button of type submit
  // in the form. The first button of type submit in this form is in the first comparator dropdown.
  // 2. Previously, a dropdown button would force a focus state when clicked by using the button.focus() method.
  // This was added to overcome Firefox/IE issues at the time, and has recently been removed.

  // Currency input component validation/formatting is triggered on blur, and when enter is hit,
  // item 1 above happens, then item 2, which results in the blurring of the currency input.
  // Likewise, currency range component validation takes the active document element into account, and hitting
  // enter triggers item 1, then item 2, which sets the active document element to the first dropdown button in the form.

  // Because item 2 no longer happens, a workaround to retain currency input validation is to manually set focus on any
  // button on the form - in this instance the submit button - when enter is pressed.
  focusSubmitButton() {
    if (this.submitButton) {
      (this.submitButton.nativeElement as HTMLButtonElement).querySelector('button').focus();
    }
  }

  private setClaimantSsnFieldValidator(claimantSsn: UntypedFormGroup, control: FormControlDefinition) {
    const claimantSsnField: AbstractControl = claimantSsn.get('field');
    const exactMaxLength = 9;
    const last4MaxLength = 4;
    const defaultPlaceholder = '000000000';
    const last4Placeholder = '0000';

    claimantSsnField.setValidators([ ssnFieldValidator, Validators.maxLength(9) ]);
    control['maxLength'] = exactMaxLength;
    control['placeholder'] = defaultPlaceholder;
    const subscription = claimantSsn.get('comparison').valueChanges.subscribe(selection => {
      if (selection === 'Wildcard') {
        claimantSsnField.setValidators([ ssnFieldValidator, Validators.maxLength(4) ]);
        control['maxLength'] = last4MaxLength;
        control['placeholder'] = last4Placeholder;
      } else {
        claimantSsnField.setValidators([ ssnFieldValidator, Validators.maxLength(9) ]);
        control['maxLength'] = exactMaxLength;
        control['placeholder'] = defaultPlaceholder;
      }
      claimantSsnField.markAsTouched();
      claimantSsnField.updateValueAndValidity();
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  // Meeting discussion between Tony D & Dave O determined this was a quick fix for
  // date picker validation while the team hashes out what/how a uniform validation would
  // look like & how to handle it.
  private setDateRangePickerValidatorAtFormSubmit(datePickerField: UntypedFormGroup): void {
    datePickerField.get('field').setValidators([ containsTwoDates, isValidDateRange ]);
    datePickerField.updateValueAndValidity();
  }

  private manageDatePickerErrorMessages(errorMessages: any[], datePickerErrorMessages: any): string[] {
    const datePickerErrorMessageRegistry = {
      CDS_DATE_INVALID_DAY: 'Please enter a valid day.',
      CDS_DATE_INVALID_MONTH: 'Please enter a valid month.',
      CDS_DATE_INVALID_YEAR: 'Please enter a valid year.',
      CDS_DATE_MISSING_DAY: 'Please enter a day.',
      CDS_DATE_MISSING_MONTH: 'Please enter a month.',
      CDS_DATE_MISSING_YEAR: 'Please enter a year.',
      CDS_DATE_DAY_NOT_NUMBER: 'Day should be a number.',
      CDS_DATE_MONTH_NOT_NUMBER: 'Month should be a number.',
      CDS_DATE_YEAR_NOT_NUMBER: 'Year should be a number.',
      CDS_DATE_MIN: `Please enter a year greater than ${this.minYear}.`,
      CDS_DATE_MAX: `Please enter a year less than ${this.maxYear}.`,
      startDateGreaterThanEndDate: 'Start date in range cannot be after end date.',
      missingStartDate: 'Missing start date.',
      missingEndDate: 'Missing end date.'
    };
    let errorMessage = 'Please enter a valid date.';
    if (datePickerErrorMessages.hasOwnProperty('CDS_DATE_DAY_NOT_NUMBER') &&
        datePickerErrorMessages.hasOwnProperty('CDS_DATE_MONTH_NOT_NUMBER') &&
        datePickerErrorMessages.hasOwnProperty('CDS_DATE_YEAR_NOT_NUMBER')) {
      errorMessages.push(errorMessage);
    } else {
      Object.keys(datePickerErrorMessages).forEach(errorKey => {
        if (datePickerErrorMessageRegistry.hasOwnProperty(errorKey)) {
          errorMessage = datePickerErrorMessageRegistry[errorKey];
          errorMessages.push(errorMessage);
        }
      });
    }
    return errorMessages;
  }
}
