
import { of as observableOf, Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';


export class ClaimStatus {
  CODE_ID: number;
  SHORT_CODE: string;
  CODE_DESC: string;
}

@Injectable()
export class ClaimStatusService {
  getClaimStatuses(): Observable<ClaimStatus[]> {
    // NOTE
    //  These values now flow all the way from the form control, through
    //  the ClaimSearchQueryBuilder and into the ClaimSearchQueryConverter.
    //  These values are ultimately what will get sent to the backend for a search.
    //
    //  THEY MUST MATCH THE DATABASE EXACTLY!
    //
    //  If we ever want to show a different value on the front end, we can do that by
    //  changing the `text` field in getClaimStatusOptions below, but the `value` field
    //  MUST remain as CODE_DESC!
    //                                                      - Brian Rowlett, 2019-10-24
    return observableOf([
      {
        CODE_ID: 10,
        SHORT_CODE: 'O',
        CODE_DESC: 'Open'
      }, {
        CODE_ID: 11,
        SHORT_CODE: 'C',
        CODE_DESC: 'Closed'
      }, {
        CODE_ID: 1732,
        SHORT_CODE: 'R',
        CODE_DESC: 'Reopened'
      }
    ]);
  }

  getClaimStatusOptions(): Observable<any> {
    return this.getClaimStatuses().pipe(map(claimStatuses =>
      claimStatuses.map(status => {
        return {
          label: status.CODE_DESC, // DON'T TOUCH ME!
          control: status.SHORT_CODE
        };
      })
    ));
  }
}
