import { DcFieldTypeEnum, DcSort, DcSortDirectionEnum } from '@dc/core';

import {
  SearchQueryField,
  SearchQueryOperator
} from 'src/component-library/classes/abstract-search-query';

import { ClaimSearchQuery } from '../base-classes/claim-search-query';
import { ClaimSearchSortDirection } from '../services/http/claim-search/claim-search.service';

import { DynamicFormQueryConverter } from './dynamic-form-query-converter';

export class ClaimSearchQueryConverter {
  static convertToElasticsearchQuery(query: ClaimSearchQuery) {
    return query.fields.map(field => {
      return this.convertToElasticsearchField(field);
    });
  }

  private static convertToElasticsearchField(field: SearchQueryField) {
    if (field.type === DcFieldTypeEnum.Text) {
      if (field.operator === SearchQueryOperator.Equal) {
        return {
          'term': {
            [field.key]: field.values[0]
          }
        };
      } else if (field.key === 'CLAIMANT_TAX_ID.keyword' && field.operator === SearchQueryOperator.Wildcard) {
        return {
          'wildcard': {
            [field.key]: `*${field.values[0]}`
          }
        };
      } else if (field.operator === SearchQueryOperator.NotEqual) {
        return {
          'bool': {
            'must_not': [
              {
                'term': {
                  [field.key]: field.values[0]
                }
              }
            ]
          }
        };
      } else if (field.operator === SearchQueryOperator.Prefix) {
        return {
          'prefix': {
            [field.key]: field.values[0]
          }
        };
      } else if (field.operator === SearchQueryOperator.Excludes) {
        return {
          'bool': {
            'must_not': [
              {
                'terms': {
                  [field.key]: field.values
                }
              }
            ]
          }
        };
      } else if (field.operator === SearchQueryOperator.Includes) {
        return {
          'terms': {
            [field.key]: field.values
          }
        };
      } else if (field.operator === SearchQueryOperator.Wildcard) {
        return {
          'wildcard': {
            [field.key]: `*${field.values[0]}*`
          }
        };
      } else if (field.operator === SearchQueryOperator.Nested) {
        return {
          'nested': {
            'path': field.key,
            'query': {
              'bool': {
                'must': field.values.map(value => {
                  return this.convertToElasticsearchField(value);
                })
              }
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.ConditionalOr) {
        return {
          'bool': {
            'should': field.values.map(value => {
              return this.convertToElasticsearchField(value);
            })
          }
        };
      }
    } else if (field.type === DcFieldTypeEnum.Date) {
      if (field.operator === SearchQueryOperator.Equal) {
        return {
          'range': {
            [field.key]: {
              'gte': this.formatDate(field.values[0], '00:00:00'),
              'lte': this.formatDate(field.values[0], '23:59:59')
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.LessThan) {
        return {
          'range': {
            [field.key]: {
              'lt': this.formatDate(field.values[0], '00:00:00')
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.GreaterThan) {
        return {
          'range': {
            [field.key]: {
              'gt': this.formatDate(field.values[0], '23:59:59')
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.LessThanEqual) {
        return {
          'range': {
            [field.key]: {
              'lte': this.formatDate(field.values[0], '23:59:59')
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.GreaterThanEqual) {
        return {
          'range': {
            [field.key]: {
              'gte': this.formatDate(field.values[0], '00:00:00')
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.Range) {
        return {
          'range': {
            [field.key]: {
              'gte': this.formatDate(field.values[0], '00:00:00'),
              'lte': this.formatDate(field.values[1], '23:59:59')
            }
          }
        };
      }
    } else if (field.type === DcFieldTypeEnum.Money) {
      if (field.operator === SearchQueryOperator.Equal) {
        return {
          'term': {
            [field.key]: this.formatCurrency(field.values[0])
          }
        };
      } else if (field.operator === SearchQueryOperator.LessThan) {
        return {
          'range': {
            [field.key]: {
              'lt': this.formatCurrency(field.values[0])
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.GreaterThan) {
        return {
          'range': {
            [field.key]: {
              'gt': this.formatCurrency(field.values[0])
            }
          }
        };
      } else if (field.operator === SearchQueryOperator.Range) {
        return {
          'range': {
            [field.key]: {
              'gte': this.formatCurrency(field.values[0]),
              'lte': this.formatCurrency(field.values[1])
            }
          }
        };
      }
    }

    throw new Error(`No converter for field... type: ${field.type}, operator: ${field.operator}.`);
  }

  static convertToElasticsearchSort(sort: DcSort) {
    let sortDirection = null;
    switch (sort.sortDirection) {
      case DcSortDirectionEnum.None:
      case DcSortDirectionEnum.Ascending: {
        sortDirection = ClaimSearchSortDirection.Ascending;
        break;
      }
      case DcSortDirectionEnum.Descending: {
        sortDirection = ClaimSearchSortDirection.Descending;
        break;
      }
    }

    return {
      [sort.sortProperty]: sortDirection
    };
  }

  private static formatDate(dateToFormat: any, time: string) {
    /**
     * Firefox date format fix https://support.mozilla.org/en-US/questions/1271450
     * String date with '-' will throw error in Firefox
     * It should be replaced with '/'
     */
    let date: Date = new Date();
    if (dateToFormat !== undefined) {
      const valueToFormat = `${dateToFormat}`.replace(/-/g, '/');
      date = new Date(valueToFormat);
    }
    return `${date.format('yyyy-MM-dd')}T${time}`;
  }

  private static formatCurrency(currency: string) {
    return parseFloat(currency.replace(/[^0-9.-]+/g, ''));
  }

  static convertToDynamicFormGroup(query: ClaimSearchQuery) {
    return new DynamicFormQueryConverter().convertQuery(query);
  }
}
