export enum ReleaseStatusEnum {
  WithRestrictions = "With Restrictions",
  WithoutRestrictions = "Without Restrictions",
  ReleaseNotAddressed = "Release Not Addressed"
}

export enum ReleaseStatusMapValueEnum {
  ReleasedWithRestrictions = "Released With Restrictions",
  ReleasedWithoutRestrictions = "Released Without Restrictions",
  NoStatusFromDoctor = "No Status From Doctor"
}

export enum ReleaseStatusOptionEnum {
  ReleasedWithRestrictions = "Released With Restrictions",
  ReleasedWithoutRestrictions = "Released Without Restrictions",
  NotReleasedFullyRestricted = 'Not Released/Fully Restricted',
  NoStatusFromDoctor = "No Status From Doctor"
}

export enum ReleaseStatusCodeEnum {
  WithRestrictions = 2003056,
  WithoutRestrictions = 2003057,
  NotReleasedFullyRestricted = 2003283,
  ReleaseNotAddressed = 2003284,
  Empty = 0
}

export enum WorkStatusEnum {
  OffWork = "Off Work"
}

export enum WorkStatusMapValueEnum {
  OutOfWork = "Out of Work"
}

export enum WorkStatusOptionEnum {
  FullDuty = 'Full Duty',
  ModifiedDuty = 'Modified Duty',
  OutOfWork = "Out of Work",
  TerminatedResigned = 'Terminated/Resigned',
  RefusedToWork = 'Refused to Work',
}

export enum WorkStatusCodeEnum {
  FullDuty = 2003051,
  ModifiedDuty = 2003052,
  OffWork = 2003053,
  TerminatedResigned = 2003054,
  RefusedToWork = 2003055
}

export enum BenefitStatusMapValueEnum {
  NotReceivingBenefits = "Not Receiving Benefits"
}

export enum BenefitStatusOptionEnum {
  TemporaryTotalDisability = 'Temporary Total Disability (TTD)',
  TemporaryPartialDisability = 'Temporary Partial Disability (TPD)',
  PermanentPartialDisability = 'Permanent Partial Disability (PPD)',
  NotReceivingBenefits = 'Not Receiving Benefits'
}

export enum BenefitStatusCodeEnum {
  TemporaryTotalDisability = 'TTD',
  TemporaryPartialDisability = 'TPD',
  PermanentPartialDisability = 'PPD',
  Empty = ''
}
