import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CdsModalService } from '@cds/angular';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { APP_ENVIRONMENT, AppEnvironment } from '@assets/types/config.type';
import { UserAttributeIdentifierEnum } from '../ccp/enums/user-attribute-identifier.enum';
import { FunctionalRolesEnum } from '../ccp/enums/functional-roles.enum';
import { SessionService } from '../ccp';
import { User } from '../ccp/models/user/user';
import { CookieCheckerUtils } from '../ccp/utils/cookie-checker-utils';
import { QuickReportsComponent } from './quick-reports/quick-reports.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  open = false;
  options = [
    {
      "key": "lossrun",
      "label": "Loss Run"
    },
    {
      "key" : "paymentdetails",
      "label" : "Payment Detail"
    }
  ];
  name = 'quickReports';
  quickReportsAllowed = false;
  quickReportType: FormControl;
  @Output() logout = new EventEmitter<void>();
  @Output() toggleSidenav = new EventEmitter<void>();
  subs: Subscription[] = [];
  cookiesEnabled: boolean;
  cookiesUrl: any;
  frame: any;

  @HostListener('window:message', ['$event'])
  onMessage(event:any) {
    if (event.data === 'CCP:3rdPartyCookieNotSupported') {
      this.cookiesEnabled = false;
    } else if (event.data === 'CCP:3rdPartyCookieSupported') {
      this.cookiesEnabled = true;
    }
    if (this.frame.parentNode){
      this.frame.parentNode.removeChild(this.frame);
    }
  }

  constructor(private modalService: CdsModalService,
              public sessionService: SessionService,
              private sanitizer: DomSanitizer,
              @Inject(APP_ENVIRONMENT) private environment: AppEnvironment) {
    this.quickReportType = new FormControl(null, { updateOn: 'submit' });
    const getUsrSub = this.sessionService.getUser().pipe(
      take(1))
      .subscribe((user: User) => {
        this.quickReportsAllowed = user.hasAccessToFunction(UserAttributeIdentifierEnum.CCP, FunctionalRolesEnum.QUICK_REPORTS) ? true : false;
      });
    this.cookiesUrl = this.sanitizer.bypassSecurityTrustResourceUrl(CookieCheckerUtils.getCookieCheckerUrl(this.environment.appConfig));
    this.subs.push(getUsrSub);
  }

  ngOnInit(){
    this.frame = document.querySelector("iframe");
  }

  selectQuickReport(reportType) {
    if (reportType) {
      this.launchQuickReports(reportType);
    }
    setTimeout(() => {
      this.quickReportType.reset();
    });
  }

  launchQuickReports(reportType) {
    const config = {
      inputs: { reportType }
    };
    if (this.cookiesEnabled) {
      this.modalService.openModal(QuickReportsComponent, config);
    } else {
      window.open(`${this.environment.appConfig.reportingAppUrl}/reportRequest/quick/${reportType}/selectAccount`);
    }
  }

  onLogOut() {
    this.logout.emit();
  }

  onDestroy():void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
