import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import {
  DcNumericSortComparator, DcTextSortComparator,
  DcSortDirectionEnum, DcSortUtils, DcSort
} from '@dc/core';

import { ConfigService, HistoryService } from '../..';
import { EventType } from '../../enums/event-type';

@Injectable()
export class ClaimSearchExportService {

  private sort: DcSort = {
    sortDirection: DcSortDirectionEnum.Ascending,
    sortProperty: 'header'
  };

  private config: any = {};
  private cachedConfig: any = {};
  private selectedColumns: any[];

  constructor(private configService: ConfigService,
              private historyService: HistoryService,
              private translate: TranslateService) {
    // TODO: Once we're ready to implement the retrieval of the user-specific configuration, remove
    // the following line and the method it invokes
    this.fetchDefaultConfig();
    // TODO: Enable the following line once we're ready to implement the retrieval of the user-specific configuration
    // this.initializeClaimSearchExportConfig();
  }

  // TODO: Once we're ready to implement the retrieval of the user-specific configuration, remove
  // the following method.
  fetchDefaultConfig() {
    this.configService.getConfig('claim-export-config', './assets/configs/claim-search-export-default-config.json')
      .subscribe(config => {
        // Translate the column headers first
        config.columns = this.translateColumnHeaders(config.columns);
        this.config = config;
      });
  }

  /**
   * This method will handle the retrieval of the cached config or the user-specific configuration
   * from the server-side. If the user doesn't have any existing config, the default configuration
   * in the assets/config folder shall be used.
   */
  initializeClaimSearchExportConfig() {
    if (this.cachedConfig.columns) {
      this.config = this.cachedConfig;
    } else {
      this.historyService.getCachedHistory('Recent', EventType.Search_Export, 1)
        .pipe(map(history => {
          const eventData = history[0] && history[0]['eventData'] ? history[0]['eventData'] : null;

          if (eventData) {
            // This is to perform deep parsing since doing it once will produce a string object instead of an array
            const parsedEventData = JSON.parse(JSON.parse(eventData));

            if (parsedEventData && parsedEventData['columns'].length > 0) {
              this.config = {
                displayDefaultOrder: false,
                // Translate the column headers first
                columns: this.translateColumnHeaders(parsedEventData['columns'])
              };
            }
          }

          return history;
        }))
        .subscribe(() => {
          // Use the default config only when the logged in user has no existing claim export config
          if (!this.config.columns) {
            this.configService.getConfig('claim-export-config', './assets/configs/claim-search-export-default-config.json')
              .subscribe(config => {
                // Translate the column headers first
                config.columns = this.translateColumnHeaders(config.columns);
                this.config = config;
              });
          }
        });
    }
  }

  /**
   * In the event that the user completed the export, the user-selected columns and their order
   * must be kept.
   */
  cacheConfigChanges() {
    this.cachedConfig = this.config;
    this.cachedConfig.columns = this.getColumnsForExport();
  }

  changeColumnPosition(key: string, direction: string) {
    // First, get our column to move
    const modifiedColumn = this.selectedColumns.find(column => column.key === key);

    // Next, figure out the column to swap with
    let swapPosition = null;
    if (direction === 'up') {
      swapPosition = modifiedColumn.position - 1;
    } else {
      swapPosition = modifiedColumn.position + 1;
    }

    // Finally, do the swap
    const swapColumn = this.selectedColumns.find(column => column.position === swapPosition);

    swapColumn.position = modifiedColumn.position;
    modifiedColumn.position = swapPosition;
  }

  displayDefaultOrder(): boolean {
    return this.config.displayDefaultOrder;
  }

  getColumns(): any[] {
    const columns = this.config.columns.filter(column => !column.hidden);
    const comparator = this.getComparator('header');

    return DcSortUtils.sort(comparator, this.sort, columns);
  }

  getColumnsForExport(): any[] {
    const columnsForExport = this.getColumns();

    return columnsForExport.map(column => {
      const selectedColumn = this.selectedColumns.find(c => c.key === column.key);
      column.position = !selectedColumn ? 999 : selectedColumn.position;
      return column;
    });
  }

  getComparator(sortField: string): any {
    let comparator = null;

    if (sortField === 'position') {
      comparator = new DcNumericSortComparator('position');
      this.sort.sortProperty = 'position';
    } else if (sortField === 'defaultPosition') {
      comparator = new DcNumericSortComparator('defaultPosition');
      this.sort.sortProperty = 'defaultPosition';
    } else {
      comparator = new DcTextSortComparator('header');
      this.sort.sortProperty = 'header';
    }

    return comparator;
  }

  getSelectedColumns(sortField?: string): any[] {
    if (sortField) {
      const comparator = this.getComparator(sortField);
      return DcSortUtils.sort(comparator, this.sort, this.selectedColumns);
    } else {
      return this.selectedColumns;
    }
  }

  resetColumnPositions() {
    // Check for the preexisting order, if none, load the default order
    if (this.selectedColumns.filter((column) => column.position < 999).length > 0) {
      const comparator = this.getComparator('position');
      this.selectedColumns = DcSortUtils.sort(comparator, this.sort, this.selectedColumns);
    } else {
      const comparator = this.getComparator('defaultPosition');
      this.selectedColumns = DcSortUtils.sort(comparator, this.sort, this.selectedColumns);
    }

    this.selectedColumns = this.selectedColumns.map((column, index) => {
      column.position = index;
      return column;
    });
  }

  setSelectedColumns(formControls: any) {
    this.selectedColumns = [];

    for (const key of Object.keys(formControls)) {
      if (key !== 'all' && formControls[key].value) {
        this.selectedColumns.push(this.getColumns().find(column => column.key === key));
      }
    }
  }

  private translateColumnHeaders(columns: any[]) {
    return columns.map(column => {
      column['header'] = this.translate.instant(`export_columns.${column.key}`);
      return column;
    });
  }
}
