import { DcFieldTypeEnum } from '@dc/core';


export abstract class AbstractSearchQuery {
  text = '';
  fields: SearchQueryField[] = [];

  constructor(public type: SearchQueryType) {
  }
}

export enum SearchQueryType {
  Advanced = 'Advanced',
  Tokenized = 'Tokenized'
}

export class SearchQueryField {
  key?: string;
  name?: string;
  type: DcFieldTypeEnum;
  values?: any[];
  operator: SearchQueryOperator;

  constructor(other: SearchQueryField) {
    this.key = other.key;
    this.name = other.name;
    this.type = other.type;
    this.values = other.values;
    this.operator = other.operator;
  }
}

export enum SearchQueryOperator {
  Equal = 'Equal',
  Range = 'Range',
  Nested = 'Nested',
  Prefix = 'Prefix',
  Excludes = 'Excludes',
  Includes = 'Includes',
  NotEqual = 'NotEqual',
  Wildcard = 'Wildcard',
  LessThan = 'LessThan',
  GreaterThan = 'GreaterThan',
  LessThanEqual = 'LessThanEqual',
  GreaterThanEqual = 'GreaterThanEqual',
  ConditionalOr = 'ConditionalOr'
}
