import {
  History,
  HistoryService
} from '../../services/history/history.service';

import { EventType } from '../../enums/event-type';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class SearchbarHistoryResult {
  recentClaimSearchHistory: History[];
  recentClaimViewHistory: History[];
  frequentClaimViewHistory: History[];
  private $cancelSubscriptions = new Subject<void>();

  historyExists = () => {
    return ((this.recentClaimSearchHistory && this.recentClaimSearchHistory.length > 0)
      || (this.recentClaimViewHistory && this.recentClaimViewHistory.length > 0)
      || (this.frequentClaimViewHistory && this.frequentClaimViewHistory.length > 0));
  };

  constructor(private historyService: HistoryService) {
    this.historyService.getCachedHistory('Recent', EventType.Claim_Search, 1)
      .pipe(takeUntil(this.$cancelSubscriptions))
      .subscribe(history => {
        this.recentClaimSearchHistory = history;
      });

    this.historyService.getCachedHistory('Recent', EventType.Claim_View, 3)
      .pipe(takeUntil(this.$cancelSubscriptions))
      .subscribe(history => {
        this.recentClaimViewHistory = history;
      });

    this.historyService.getCachedHistory('Frequent', EventType.Claim_View, 3)
      .pipe(takeUntil(this.$cancelSubscriptions))
      .subscribe(history => {
        this.frequentClaimViewHistory = history;
      });
  }

  cancelSubscriptions() {
    this.$cancelSubscriptions.next();
  }
}
