export class ObjectValueChecker {
  static isEmpty(object: any) {
    return object === undefined || object === null || ((typeof object === 'string') && this.isEmptyString(object as string));
  }

  static isPropertyEmptyOrNotExisting(object: any, key: string) {
    return !object.hasOwnProperty(key) || this.isEmpty(object[key]);
  }

  private static isEmptyString(object: string) {
    return object === '' || ((object !== undefined  || object !== null) && object.trim() === '');
  }
}
