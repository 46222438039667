import { Injectable } from '@angular/core';
import { Aggregations, BaseQuery, BucketAggregationsSchema } from 'open-search-query-builder';
import { BehaviorSubject, Observable } from 'rxjs';

export class ClaimType {
  LABEL: string;
  KEY: string;
}

@Injectable()
export class ClaimTypeService {
  private claimTypes$ = new BehaviorSubject<ClaimType[]>(null);

  setClaimTypes(claimTypes: ClaimType[]) {
    this.claimTypes$.next(claimTypes);
  }

  getClaimTypes(): Observable<ClaimType[]> {
    return this.claimTypes$;
  }

  getClaimTypeQuery() {
    const query = new BaseQuery();
    query.addProp('size', 0);
    query.addProp('clauses', []);
    query.addProp('aggregations', new Aggregations<BucketAggregationsSchema>()
      .addProp(
        'terms',
        { field: 'CLAIM_TYPE.CODE_DESCRIPTION.keyword', size: 300 },
        { identifier: 'CLAIM_TYPES' }
      )
    );

    return { Search: query.generate() };
  }
}
