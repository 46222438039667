import { InjectionToken } from '@angular/core';

export const APP_ENVIRONMENT = new InjectionToken('APP_ENVIRONMENT');

export type AppEnvironment = {
  auth0ClientConfig: Record<string, any>;
  authConfig: Record<string, any>;
  appConfig: AppConfig;
};

export type AppConfig<T ={}> = {
  production: boolean;
  demo: boolean;
  qa: boolean;
  var1: string;
  var2: string;
  cookieCheckerUrl1: string;
  cookieCheckerUrl2: string;
  apiUrl: string;
  userInactivityTimeout: number;
  userInactivityPollingInterval: number;
  thirdPartyCookiesUrl: string;
  reportingAppUrl: string;
} & T;
