import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MessageBusService {
  private topics: { [key: string]: Subject<any> } = { };

  consume<T>(key: string): Observable<T> {
    if (!this.topics[key]) {
      this.topics[key] = new Subject<T>();
    }

    return this.topics[key].asObservable();
  }

  publish<T>(key: string, value: any): void {
    if (!this.topics[key]) {
      this.topics[key] = new Subject<T>();
    }

    this.topics[key].next(value as T);
  }
}
