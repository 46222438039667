import {
  Input,
  Component
} from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html'
})
export class FormFieldComponent {
  @Input()
    showLabel = true;
}
