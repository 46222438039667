import { AppConfig } from '@assets/types/config.type';

export class CookieCheckerUtils {
  static getCookieCheckerUrl(appConfig: AppConfig) {
    return this.swapUrl(appConfig);
  }

  private static extractDomain(url: string): string | null {
    const match = url.match(/^(?:https?:\/\/)?(?:[^/]+\.)?([^./]+\.[^./]+)/);
    return match ? match[1] : null;
  }

  private static swapUrl(appConfig: AppConfig): string {
    const cookieCheckerUrl1 = appConfig.cookieCheckerUrl1;
    const cookieCheckerUrl2 = appConfig.cookieCheckerUrl2;
    return this.extractDomain(window.location.href) === this.extractDomain(cookieCheckerUrl1)
      ? cookieCheckerUrl2
      : cookieCheckerUrl1;
  }
}
